import { Form } from '../components/form-items'

export const simpleForm: Form = {
  id: 'simple-test',
  title: 'untitled form',
  pages: [
    {
      title: 'Page 1',
      nextCaption: 'Continue',
      items: [
        {
          type: 'string',
          caption: 'What is your name?',
          key: 'name',
          required: true,
        },
        {
          type: 'pickOption',
          caption: 'What is your favorite color?',
          key: 'color',
          options: ['pink', 'red', 'yellow', 'blue', 'no, wait... aieeeeee'],
          required: true,
        },
        {
          type: 'text',
          caption: 'What is your quest?',
          key: 'quest',
          required: false,
        },
      ],
    },
  ],
}
