import { ElementCreator, elements, vars } from 'xinjs'
import { complaintButton } from './complaint-button'
import { businessSearch } from './business-search'
import { service } from '../firebase'
import { reviewCard } from './review-card'
import { loadingSpinner } from './loading-spinner'

const { fragment, h1, h2, div, img, p } = elements

const reviewContainer = div(
  {
    class: 'column',
    style: { margin: vars.spacing200, gap: vars.spacing, overflow: 'visible' },
  },
  loadingSpinner('loading recent reviews…')
)

service.reviews
  .get({ count: 5 })
  .then((reviews) => {
    reviewContainer.textContent = ''
    reviewContainer.append(h2('Latest Resolution Reviews'))
    const cards = reviews
      .map((review: any) =>
        reviewCard({
          review,
          isEmbedded: true,
          style: { width: '100%', maxWidth: '30em' },
        })
      )
      .slice(0, 5)
    reviewContainer.append(...cards)
  })
  .catch((e) => console.error('problem loading reviews', e))

export const homePage: ElementCreator = () =>
  fragment(
    div(
      {
        class: 'row-if-wide',
        style: { justifyContent: 'center', margin: vars.spacing400 },
      },
      div(
        img({
          alt: 'Get faster resolution for your complaints',
          src: 'images/home-image.webp',
          style: { width: '200px', height: '220px' },
        })
      ),
      div(
        {
          class: 'column',
          style: {
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '420px',
          },
        },
        h1('Get faster resolution for your complaints', {
          class: 'center-justified',
        }),
        p(
          'No No No gets companies to act on product and service problems—quick and easy',
          { class: 'center-justified' }
        ),
        complaintButton({ style: { marginTop: vars.spacing200 } })
      )
    ),
    businessSearch({
      style: {
        height: 'auto',
        width: '100vw',
        maxWidth: '100vw',
        overflowX: 'hidden',
      },
      cardsVisibleIfNoFilter: 0,
      isEmbedded: true,
    }),
    h2('How No No No works…', { class: 'center-justified' }),
    div(
      {
        class: 'row-if-wide',
        style: { justifyContent: 'space-evenly', gap: '20px' },
      },
      div(
        { class: 'column' },
        img({
          alt: 'Step 1',
          src: 'images/home-image-step-1.webp',
          style: { width: '200px', height: '220px' },
        }),
        p('You file a public complaint on nonono.com', {
          class: 'center-justified',
        })
      ),
      div(
        { class: 'column' },
        img({
          alt: 'Step 2',
          src: 'images/home-image-step-2.webp',
          style: { width: '200px', height: '220px' },
        }),
        p('We deliver the complaint to the business', {
          class: 'center-justified',
        })
      ),
      div(
        { class: 'column' },
        img({
          alt: 'Step 3',
          src: 'images/home-image-step-3.webp',
          style: { width: '200px', height: '220px' },
        }),
        p('You rate and review the outcome of your resolution', {
          class: 'center-justified',
        })
      )
    ),
    div(
      {
        style: {
          padding: '40px 0 120px',
          textAlign: 'center',
        },
      },
      complaintButton({ style: { marginTop: vars.spacing200 } })
    ),
    div(
      { class: 'row-if-wide' },
      div(
        img({
          alt: 'Yes Yes Yes!',
          src: 'images/home-image-bottom.webp',
          style: { width: '200px', height: '200px' },
        })
      ),
      div(
        {
          class: 'column',
          style: {
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '40px',
          },
        },
        h2('Yes Yes Yes', { class: 'center-justified' }),
        p('With our approach the business has an incentive to help you.', {
          class: 'center-justified',
        }),
        complaintButton({ style: { marginTop: vars.spacing200 } })
      )
    ),
    reviewContainer
  )
