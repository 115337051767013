import {
  SlDivider
} from "./chunk.CMGVEAL4.js";

// src/components/divider/divider.ts
var divider_default = SlDivider;
SlDivider.define("sl-divider");

export {
  divider_default
};
