import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { service } from '../firebase'
import { reviewStamp } from './review-stamp'
import { Review } from '../mocks/review'
import '../shoelace'
import { xinCarousel } from 'xinjs-ui'

const { h2 } = elements

const DEFAULT_CAPTION = 'Latest customer reviews'
class LatestReviews extends WebComponent {
  caption = DEFAULT_CAPTION
  businessPath = ''
  private _reviews: Review[] = []

  get reviews(): Review[] {
    return this._reviews
  }

  set reviews(_reviews: Review[]) {
    this._reviews = _reviews
    this.queueRender()
  }

  constructor() {
    super()

    this.initAttributes('businessPath', 'caption')
  }

  content = () => [
    h2({ part: 'caption' }),
    xinCarousel({
      part: 'carousel',
      arrows: true,
      dots: true,
      loop: true,
    }),
  ]

  async loadReviews(): Promise<void> {
    // are we in a business profile?
    let reviews: Review[] = []
    if (this.businessPath) {
      reviews = await service.reviews.get({
        businessPath: this.businessPath,
        count: 5,
      })

      if (reviews.length === 0) {
        this.caption = DEFAULT_CAPTION
        reviews = await service.reviews.get({ count: 5 })
      }
    } else {
      reviews = await service.reviews.get({ count: 5 })
    }
    for (const idx in reviews) {
      const review = reviews[idx]
      if (!review.displayName || !review.businessName) {
        try {
          reviews[idx] = await service.review.get({ reviewId: review._id })
        } catch (e) {
          console.error(e)
        }
      }
    }
    this.reviews = reviews.filter(
      (review) => review.displayName && review.businessName
    )
  }

  connectedCallback(): void {
    super.connectedCallback()
    if (this.reviews.length === 0) {
      this.loadReviews()
    }
  }

  render(): void {
    super.render()

    const { carousel, caption } = this.parts

    carousel.textContent = ''
    if (this.reviews.length === 0) {
      return
    }

    for (const review of this.reviews) {
      carousel.append(reviewStamp({ review }))
    }

    caption.textContent = this.caption
    ;(carousel as any).queueRender()
  }
}

export const latestReviews = LatestReviews.elementCreator({
  tag: 'latest-reviews',
  styleSpec: {
    ':host': {
      display: 'block',
      margin: `${vars.spacing} 0`,
    },
    ':host review-stamp': {
      width: '100%',
      maxWidth: '400px',
      margin: vars.spacing150,
    },
    ':host[narrow] review-stamp': {
      width: '280px',
    },
    [`@media (max-width: ${vars.readableWidth})`]: {
      ':host review-stamp': {
        width: '320px',
      },
    },
  },
}) as ElementCreator<LatestReviews>
