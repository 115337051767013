import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
  PartsMap,
} from 'xinjs'
import { readableWidth } from '../style'
import { markdownViewer, MarkdownViewer } from './markdown-viewer'
import { icons, findHighestZ } from 'xinjs-ui'

const { dialog, button, div } = elements

export interface PopPageParts extends PartsMap {
  dialog: HTMLDialogElement
  page: MarkdownViewer
}

class PopPage extends WebComponent {
  pageId = ''
  context = {}

  close = (event: Event) => {
    if ((event.target as HTMLElement)?.closest('[part=scrollRegion]')) {
      return
    }
    this.remove()
  }

  dismiss = this.remove.bind(this)

  content = () =>
    dialog(
      { part: 'dialog' },
      div({ part: 'scrollRegion' }, markdownViewer({ part: 'page' })),
      button(
        { part: 'closeButton', title: 'close', onClick: this.close },
        icons.x()
      )
    )

  constructor() {
    super()

    this.initAttributes('pageId', 'removeOnClose')
    this.addEventListener('click', this.close)
  }

  connectedCallback() {
    super.connectedCallback()

    window.addEventListener('route-will-load', this.dismiss)
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    window.removeEventListener('route-will-load', this.dismiss)
  }

  render() {
    super.render()

    const { page, dialog } = this.parts as PopPageParts

    page.loadPage(this.pageId, this.context)
    this.style.zIndex = String(Math.max(100, findHighestZ()) + 1)
    dialog.open = true
  }
}

export const popPage = PopPage.elementCreator({
  tag: 'pop-page',
  styleSpec: {
    ':host': {
      display: 'block',
      content: '" "',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: '#0002',
      animation: 'poppage 0.25s ease-out',
    },
    ':host [part="dialog"]': {
      display: 'flex',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: `calc(50vh + ${vars.spacing50})`,
      transform: 'translateY(-50%)',
      margin: 'auto',
      height: 'auto',
      minWidth: '200px',
      maxHeight: `calc(100vh - ${vars.spacing300})`,
      width: `calc(100vw - ${vars.spacing200})`,
      maxWidth: readableWidth,
      background: '#fff',
      animation: 'poppage 0.25s ease-out',
    },
    '@keyframes poppage': {
      '0%': {
        visibility: 'hidden',
        opacity: 0,
      },
      '10%': {
        visibility: 'visible',
      },
      '100%': {
        opacity: 1,
      },
    },
    ':host [part="scrollRegion"]': {
      overflow: 'hidden auto',
      padding: vars.spacing,
      maxWidth: '100%',
      maxHeight: '100%',
    },
    ':host [part="closeButton"]': {
      position: 'absolute',
      background: '#fff8',
      boxShadow: 'none',
      top: vars.spacing25,
      right: vars.spacing25,
      width: vars.touchSize,
      height: vars.touchSize,
      lineHeight: vars.touchSize,
      padding: 0,
      borderRadius: vars.touchSize,
    },
  },
}) as ElementCreator<PopPage>
