import { elements } from 'xinjs'
import { icons } from 'xinjs-ui'

const { span } = elements

export const disclose = (): HTMLSpanElement =>
  span(
    {
      class: 'disclose no-drag',
      role: 'button',
      title: 'toggle details',
      onClick(event: Event) {
        const details = (event.target as HTMLElement).closest(
          'details'
        ) as HTMLDetailsElement
        details.open = !details.open
        event.stopPropagation()
        event.preventDefault()
      },
    },
    icons.chevronRight()
  )
