import {
  Component as XinComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { tabSelector, makeSorter, icons } from 'xinjs-ui'
import { app } from '../app'
import {
  getRecords,
  authStateChangeListeners,
  service,
  DEFAULT_LIMIT,
} from '../firebase'
import { Chart } from 'chart.js/auto'

export const getList = async (options: {
  collection: string
  props: string[]
  latest: boolean
}): Promise<any[]> => {
  const { collection, props, latest } = options
  const { records, _created } = await service.list.post({
    collection,
    props,
    latest,
    limit: DEFAULT_LIMIT,
  })
  const newerRecords = await getRecords(collection, {
    field: '_modified',
    operator: '>',
    value: _created,
  })
  for (const rec of newerRecords) {
    const existingIndex = records.findIndex((r: any) => r._id === rec._id)
    if (existingIndex > -1) {
      records.splice(existingIndex, 1)
    }
    records.unshift(rec)
  }
  return records
}

const { div, canvas, label, select, option, span } = elements

// given a time stamp, will return a label for how many intervals in the past the timestamp is
const timeboxDurations = {
  hour: 3600 * 1000,
  day: 24 * 3600 * 1000,
  week: 7 * 24 * 3600 * 1000,
  month: 30 * 24 * 3600 * 1000, // actually use calendar month
}
type TimeboxDuration = keyof typeof timeboxDurations

const WEEKDAY_OFFSET = new Date(0).getDay() * timeboxDurations.day

const weeksSinceEpoch = (t: number | string | Date): number => {
  const daysSinceEpoch = Math.floor(
    new Date(t).valueOf() / timeboxDurations.day
  )
  return Math.floor((daysSinceEpoch + new Date(0).getDay()) / 7)
}

const getTimebox = (
  timestamp: string,
  duration: TimeboxDuration = 'week',
  utcOffset = -4
): number => {
  const now = new Date()
  const date = new Date(timestamp)
  switch (duration) {
    case 'hour':
      return (date.getUTCHours() + utcOffset + 24) % 24
    case 'day':
      return Math.floor(
        (now.valueOf() - date.valueOf()) / timeboxDurations[duration]
      )
    case 'week':
      return weeksSinceEpoch(now) - weeksSinceEpoch(date)
    case 'month':
      return (
        now.getMonth() -
        now.getFullYear() * 12 -
        date.getMonth() +
        date.getFullYear() * 12
      )
  }
  return Math.floor((Date.now() - date.valueOf()) / timeboxDurations[duration])
}
interface StackData {
  [key: string]: number
}

const reportTypes = {
  status: {
    makeDatasets(/* comp: StatusReport */) {
      return [
        {
          label: 'reviewed',
          data: [] as number[],
        },
        {
          label: 'unreviewed',
          data: [] as number[],
        },
      ]
    },
    makeReducer(
      comp: StatusReport
    ): (data: StackData[], rec: any) => StackData[] {
      const { interval, reviews } = comp
      return (data: StackData[], caseData): StackData[] => {
        const intervalsBack = getTimebox(caseData._created, interval)
        if (data[intervalsBack] === undefined) {
          data[intervalsBack] = {
            reviewed: 0,
            unreviewed: 0,
          }
        }
        if (reviews.find((review) => review.caseId === caseData._id)) {
          data[intervalsBack].reviewed += 1
        } else {
          data[intervalsBack].unreviewed += 1
        }
        return data
      }
    },
  },
  business: {
    makeDatasets(comp: StatusReport) {
      return [
        ...comp.businesses.slice(0, 20).map((business) => ({
          label: business.name,
          data: [] as number[],
        })),
        {
          label: 'other',
          data: [] as number[],
        },
      ]
    },
    makeReducer(
      comp: StatusReport
    ): (data: StackData[], rec: any) => StackData[] {
      const { interval, businesses } = comp
      return (data: StackData[], caseData): StackData[] => {
        const intervalsBack = getTimebox(caseData._created, interval)
        if (data[intervalsBack] === undefined) {
          const stackData = {} as StackData
          for (const key of [
            ...businesses.slice(0, 20).map((b) => b.name),
            'other',
          ]) {
            stackData[key] = 0
          }
          data[intervalsBack] = stackData
        }
        const business = businesses.find(
          (bus) => bus._id === caseData.businessId
        )
        const name =
          business !== undefined ? business.name : caseData.businessName
        if (data[intervalsBack][name] !== undefined) {
          data[intervalsBack][name] += 1
        } else {
          data[intervalsBack].other += 1
        }
        return data
      }
    },
  },
}

type ReportType = keyof typeof reportTypes

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const labelTimebox = (duration: TimeboxDuration, value: number): string => {
  switch (duration) {
    case 'hour':
      return `${value}:00`
    case 'day':
      return new Date(
        Date.now() - value * timeboxDurations.day
      ).toLocaleDateString()
    case 'week': {
      const week = new Date(
        (weeksSinceEpoch(Date.now()) - value) * timeboxDurations.week -
          WEEKDAY_OFFSET
      )
      return week.toLocaleDateString()
    }
    case 'month': {
      const thisMonth = new Date().getMonth() + new Date().getFullYear() * 12
      return (
        months[(thisMonth - value + 12) % 12] +
        ' ' +
        Math.floor((thisMonth - value) / 12)
      )
    }
  }
}

export class StatusReport extends XinComponent {
  cases: any[] = []
  businesses: any[] = []
  reviews: any[] = []
  interval: TimeboxDuration = 'week'
  reportType: ReportType = 'status'

  getData = async (): Promise<void> => {
    if (app.privileges?.isCSR) {
      const [cases, businesses, reviews] = await Promise.all([
        getList({
          collection: 'case',
          props: [
            '_id',
            'businessName',
            'businessId',
            '_created',
            '_modified',
            'tags',
            'isPaid',
            'isReviewed', // not reliable
            'referrer',
            'isEscalated',
            'isResolved',
          ],
          latest: false,
        }),
        getList({
          collection: 'business-profile',
          props: ['_id', 'name', 'cases', 'reviews', 'isTestData'],
          latest: false,
        }),
        getList({
          collection: 'review',
          props: [
            '_id',
            'businessId',
            'caseId',
            'rating',
            'ourRating',
            'wouldDoBusiness',
            'wouldUseNoNoNoAgain',
          ],
          latest: false,
        }),
      ])

      this.businesses = businesses
        .filter((business) => !business.isTestData)
        .sort(
          makeSorter((business) => [
            business.cases ? -business.cases.caseIds.length : 0,
          ])
        )
      console.log(this.businesses)
      const testBusinesses = businesses
        .filter((business) => business.isTestData)
        .map((business) => business._id)
      this.cases = cases.filter(
        (caseData) => !testBusinesses.includes(caseData.businessId)
      )
      this.reviews = reviews

      console.log('filtered out test buinesses', testBusinesses.length)
      console.log('filtered out test cases', cases.length - this.cases.length)

      this.queueRender()
    }
  }

  setInterval = () => {
    const { pickInterval } = this.parts as { pickInterval: HTMLSelectElement }
    this.interval = pickInterval.value as TimeboxDuration
  }

  setReportType = () => {
    const { pickReportType } = this.parts as {
      pickReportType: HTMLSelectElement
    }
    this.reportType = pickReportType.value as ReportType
  }

  content = () => [
    tabSelector(
      { style: { height: '100%' } },
      div(
        { name: 'cases', part: 'chartTab', class: 'column' },
        div(
          {
            class: 'row',
            style: {
              flexWrap: 'wrap',
              gap: vars.spacing50,
              padding: vars.spacing,
            },
          },
          label(
            {
              class: 'row',
              style: { gap: vars.spacing, alignItems: 'center', padding: 0 },
            },
            span('interval'),
            select(
              option('hour'),
              option('day'),
              option('week', { selected: true }),
              option('month'),
              {
                class: 'compact',
                part: 'pickInterval',
                onChange: this.setInterval,
              }
            ),
            icons.chevronDown()
          ),

          label(
            {
              class: 'row',
              style: { gap: vars.spacing, alignItems: 'center', padding: 0 },
            },
            span('breakdown'),
            select(...Object.keys(reportTypes).map((type) => option(type)), {
              class: 'compact',
              part: 'pickReportType',
              onChange: this.setReportType,
            }),
            icons.chevronDown()
          )
        )
      )
    ),
  ]

  constructor() {
    super()

    this.initAttributes(
      'cases',
      'businesses',
      'reviews',
      'interval',
      'reportType'
    )
  }

  connectedCallback(): void {
    super.connectedCallback()

    void this.getData()
    authStateChangeListeners.add(this.getData)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()

    authStateChangeListeners.delete(this.getData)
  }

  async renderChart() {
    const { chartTab } = this.parts

    // FIXME can we reuse a canvas?
    const existing = chartTab.querySelector('canvas')
    if (existing) {
      existing.remove()
    }

    const chartCanvas = canvas({
      style: {
        margin: vars.spacing,
        width: '100%',
        flex: '1 1 auto',
      },
    })
    chartTab.append(chartCanvas)

    const caseCounts = this.cases
      .filter(
        (caseData) => caseData.isPaid || (caseData.tags || []).includes('paid')
      )
      .reduce(
        reportTypes[this.reportType].makeReducer(this),
        [] as StackData[]
      ) as StackData[]

    const datasets = reportTypes[this.reportType].makeDatasets(this)

    const labels = [] as string[]
    for (let i = 0; i < caseCounts.length; i++) {
      labels[i] = labelTimebox(this.interval, i)
      if (caseCounts[i]) {
        datasets.forEach((dataset) => {
          dataset.data.push(caseCounts[i][dataset.label])
        })
      } else {
        datasets.forEach((dataset) => {
          dataset.data.push(0)
        })
      }
    }

    if (this.interval !== 'hour') {
      // oldest data is currently last
      // first interval will almost always be incomplete
      for (const dataset of datasets) {
        dataset.data.pop()
        dataset.data.reverse()
      }

      labels.pop()
      labels.reverse()
    }

    console.log(datasets)

    new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
          },
          y: {
            border: { display: false },
            stacked: true,
            beginAtZero: true,
          },
        },
      },
    })
  }

  render(): void {
    super.render()

    this.style.height = '100%'

    this.renderChart()
  }
}

export const statusReport = StatusReport.elementCreator({
  tag: 'status-report',
}) as ElementCreator<StatusReport>
