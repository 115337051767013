import { Component as WebComponent, elements, vars } from 'xinjs'
import { service, getRecords, logEvent } from '../firebase'
import { marked } from 'marked'
import { cardView } from './card-view'
import { disqusThread } from './disqus-thread'
import { reviewCard } from './review-card'
import { BrandProfile } from '../mocks/business-profile'
import { loadingSpinner } from './loading-spinner'
import { Case, CaseStatusTags } from '../mocks/case'
import { hasLegacyStatus, hasTags } from '../tags'
import { complaintButton, ComplaintButton } from './complaint-button'
import { app } from '../app'

const isProduction = ['new.nonono.com', 'nonono.com'].includes(
  window.location.host
)

const { h1, h2, h3, h4, div, span, a } = elements

const mockCase = {
  title: '',
  description: '',
  nameFirst: '',
  nameLast: '',
} as Case

class CaseView extends WebComponent {
  caseId: string = ''
  businessData: BrandProfile | null = null
  caseData: Case | null = null
  // FIXME implement compact display mode
  // compact = false
  linkBusinessProfile = false
  linkComplaint = false
  linkEscalate = false
  isEmbedded = false

  showReview = false

  constructor() {
    super()
    this.initAttributes(
      'caseId',
      'setTitle',
      'isEmbedded',
      'linkBusinessProfile',
      'linkComplaint',
      'linkEscalate'
    )
  }

  heading() {
    if (this.isEmbedded) {
      return a(
        { part: 'caseLink', dataTooltip: 'See case page' },
        h3(
          {
            class: 'no-margin',
            style: {
              color: vars.linkColor,
              fontSize: vars.fontSize125,
              lineHeight: vars.lineHeight110,
            },
          },
          span({ part: 'title' })
        )
      )
    } else {
      return h1({
        part: 'title',
        class: 'no-margin',
        style: { fontSize: vars.fontSize125, lineHeight: vars.lineHeight110 },
      })
    }
  }

  content = () => [
    loadingSpinner({ part: 'spinner' }, 'loading case details'),
    cardView(
      {
        part: 'card',
        style: {
          padding: vars.spacing200,
          margin: `${vars.spacing} auto`,
          maxWidth: '30em',
        },
      },
      this.heading(),
      (this.isEmbedded ? h4 : h2)(
        { style: { fontSize: vars.fontSize, lineHeight: vars.lineHeight } },
        'Complaint lodged with ',
        a({ part: 'businessLink' }),
        ' on ',
        span({ part: 'date' })
      ),
      div({ part: 'description' }),
      div(
        { style: { marginTop: vars.spacing200 } },
        span('— '),
        span({ part: 'customerName' })
      )
    ),
    complaintButton({
      part: 'escalateButton',
      hidden: true,
      style: {
        display: 'block',
        margin: `${vars.spacing200} 0`,
        textAlign: 'center',
      },
    }),
    !this.isEmbedded ? disqusThread() : '',
  ]

  set loading(isLoading: boolean) {
    const { card, spinner } = this.parts
    if (isLoading) {
      card.setAttribute('hidden', '')
      spinner.removeAttribute('hidden')
    } else {
      card.removeAttribute('hidden')
      spinner.setAttribute('hidden', '')
    }
  }

  get loading(): boolean {
    const { card } = this.parts
    return card.hasAttribute('hidden')
  }

  setupLink(element: HTMLElement, text: string, url: string, tooltip: string) {
    element.textContent = text
    element.setAttribute('target', '_blank')
    element.setAttribute('href', url)
    element.dataset.tooltip = tooltip
  }

  update = (): void => {
    const { businessData } = this
    let { caseData } = this

    const { title, businessLink, date, description, customerName } = this
      .parts as { [key: string]: HTMLElement }
    this.loading = caseData === null
    if (caseData === null) {
      caseData = mockCase
    } else if (
      hasLegacyStatus(caseData, CaseStatusTags.paid) &&
      !hasTags(caseData, CaseStatusTags.paymentLogged) &&
      isProduction &&
      caseData._created > '2023-10-25T09:19:19.777Z'
    ) {
      try {
        service.logcasepaid.get({ caseId: caseData._id })
      } catch (e) {
        console.error(e)
      }

      logEvent('purchase', {
        transaction_id: caseData._id,
        currency: 'USD',
        items: [
          {
            item_id: `${caseData.businessName.replace(/ /g, '-')}-complaint`,
            item_name: `${caseData.businessName} Complaint Resolution Service`,
          },
        ],
        value: caseData.price,
      })
    }

    if (!this.isEmbedded) {
      app.ui.editableContent = `case ${caseData._id}`
    }

    if (businessData !== null) {
      caseData.businessName = businessData.name
    }
    const businessProfileUrl = caseData.businessPath
      ? `/${caseData.businessPath}-customer-service/`
      : `/business/${caseData.businessId as string}`
    this.setupLink(
      businessLink,
      caseData.businessName,
      businessProfileUrl,
      `See ${caseData.businessName} Customer Service page`
    )
    date.textContent = new Date(caseData._created).toLocaleDateString()
    title.textContent = caseData.title
    if (this.isEmbedded) {
      this.parts.caseLink.setAttribute(
        'href',
        `/case/${caseData.slug || caseData._id}`
      )
    }
    description.innerHTML = marked(
      caseData.description.replace(/\n/g, '\n\n').replace(/\n\n\n/g, '\n\n'),
      { mangle: false, headerIds: false }
    )
      .replace(/<(\/?)pre>/g, '<$1blockquote>')
      .replace(/<(\/?)code>/g, '<$1p>')
      .replace(/\s+/g, ' ') // fixing &ensp; etc.
    customerName.textContent =
      caseData.nameFirst !== undefined
        ? `${caseData.nameFirst as string} ${(caseData.nameLast as string)[0]}`
        : ''

    if (this.linkEscalate) {
      const escalateButton = this.parts.escalateButton as ComplaintButton
      escalateButton.path = caseData.businessPath
      escalateButton.brandName = caseData.businessName
      escalateButton.hidden = false
    }
  }

  private loaded = false
  loadCase = async (): Promise<void> => {
    const { escalateButton } = this.parts
    if (this.loaded) {
      return
    }
    this.loaded = true
    const { caseId } = this
    if (caseId === undefined) {
      return
    }

    this.update()

    if (this.caseData === null) {
      if (this.showReview) {
        const caseData = await service.case.get({ caseId: String(caseId) })
        this.caseId = caseData._id

        this.caseData = caseData

        if (
          this.caseData !== null &&
          this.showReview &&
          this.parentElement !== null
        ) {
          const [review] = await getRecords(
            'review',
            {
              field: 'caseId',
              operator: '==',
              value: this.caseId,
            },
            1
          )
          this.insertBefore(
            reviewCard({
              style: {
                margin: `${vars.spacing} 0`,
                transform: 'translateX(var(--spacing))',
              },
              review,
              hideCaseLink: true,
            }),
            escalateButton
          )
        }
      } else {
        const caseData = await service[`case?caseId=${caseId}`].get()
        this.caseData = caseData
      }
      this.update()
    }
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.loading = this.caseData === null

    void this.loadCase()
  }
}

export const caseView = CaseView.elementCreator({ tag: 'case-view' })
