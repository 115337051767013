import { Component as WebComponent, vars } from 'xinjs'
import { ItemTypes, Form, FormData } from './form-items'
import { setRecord, logEvent } from '../firebase'
import { randomID } from '../random-id'
import { error, log } from '../notifications'
import { StripePayment } from './stripe-payment'
import { markdownViewer } from './markdown-viewer'
import { XinTagList } from 'xinjs-ui'

const nullForm: Form = {
  id: 'null-form',
  title: 'no form selected',
  pages: [
    {
      title: '',
      nextCaption: 'Save',
      items: [],
    },
  ],
}

const { button, form, div, span } = WebComponent.elements

export class FormViewer extends WebComponent {
  _form: Form = nullForm
  page = 0
  value: FormData = {}

  get form(): Form {
    return this._form
  }

  set form(newForm: Form) {
    this._form = newForm
    this.page = 0
  }

  content = [
    form({
      style: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        gap: '10px',
        position: 'relative',
        padding: vars.spacing,
        minHeight: '400px',
      },
    }),
    div(
      {
        part: 'bottomBar',
        style: {
          display: 'flex',
          padding: `${vars.spacing50} ${vars.spacing}`,
          gap: vars.spacing50,
        },
      },
      span({ class: 'elastic' }),
      button(
        {
          part: 'back',
          disabled: true,
        },
        'Back'
      ),
      button(
        {
          part: 'next',
          class: 'default',
        },
        'Next'
      )
    ),
  ]

  constructor() {
    super()
    this.initAttributes('page', 'record')
  }

  onAllowNext(): boolean {
    return true
  }

  onAllowSubmit(): boolean {
    return true
  }

  save(): void {
    if (this.form.collection !== undefined) {
      const data = { ...this.value }
      delete data._context
      setRecord(this.form.collection, data, data._id).catch(error)
    }
  }

  previous = (): void => {
    if (this.page > 0) {
      this.save()
      this.page -= 1
    }
  }

  attemptPayment = (): boolean => {
    const { back, next } = this.parts as { [key: string]: HTMLInputElement }
    const stripePayment = this.querySelector('stripe-payment') as StripePayment
    if (stripePayment !== null) {
      this.value[stripePayment.name] = stripePayment.value
      back.disabled = true
      next.disabled = true
      stripePayment
        .submitPayment()
        .catch(error)
        .finally(() => {
          delete this.value[stripePayment.name]
          this.save()
          back.disabled = false
          next.disabled = false
        })
      return true
    }
    return false
  }

  next = (event: Event): void => {
    event.preventDefault()
    const { form } = this.parts

    const widgets = [...form.querySelectorAll('*')].filter(
      (element) => (element as HTMLInputElement).required === true
    ) as HTMLInputElement[]
    const invalidWidget = widgets.find((widget) => !widget.reportValidity())
    const isLastPage = this.page + 1 === this.form.pages.length
    if (
      invalidWidget == null &&
      this.onAllowNext() &&
      (!isLastPage || this.onAllowSubmit())
    ) {
      if (isLastPage) {
        this.dispatchEvent(new Event('submit'))
      }
      if (!this.attemptPayment()) {
        this.page += 1
      }
      this.save()
    }
  }

  handleChange = (event: Event) => {
    const field = (event.target as HTMLElement).closest('[name]') as HTMLElement

    if (field == null) {
      return
    }

    if ((field as HTMLInputElement).name === '.') {
      try {
        this.value = JSON.parse((field as HTMLInputElement).value)
        ;(event.target as HTMLTextAreaElement).setCustomValidity('')
      } catch (e) {
        log(e as string)
        ;(event.target as HTMLTextAreaElement).setCustomValidity(
          'not valid JSON'
        )
      }
    } else {
      if (field instanceof HTMLTextAreaElement) {
        this.value[field.name] = field.value
      } else if (field instanceof HTMLSelectElement) {
        if (field.multiple) {
          this.value[field.name] = [...field.selectedOptions].map(
            (option) => option.value
          )
        } else {
          const inputElement = field.nextElementSibling as HTMLInputElement
          this.value[field.name] = field.value
          inputElement.required = false
          if (field.value === '__specify__') {
            inputElement.required = true
            this.value[field.name] = inputElement.value
          }
        }
      } else if (field instanceof HTMLInputElement) {
        switch (field.type) {
          case 'number':
          case 'range':
            this.value[field.name] = Number(field.value)
            break
          case 'checkbox':
            this.value[field.name] = field.checked
            break
          case 'radio':
            this.value[field.name] = (
              (field.parentElement as HTMLElement).querySelector(
                'input[type="radio"]:checked'
              ) as HTMLInputElement
            ).value
            break
          default:
            this.value[field.name] = field.value
        }
      } else if (field instanceof XinTagList) {
        this.value[field.name] = field.value
      }
    }
  }

  connectedCallback(): void {
    // need to do this before super because hydration overwrites promise by cloning it…
    if (this.value instanceof Promise) {
      this.classList.add('blur-content')
      const promise = this.value
      this.value = {}
      promise
        .then((data: FormData) => {
          Object.assign(this.value, data)
          this.classList.remove('blur-content')
          this.queueRender()
        })
        .catch(error)
    }
    super.connectedCallback()
    const { form, next, back } = this.parts
    this.queueRender()
    back.addEventListener('click', this.previous)
    next.addEventListener('click', this.next)
    form.addEventListener('submit', (event: Event) => {
      event.preventDefault()
      event.stopPropagation()
    })
    form.addEventListener('change', this.handleChange, { capture: true })
  }

  render(): void {
    const { form, back, next, bottomBar } = this.parts as {
      form: HTMLFormElement
      back: HTMLButtonElement
      next: HTMLButtonElement
      bottomBar: HTMLDivElement
    }
    if (this.value._id === undefined) {
      this.value._id = randomID(16)
    }
    super.render()
    globalThis.document
      .querySelector('main')
      ?.scrollIntoView({ behavior: 'smooth' })
    const page = this.form.pages[this.page]
    form.textContent = ''
    back.disabled = this.page === 0
    logEvent('page_view', {
      page_title: this.form.title + '-form',
      page_location: `${this.form.title}-form-${this.page}`,
    })
    if (page != null) {
      bottomBar.style.visibility = ''
      next.textContent = page.nextCaption
      for (const item of page.items) {
        const element = ItemTypes[item.type](item, this.value, this.form)
        form.append(element)
      }
    } else {
      bottomBar.style.visibility = 'hidden'
      if (this.form.completionMessage != null) {
        form.append(markdownViewer(this.form.completionMessage))
      }
    }
  }
}

export const formViewer = FormViewer.elementCreator({ tag: 'form-viewer' })
