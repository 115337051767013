import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { Contact } from '../mocks/business-profile'
import { MarkdownViewer } from './markdown-viewer'
import { icons } from 'xinjs-ui'

const { h3, span, a } = elements

function renderContactInfo(info: string): HTMLElement {
  if (info.startsWith('http')) {
    return a({ href: info, target: '_blank' }, icons.web(), info)
  } else if (info.includes('@')) {
    return a({ href: `mailto:${info}` }, icons.mail(), info)
  } else if (info.match(/\(\d{3}\) \d{3} \d{4}/)) {
    return a({ href: `tel:${info}` }, icons.phone(), info)
  } else {
    return span({ style: { whiteSpace: 'pre' } }, info)
  }
}

class ContactBlock extends WebComponent {
  contacts: Contact[] = []

  content = []

  connectedCallback(): void {
    super.connectedCallback()

    const viewer = this.closest('md-viewer') as MarkdownViewer

    if (viewer.context.business.contacts) {
      this.contacts = viewer.context.business.contacts as Contact[]
    }
  }

  render() {
    this.textContent = ''

    for (const contact of this.contacts) {
      this.append(
        span(h3(contact.description), renderContactInfo(contact.content))
      )
    }
  }
}

export const contactBlock = ContactBlock.elementCreator({
  tag: 'contact-block',
  styleSpec: {
    ':host': {
      display: 'flex',
      flexDirection: 'column',
      gap: vars.spacing50,
    },
    ':host > span': {
      display: 'flex',
      flexDirection: 'column',
    },
    ':host svg': {
      marginRight: '8px',
    },
    ':host h3': {
      margin: 0,
    },
  },
}) as ElementCreator<ContactBlock>
