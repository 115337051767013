import {
  SlBadge
} from "./chunk.6SJD4YXQ.js";

// src/components/badge/badge.ts
var badge_default = SlBadge;
SlBadge.define("sl-badge");

export {
  badge_default
};
