import { elements, ElementPart, vars } from 'xinjs'
import { SNIPPET_CONTEXTS, SNIPPET_ACTIONS } from '../mocks/snippet'
import { dbTool } from './crud-tool'
import { setRecord } from '../firebase'
import { error, success } from '../notifications'
import { tabSelector, icons, xinTagList } from 'xinjs-ui'

const { h2, form, div, label, strong, span, input, select, option, button } =
  elements

const SNIPPET_TAGS = [
  'customer-escalated',
  'customer-awaiting-escalation',
  'internal-new-complaint',
  'business-new-complaint',
  'business-new-link',
  'customer-new-link',
  'customer-new-review-link',
]

export const snippetEditor = (...args: ElementPart[]): HTMLFormElement =>
  form(
    ...args,
    {
      style: { padding: 0, gap: 0 },
      onInput(event: Event) {
        const form = (event.target as HTMLElement).closest(
          'form'
        ) as HTMLFormElement
        const saveButton = form.querySelector(
          '.save-button'
        ) as HTMLButtonElement
        saveButton.disabled = false
      },
    },
    h2({
      bindText: 'dbTool.object.name',
      class: 'primary',
      style: { padding: vars.spacing, margin: 0 },
    }),
    div(
      { class: 'toolbar', style: { background: vars.panelBg } },
      span({ class: 'elastic' }),

      label(
        { class: 'row', style: { alignItems: 'center' } },
        span('Action'),
        select(
          { bindValue: 'dbTool.object.action' },
          ...SNIPPET_ACTIONS.map((context) => option(context))
        )
      ),
      label(
        { class: 'row', style: { alignItems: 'center' } },
        span('Context'),
        select(
          { bindValue: 'dbTool.object.context' },
          ...SNIPPET_CONTEXTS.map((context) => option(context))
        )
      ),
      span({ class: 'spacer' }),
      button(
        {
          class: 'save-button default',
          disabled: true,
          onClick(event: Event) {
            event.preventDefault()
            const { collection, object } = dbTool
            const saveButton = (event.target as HTMLElement).closest(
              'button'
            ) as HTMLButtonElement
            saveButton.disabled = true
            setRecord(collection, object)
              .then(() => {
                success(
                  `${object.name as string} saved to ${collection}/${
                    object._id as string
                  }`
                )
                dbTool._dirty = false
              })
              .catch((err) => {
                error(`${object.name as string} was not saved`, err)
                saveButton.disabled = false
              })
          },
        },
        'Save'
      ),
      button({ title: 'close', onClick: dbTool.close }, icons.x()),
      button(
        { title: 'delete', onClick: dbTool.delete, class: 'danger' },
        icons.trash()
      )
    ),
    div(
      {
        class: 'column',
        style: {
          padding: vars.spacing,
          overflowY: 'scroll',
          alignItems: 'stretch',
        },
      },
      div(
        { bindShow: 'dbTool.object.context', dataShowMatchValue: 'case' },
        strong('Status Effects'),
        div(
          { class: 'row' },
          label(
            'Set Escalated',
            input({ type: 'checkbox', bindValue: 'dbTool.object.setEscalated' })
          ),
          label(
            'Set Resolved',
            input({ type: 'checkbox', bindValue: 'dbTool.object.setResolved' })
          ),
          label(
            'Assign to Me',
            input({ type: 'checkbox', bindValue: 'dbTool.object.assignToMe' })
          )
        )
      ),
      label(strong('Snippet Name'), input({ bindValue: 'dbTool.object.name' })),
      label(
        { class: 'row' },
        input({
          type: 'checkbox',
          bindValue: 'dbTool.object.isVisibleInMenus',
        }),
        span('Show in Menus')
      ),
      xinTagList({
        title: 'tags',
        custom: true,
        bindValue: 'dbTool.object.tags',
        availableTags: SNIPPET_TAGS,
      }),
      label(
        {
          bindHide: 'dbTool.object.action',
          dataHideMatchValue: 'add to message',
        },
        strong('Subject'),
        input({ bindValue: 'dbTool.object.subject' })
      ),
      label(
        strong('Body'),
        tabSelector(
          div({
            name: 'markdown',
            contenteditable: true,
            bindEditable: 'dbTool.object.body',
            class: 'code',
            style: {
              width: '100%',
              minHeight: '20em',
            },
          }),
          div({
            name: 'preview',
            bindMarkdown: 'dbTool.object.body',
          })
        )
      )
    )
  )
