import { xinProxy } from 'xinjs'
import * as _fb from './firebase'
import localforage from 'localforage'

export interface Privileges {
  isAdmin?: boolean
  isSuper?: boolean
  isCSR?: boolean
}

export interface FirebaseUser {
  email: string
  displayName: string
  uid: string
  phone_number: string
  access_token: string
  photoURL: string
  [key: string]: any
}

let testBusinesses = null as null | { names: string[]; ids: string[] }

export const { app, fb } = xinProxy({
  app: {
    title: 'No No No',
    celebrate: (...args: any[]) => {
      console.log('celebrating', ...args)
    },
    signinEnabled: false,
    user: null as null | FirebaseUser,
    privileges: {} as Privileges | undefined,
    ui: {
      isInternal: false,
      showSignin: false,
      showMenu: false,
      editableContent: '',
    },
    isCacheEnabled: false,
    isTestDataVisible: false,
    async testBusinesses(): Promise<{ names: string[]; ids: string[] }> {
      if (testBusinesses === null) {
        testBusinesses = { names: [], ids: [] }
        if (app.privileges && app.privileges.isCSR) {
          const businesses = await await _fb.getRecords('business-profile', {
            field: 'isTestData',
            operator: '==',
            value: true,
          })
          for (const bus of businesses) {
            testBusinesses.names.push(bus.name)
            testBusinesses.ids.push(bus._id)
          }
        }
      }
      return testBusinesses
    },
  },
  fb: _fb,
  lf: localforage,
})
