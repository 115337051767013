const PROJECT_ID = 'nonono-fea9e'

export const LOCAL_BASE = `//localhost:8501/${PROJECT_ID}/us-central1/`
export const PRODUCTION_BASE = `//us-central1-${PROJECT_ID}.cloudfunctions.net/`

export const config = !window.location.host.includes('stage')
  ? {
      apiKey: 'AIzaSyC2zdTysyosa9Fk5RrFhxrUp7UE66ra_Vw',
      authDomain: 'nonono-fea9e.firebaseapp.com',
      projectId: 'nonono-fea9e',
      storageBucket: 'nonono-fea9e.appspot.com',
      messagingSenderId: '171481538073',
      appId: '1:171481538073:web:8ad37ebf871de47b5a5488',
      measurementId: 'G-CZSVWT3XS1',
    }
  : {
      apiKey: 'AIzaSyC2zdTysyosa9Fk5RrFhxrUp7UE66ra_Vw',
      authDomain: 'nonono-fea9e.firebaseapp.com',
      projectId: 'nonono-fea9e',
      storageBucket: 'nonono-fea9e.appspot.com',
      messagingSenderId: '171481538073',
      appId: '1:171481538073:web:5a19caffeae59de05a5488',
      measurementId: 'G-5GQNYQBWGD',
    }
