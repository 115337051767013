export function stringFallback(s?: string, fallback = ''): string {
  return s != null ? s : fallback
}

export function booleanFallback(b?: boolean, fallback = false): boolean {
  return typeof b === 'boolean' ? b : fallback
}

export function numberFallback(n?: number, fallback = 0): number {
  return typeof n === 'number' ? n : fallback
}

export function arrayFallback(a?: any[], fallback = []): any[] {
  return Array.isArray(a) ? a : fallback
}

export function targetFallback(event: Event): HTMLElement & HTMLInputElement {
  if (
    event.target != null &&
    typeof event.target === 'object' &&
    typeof (event.target as HTMLElement).dataset === 'object'
  ) {
    return event.target as unknown as HTMLElement & HTMLInputElement
  } else {
    console.error('event did not have target', event)
    throw new Error('event did not have target')
  }
}
