import {
  elements,
  vars,
  getListItem,
  xin,
  Component,
  ElementCreator,
  touch,
  css,
} from 'xinjs'
import { tabSelector, icons, svgIcon, codeEditor } from 'xinjs-ui'
import { dbTool } from './crud-tool'
import { disclose } from './disclose'
import { targetFallback } from '../fallback'
import { service } from '../firebase'
import { CollectionSpec } from '../collection-spec'
import { collections } from '../collections'

const {
  form,
  div,
  h4,
  label,
  span,
  input,
  template,
  button,
  details,
  summary,
  textarea,
  table,
  tr,
  td,
  th,
  style,
} = elements

async function recomputeBusinessStats(event: Event): Promise<void> {
  event.preventDefault()
  const button = event.target as HTMLButtonElement
  button.disabled = true
  const rebuilt = await service.business.get({
    id: dbTool.object._id,
    rebuild: true,
  })
  dbTool.object = null
  Object.assign(dbTool.object, rebuilt)
  button.disabled = false
}

document.head.append(
  style(
    { id: 'business-editor' },
    css({
      'business-editor': {
        flex: '1 1',
        position: 'relative',
      },
      'business-editor > form': {
        height: '100%',
      },
      'business-editor div[contenteditable]': {
        minHeight: `calc(${vars.lineHeight200} + ${vars.spacing})`,
      },
    })
  )
)

const contactsEditor = (name: string, path: string): HTMLDetailsElement =>
  details(
    { open: true },
    summary(
      { class: 'toolbar' },
      disclose(),
      h4(name, { class: 'no-margin' }),
      span({ class: 'elastic' }),
      button(
        {
          onClick(event: Event) {
            if (!Array.isArray(xin[path])) {
              xin[path] = []
            }
            ;(xin[path] as any[]).push({ description: '', content: '' })
            event.preventDefault()
          },
        },
        svgIcon({ icon: 'plus' })
      )
    ),
    div(
      {
        bindList: {
          value: path,
        },
        style: {
          '--grid-columns': '300px 1fr 44px 44px 44px',
        },
      },
      template(
        div(
          {
            style: {
              display: 'grid',
              gridTemplateColumns: vars.gridColumns,
            },
          },
          input({ placeholder: 'description', bindValue: '^.description' }),
          div({
            contenteditable: true,
            placeholder: 'content',
            bindEditable: '^.content',
            style: {
              overflow: 'hidden',
            },
          }),
          button(
            {
              class: 'no-padding',
              onClick(event: Event) {
                event.preventDefault()
                const listItem = getListItem(targetFallback(event))
                const list = xin[path] as Array<typeof listItem>
                const index = list.indexOf(listItem)
                if (index === 0) {
                  return
                }
                const otherItem = { ...list[index - 1] }
                list[index - 1] = { ...listItem }
                list[index] = { ...otherItem }
                touch(path)
              },
            },
            svgIcon({ icon: 'chevronUp' })
          ),
          button(
            {
              class: 'no-padding',
              onClick(event: Event) {
                event.preventDefault()
                const listItem = getListItem(targetFallback(event))
                const list = xin[path] as Array<typeof listItem>
                const index = list.indexOf(listItem)
                if (index === list.length - 1) {
                  return
                }
                const otherItem = { ...list[index + 1] }
                list[index + 1] = { ...listItem }
                list[index] = { ...otherItem }
                xin[path] = [...list]
              },
            },
            svgIcon({ icon: 'chevronDown' })
          ),
          button(
            {
              class: 'no-padding',
              onClick(event: Event) {
                event.preventDefault()
                const listItem = getListItem(targetFallback(event))
                if (
                  !confirm(
                    `Delete "${listItem.description as string}: ${
                      listItem.content as string
                    }"?`
                  )
                ) {
                  return
                }
                if (listItem !== undefined) {
                  const index = (xin[path] as any[]).indexOf(listItem)
                  ;(xin[path] as any[]).splice(index, 1)
                }
              },
            },
            svgIcon({ icon: 'x' })
          )
        )
      )
    )
  )

export class BusinessEditor extends Component {
  content = () =>
    form(
      { style: { margin: `0 ${vars.spacing}` } },
      div(
        { class: 'row' },
        label(
          { class: 'column elastic' },
          span('Name'),
          input({
            style: { alignSelf: 'stretch', fontWeight: 'bold' },
            bindValue: 'dbTool.object.name',
          })
        ),
        label(
          { class: 'column' },
          span('Price per Complaint'),
          input({
            bindValue: 'dbTool.object.price',
            type: 'number',
            placeholder: '$15 default',
          })
        )
      ),
      div(
        { class: 'row', style: { alignItems: 'center' } },
        span('created'),
        span({ bindDate: 'dbTool.object._created' }),
        span('modified'),
        span({ bindDate: 'dbTool.object._modified' }),
        span({ class: 'elastic' }),
        label(
          { class: 'row' },
          input({
            type: 'checkbox',
            bindValue: 'dbTool.object.isTestData',
            style: { margin: 0 },
          }),
          'test data'
        )
      ),
      tabSelector(
        { class: 'elastic', style: { margin: `0 ${vars.spacing_100}` } },
        div(
          { name: 'Workflow' },
          label(
            { class: 'row' },
            input({
              type: 'checkbox',
              bindValue: 'dbTool.object.isAutomaticallyEscalated',
            }),
            span('Enable Automatic Escalation')
          ),
          label(
            { class: 'row' },
            input({
              type: 'checkbox',
              bindValue: 'dbTool.object.isSelfService',
            }),
            span('Enable Self-Service')
          ),
          div(
            {
              class: 'toolbar',
              style: {
                background: vars.panelBg,
                paddingLeft: vars.spacing,
                paddingRight: 0,
              },
            },
            h4('Escalation Emails'),
            span({ class: 'elastic' }),
            button(
              {
                onClick(event: Event) {
                  if (dbTool.object.escalationEmails === undefined) {
                    dbTool.object.escalationEmails = []
                  }
                  dbTool.object.escalationEmails.push('')
                  event.preventDefault()
                },
              },
              icons.plus()
            )
          ),
          div(
            {
              bindList: {
                value: 'dbTool.object.escalationEmails',
              },
              style: {
                '--grid-columns': '1fr 44px',
              },
            },
            template(
              div(
                {
                  style: {
                    display: 'grid',
                    gridTemplateColumns: vars.gridColumns,
                  },
                },
                input({ bindValue: '^', placeholder: 'email address' }),
                button(
                  {
                    class: 'no-padding',
                    onClick(event: Event) {
                      event.preventDefault()
                      const target = event.target as HTMLElement
                      const emailField =
                        target.previousElementSibling as HTMLInputElement
                      const email = emailField.value
                      if (!confirm(`Delete "${email as string}"?`)) {
                        return
                      }
                      const index =
                        dbTool.object.escalationEmails.indexOf(email)
                      dbTool.object.escalationEmails.splice(index, 1)
                    },
                  },
                  svgIcon({ icon: 'x' })
                )
              )
            )
          )
        ),
        div(
          { name: 'Contacts' },
          contactsEditor('Public', 'dbTool.object.contacts'),
          contactsEditor('Private', 'dbTool.object.privateContacts')
        ),
        div(
          { name: 'SEO', style: { padding: `0 ${vars.spacing}` } },
          label(
            span('path (a.k.a. slug)'),
            input({ bindValue: 'dbTool.object.path' })
          ),
          label(
            span('redirect to another business-profile'),
            input({
              placeholder: 'slug of correct business profile',
              bindValueEmpty: 'dbTool.object.redirectToBusinessSlug',
            })
          ),
          label(
            span('page title, <h1>, and <meta og:title>'),
            input({
              placeholder: 'override default title',
              bindValueEmpty: 'dbTool.object.title',
            })
          ),
          label(
            span('<meta description> and <meta og:description>'),
            input({
              placeholder: 'override default description',
              bindValueEmpty: 'dbTool.object.description',
            })
          )
        ),
        div(
          {
            name: 'Profile',
            style: { height: '100%' },
          },
          tabSelector(
            { style: { height: '100%' } },
            codeEditor({
              mode: 'markdown',
              name: 'complaintProcess',
              bindValue: 'dbTool.object.complaintProcess',
              style: { width: '100%' },
            }),
            codeEditor({
              mode: 'markdown',
              name: 'extraText',
              bindValue: 'dbTool.object.extraText',
              style: { width: '100%' },
            })
          )
        ),
        div(
          { name: 'FAC Form', style: { height: '100%' } },
          tabSelector(
            { style: { height: '100%' } },
            codeEditor({
              mode: 'markdown',
              name: 'facPrologue',
              bindValue: 'dbTool.object.facPrologue',
              style: { width: '100%' },
            }),
            codeEditor({
              mode: 'markdown',
              name: 'facQuestions',
              bindValue: 'dbTool.object.facQuestions',
              style: { width: '100%' },
            }),
            codeEditor({
              mode: 'markdown',
              name: 'facPayment',
              bindValue: 'dbTool.object.facPayment',
              style: { width: '100%' },
            })
          )
        ),
        div(
          { name: 'Case Views', style: { height: '100%' } },
          tabSelector(
            { style: { height: '100%' } },
            codeEditor({
              mode: 'markdown',
              name: 'escalationInfo',
              bindValue: 'dbTool.object.escalationInfo',
              style: { width: '100%' },
            }),
            codeEditor({
              mode: 'markdown',
              name: 'caseDescription',
              bindValue: 'dbTool.object.caseDescription',
              style: { width: '100%' },
            })
          )
        ),
        div(
          { name: 'Stats', class: 'row' },
          table(
            tr(
              th('Total Cases'),
              td({ bindText: 'dbTool.object.cases.count' })
            ),
            tr(
              th('Resolved Cases'),
              td({ bindText: 'dbTool.object.cases.resolved' })
            ),
            tr(
              th('Total Reviews'),
              td({ bindText: 'dbTool.object.reviews.count' })
            ),
            tr(
              th('Total Review Score'),
              td({ bindText: 'dbTool.object.reviews.totalScore' })
            ),
            tr(
              th('Total Would Do Business'),
              td({ bindText: 'dbTool.object.reviews.wouldDoBusiness' })
            )
          ),
          div(
            { style: { padding: vars.spacing } },
            button('Recalculate Stats', { onClick: recomputeBusinessStats })
          )
        ),
        div(
          {
            name: 'Schema.org',
            class: 'column',
            style: { alignItems: 'stretch' },
          },
          div(
            { class: 'toolbar' },
            button('Insert Template', {
              onClick(event: Event) {
                event.preventDefault()
                dbTool.object.schema = {
                  url: 'url',
                  image: 'image_url',
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'streetAddress',
                    addressLocality: 'state',
                  },
                  contactPoint: {
                    '@type': 'ContactPoint',
                    contentType: 'customer service',
                    email: 'email_address',
                    telephone: 'phone_number',
                  },
                }
              },
            })
          ),
          textarea({
            bindJson: 'dbTool.object.schema',
            placeholder:
              'Do not mess with this unless you KNOW what you are doing!',
            style: { minHeight: '200px', resize: 'none' },
            onBlur(event: Event) {
              const textarea = event.target as HTMLTextAreaElement
              if (textarea.value && textarea.value !== 'undefined') {
                const data = JSON.parse(textarea.value)
                dbTool.object.schema = data
                dbTool._dirty = new Date().toISOString()
              }
            },
          })
        )
      )
    )
}

export const businessEditor = BusinessEditor.elementCreator({
  tag: 'business-editor',
}) as ElementCreator<BusinessEditor>

const business = collections.find(
  (c) => c.name === 'business-profile'
) as CollectionSpec
business.editor = businessEditor
