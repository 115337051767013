import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { icons } from 'xinjs-ui'

const { slot } = elements

class LoadingSpinner extends WebComponent {
  content = () => [icons.refresh({ class: 'icon-refresh spin' }), slot()]
}

export const loadingSpinner = LoadingSpinner.elementCreator({
  tag: 'loading-spinner',
  styleSpec: {
    ':host': {
      display: 'flex',
      alignItems: 'center',
      gap: vars.spacing50,
      padding: vars.spacing,
    },
  },
}) as ElementCreator<LoadingSpinner>
