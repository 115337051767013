import { Component as WebComponent, elements } from 'xinjs'
import { logEvent, auth } from '../firebase'
import {
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
} from 'firebase/auth'

const { dialog, h2, button, div } = elements

const defaultSuccessHander = (result: UserCredential): void => {
  console.log(result)
}
const defaultErrorHandler = (error: Error): void => {
  console.error(error)
}

export function signinWithGoogle(
  successCallback = defaultSuccessHander,
  failureCallback = defaultErrorHandler
): void {
  logEvent('login', {
    method: 'google',
  })
  const provider = new GoogleAuthProvider()
  signInWithPopup(auth, provider).then(successCallback).catch(failureCallback)
}

class SignIn extends WebComponent {
  content = dialog(
    div(
      {
        class: 'column',
        style: {
          textAlign: 'center',
          alignItems: 'stretch',
          minWidth: '240px',
        },
      },
      h2({ style: { marginTop: 0 } }, 'Sign in'),
      button('Sign in with Google', {
        class: 'default',
        onClick: () => {
          const { dialog } = this.parts as { dialog: HTMLDialogElement }
          signinWithGoogle(() => {
            dialog.close()
          })
        },
      }),
      button('Sign in via Cell Phone')
    )
  )

  open = (): void => {
    const { dialog } = this.parts as { dialog: HTMLDialogElement }
    dialog.hidden = false
    dialog.showModal()
  }
}

export const signIn = SignIn.elementCreator({ tag: 'sign-in' })()
