import {
  SlCarousel
} from "./chunk.24SEFWZE.js";

// src/components/carousel/carousel.ts
var carousel_default = SlCarousel;
SlCarousel.define("sl-carousel");

export {
  carousel_default
};
