export const PIVOT_DATE = new Date('2022-03-31').toISOString()

export interface Message {
  _id?: string
  _created?: string
  from?: string
  to: string
  cc?: string
  bcc?: string
  // true if we do not recognize the sender or any recipient
  isOrphan?: boolean
  sendEmail?: boolean
  sendError?: string
  dateReceived?: string
  // set when we know the message is first read (either locally or via pixel)
  dateRead?: string
  subject: string
  text?: string
  html?: string
  caseId?: string
  tags?: string[]
}
