import {
  xinProxy,
  XinProxyArray,
  ElementPart,
  elements,
  vars,
  observe,
  touch,
  getListItem,
  XinObject,
  xinValue,
} from 'xinjs'
import { app } from '../app'
import { User } from 'firebase/auth'
import { Unsubscribe } from 'firebase/firestore'
import {
  syncRecords,
  getRecord,
  getRecords,
  service,
  setRecord,
  authStateChangeListeners,
} from '../firebase'
import { messageEditor } from './message-editor'
import { error, success, warn } from '../notifications'
import { Message } from '../mocks/message'
import { Case } from '../mocks/case'
import {
  tabSelector,
  dataTable,
  xinFloat,
  icons,
  markdownViewer,
  xinSizer,
  filterBuilder,
  popMenu,
  MenuItem,
  xinTagList,
} from 'xinjs-ui'
import { BrandProfile } from '../mocks/business-profile'
import { summarize } from '../mocks/prompts'
import { Snippet, snippetActions } from '../mocks/snippet'
import { loadingSpinner } from './loading-spinner'
import { disclose } from './disclose'
import { makeSorter } from '../sort'
import { hasFieldContaining } from '../filters'
import { inferTags, addTags, removeTags } from '../tags'

const CASE_TAGS = [
  'paid',
  'escalated',
  'resolved',
  'closed',
  'reviewed',
  'featured',
]

const messageSorter = makeSorter((message: Message) => {
  const timestamp =
    message._created !== undefined ? new Date(message._created).valueOf() : 0
  return [timestamp]
}, false)

async function performSnippet(
  snippet: Snippet,
  message?: Message,
  caseId?: string
): Promise<void> {
  const action = snippetActions[snippet.action]

  const caseData =
    caseId !== undefined
      ? ((await getRecord('case', caseId)) as Case | undefined)
      : undefined

  if (action === undefined) {
    error(
      'Snippet action not found, you might want to check that the image has an ACTION specified. If not, complain to coders.'
    )
    return
  }

  if (snippet.action === 'add to message' && message !== undefined) {
    if (caseId !== undefined) {
      message.caseId = caseId
    }
    action(snippet, message, caseData).catch((err) => {
      error(`Snippet ${snippet.name} threw an error`, err)
    })
  } else {
    if (inbox.currentMessage !== undefined) {
      error('You have an open, unsent message. Send or close it first.')
    }
    const newMessage: Message = {
      to: '',
      subject: '',
      from: '',
      html: '',
    }
    if (caseId !== undefined) {
      newMessage.caseId = caseId
    }
    action(snippet, newMessage, caseData)
      .then(() => {
        inbox.currentMessage = newMessage
      })
      .catch((err) => {
        error(`Snippet ${snippet.name} threw an error`, err)
      })
  }
}

const {
  h3,
  h4,
  div,
  button,
  input,
  span,
  template,
  a,
  strong,
  details,
  summary,
  label,
  select,
  option,
} = elements

const infoStyle = {
  padding: vars.spacing,
  background: vars.inputBg,
  borderRadius: `0 0 ${vars.roundedRadius} ${vars.roundedRadius50}`,
  maxHeight: '20vh',
  overflow: 'auto',
}

interface NononoUser {
  _id: string
  uid: string
  email: string
  emailVerified: boolean
  description: string
}

interface FilterSpec {
  haystack: string
  condition: string
  needle: string
}
interface MessageFilter {
  name: string
  spec: FilterSpec[]
  bg?: string
  color?: string
}

export const { inbox } = xinProxy({
  inbox: {
    users: [] as NononoUser[],
    cases: [] as Case[],
    messages: [] as (Message & { caseData?: Case })[],
    messageFilters: [] as MessageFilter[],
    messageFilter: (a: any) => a,
    items: [] as any[],
    displayedMessage: undefined as Message | undefined,
    unsubscribeCases: () => {},
    currentMessage: undefined as Message | undefined,
    unsubscribeMessages: () => {},
    currentMessages: [] as Message[],
    currentCase: undefined as Case | undefined,
    currentBusiness: undefined as BrandProfile | undefined,
    loadingMessages: false,
    updateMessages() {
      const caseIds = new Set()
      for (const caseData of xinValue(inbox.cases)) {
        caseData.tags = inferTags(caseData, {
          isPaid: 'paid',
          isEscalated: 'escalated',
          isResolved: 'resolved',
          isClosed: 'closed',
        }).tags
      }
      for (const message of xinValue(inbox.messages)) {
        if (message.tags === undefined) {
          message.tags = []
        }
        // infer from subject
        if (message.caseId === undefined) {
          const [, , possibleId] =
            message.subject.match(/(\[|#|Ref|Ref:)\s?([\da-z]{12,})/) || []
          if (possibleId !== undefined) {
            console.log('inferred caseId from subject')
            message.caseId = possibleId
          }
        }
        // infer from sender
        if (message.caseId === undefined && message.from !== undefined) {
          const to = Array.isArray(message.to)
            ? message.to
            : (message.to || '').split(',').map((s) => s.trim())
          const caseData = inbox.cases.find(
            (c) =>
              c.email === message.from ||
              to.find((email) => email === message.from)
          )
          if (caseData !== undefined) {
            message.caseId = caseData._id
            message.caseData = caseData
          }
        }
        if (message.caseId !== undefined) {
          if (!caseIds.has(message.caseId)) {
            if (!(message.from || '').includes('nonono.com')) {
              addTags(message, 'respond')
              if (
                Date.now() - new Date(message._created!).valueOf() >
                24 * 3600 * 1000
              ) {
                addTags(message, 'urgent')
              }
            }
            caseIds.add(message.caseId)
          } else {
            removeTags(message, 'urgent')
          }
          if (message.caseData === undefined) {
            const caseData = inbox.cases.find((c) => c._id === message.caseId)
            if (caseData !== undefined) {
              message.caseData = caseData
              addTags(message, ...(caseData.tags as string[]))
            }
          }
        }
      }
      inbox.messages.sort(messageSorter)
      touch(inbox.messages)
    },
    snippets: [] as Snippet[],
    unsubscribeSnippets: () => {},
  },
})

getRecord('config', 'filters').then((filters) => {
  inbox.messageFilters = filters ? filters.messageFilters || [] : []
})

observe(/^(inbox.cases|inbox.messages)\b/, inbox.updateMessages)

let unsubscribe: Unsubscribe | undefined

const inboxSignIn = async (user: User | null): Promise<void> => {
  if (user == null) {
    if (unsubscribe !== undefined) {
      unsubscribe()
      unsubscribe = undefined
    }
  } else {
    app.privileges = await service.privileges.get()
    const { uid } = user
    inbox.unsubscribeCases()
    inbox.unsubscribeMessages()
    inbox.unsubscribeSnippets()
    inbox.users = []
    if (uid !== undefined) {
      inbox.unsubscribeCases = await syncRecords(
        inbox.cases as unknown as XinProxyArray,
        'case',
        undefined,
        [
          '_id',
          '_created',
          'businessName',
          'title',
          'email',
          'assignedUserId',
          'price',
          'isPaid',
          'isEscalated',
          'isResolved',
          'isClosed',
          'tags',
        ]
      )
      inbox.unsubscribeMessages = await syncRecords(
        inbox.messages as unknown as XinProxyArray,
        'message',
        undefined,
        ['_id', '_created', 'subject', 'from', 'to', 'businessId', 'caseId']
      )
      inbox.unsubscribeSnippets = await syncRecords(
        inbox.snippets as unknown as XinProxyArray,
        'template'
      )
      inbox.users = [
        {
          _id: 'does-not-exist',
          description: 'unassigned',
          email: '',
          emailVerified: false,
          uid: 'unassigned',
        },
        ...(await getRecords('privileges'))
          .filter(
            (user: NononoUser) =>
              user.email.endsWith('nonono.com') && user.emailVerified
          )
          .map((user: NononoUser) => ({
            ...user,
            uid: user._id,
            description:
              user.description === undefined
                ? user._id.slice(-6)
                : user.description,
          })),
      ]
    }
  }
}

authStateChangeListeners.add((user) => {
  inboxSignIn(user).catch(() => {})
})

const newMessage = async (
  event: UIEvent,
  to = '',
  subject = ''
): Promise<void> => {
  if (inbox.currentMessage !== undefined) {
    if (!confirm('You have a message open, this will replace it. Proceed?')) {
      return
    }
  }
  const target = event.target as HTMLElement
  const typeElement = target.closest('[data-message-type]') as HTMLElement
  const messageType = typeElement.dataset.messageType
  const currentCase = inbox.currentCase
  if (subject === '' && currentCase !== undefined) {
    subject = `Re: ${currentCase.title as string} Ref #${currentCase._id}`
  }
  switch (messageType) {
    case 'business':
      to = (inbox.currentBusiness?.escalationEmails || []).join(', ')
      break
    case 'customer':
      to = currentCase ? currentCase.email : ''
      break
    case 'reply':
      {
        const currentMessage = await getRecord(
          'message',
          getListItem(target)._id
        )
        to = typeof currentMessage.from === 'string' ? currentMessage.from : ''
        subject = currentMessage.subject
        if (!subject.startsWith('Re:')) {
          subject = `Re: ${subject}`
        }
      }
      break
    default:
  }
  inbox.currentMessage = {
    to,
    subject,
    caseId: currentCase?._id,
  }
}

const inboxPicker = () => {
  if (window.location.pathname !== '/inbox') {
    return
  }
  const params = new URLSearchParams(window.location.search)
  const caseId = params.get('c')
  const emailAddress = params.get('m')
  inbox.currentCase = undefined
  inbox.currentMessages = []
  if (typeof caseId === 'string') {
    void pickCase(caseId)
  } else if (typeof emailAddress === 'string') {
    inbox.currentMessages = inbox.messages.filter((message) =>
      hasFieldContaining(message, emailAddress, 'from', 'to')
    )
  }
}

window.addEventListener('route-did-load', inboxPicker)

const pickCase = async (caseId: string): Promise<void> => {
  let currentCase = await getRecord('case', caseId)
  inbox.currentCase = currentCase
  inbox.loadingMessages = true
  try {
    const reviewLinkState = await service.case.get({
      caseId: currentCase._id,
      checkLink: 'review',
    })
    if (reviewLinkState.status !== 'ok') {
      console.log('review link updated')
      inbox.currentCase = currentCase = await getRecord('case', currentCase._id)
    }
  } catch (err) {
    error('An error occurred while verifying review link state', err)
  }
  if (currentCase.summary === undefined) {
    if (
      currentCase.description === undefined ||
      currentCase.description.trim() === ''
    ) {
      currentCase.summary = 'case has no content'
    } else if (currentCase.description.length < 500) {
      currentCase.summary = currentCase.description
    } else {
      currentCase.summary = loadingSpinner('creating summary…')
      try {
        const response = await service.askgpt.post({
          prompt: `${summarize}\n\n${currentCase.description as string}`,
        })
        currentCase.summary = response.result[0].text.replace(/^• /g, '- ')
        touch('inbox.currentCase')
        await setRecord('case', currentCase)
      } catch (err) {
        error('gpt service failed', err)
        currentCase.summary = 'Sorry, gpt could not summarize this case.'
      }
    }
  }
  inbox.currentBusiness = undefined
  console.log('clearing currentBusiness', inbox.currentBusiness)
  if (currentCase !== undefined) {
    getRecord('business-profile', currentCase.businessId)
      .then((business) => {
        if (business !== undefined) {
          inbox.currentBusiness = business as unknown as BrandProfile
          console.log('setting currentBusiness', inbox.currentBusiness)
        }
      })
      .catch((err) => {
        error(
          `Failed to load business-profile/${currentCase.businessId as string}`,
          err
        )
      })
  }
}

const showMessage = async (messageId: string): Promise<void> => {
  const message = await getRecord('message', messageId)
  const { email, nameFirst, nameLast, phone } = message
  if (phone !== undefined) {
    if (message.from === undefined) {
      message.from = email
    }
    message.type = 'form'
    message.text = `${message.text}\n\n<a href="mailto:${email}">${nameFirst} ${nameLast}</a><br><a href="tel:${phone}">${phone}</a>`
  }
  if (message !== false) {
    inbox.displayedMessage = message
  }
}

const messageList = dataTable({
  rowHeight: '45px',
  select: true,
  style: {
    flex: '1 1 auto',
  },
  bindDataTable: inbox.messages,
  selectionChanged(selected: Message[]) {
    if (selected.length === 1) {
      void showMessage(selected[0]._id!)
    }
  },
})

messageList.columns = [
  {
    name: 'sent',
    prop: '_created',
    width: 120,
    dataCell() {
      return span({ class: 'td', bindDate: '^._created' })
    },
  },
  {
    prop: 'message',
    width: 300,
    dataCell() {
      return span(
        { class: 'td', style: { position: 'relative' } },
        span({ class: 'bubble', bindMessageBubble: '^' })
      )
    },
  },
  {
    name: 'summary',
    prop: 'subject',
    width: 1000,
    dataCell() {
      return span(
        { class: 'td row' },
        span({
          class: 'row',
          style: { gap: '2px', alignItems: 'center' },
          bindShow: '^.tags.length',
          bindTags: '^.tags',
        }),
        span({ style: { marginLeft: vars.spacing50 }, bindMessageSummary: '^' })
      )
    },
  },
]

function replyTo(message: Message) {
  const newMessage: Message = {
    to: message.from || '',
    subject: message.subject.toLocaleLowerCase().startsWith('re:')
      ? message.subject
      : `Re: ${message.subject}`,
  }
  if (message.caseId !== undefined) {
    newMessage.caseId = message.caseId
  }
  inbox.currentMessage = newMessage
}

const messageView = (): HTMLElement =>
  xinFloat(
    {
      drag: true,
      bindShow: 'inbox.displayedMessage',
      style: {
        position: 'fixed',
        bottom: vars.spacing,
        left: vars.spacing,
        maxHeight: `calc(100% - ${vars.toolbarHeight} - ${vars.spacing600})`,
        width: `calc(100% - ${vars.spacing400})`,
        maxWidth: '50em',
        padding: vars.spacing,
        boxShadow: vars.outlineShadow,
      },
    },
    div(
      {
        class: 'no-drag row',
        style: {
          position: 'absolute',
          top: vars.spacing50,
          right: vars.spacing50,
        },
      },
      button(
        {
          bindShow: 'inbox.displayedMessage.caseId',
          class: 'primary',
          onClick() {
            void pickCase(inbox.displayedMessage!.caseId as string)
          },
        },
        span('Show Case')
      ),
      button(
        {
          bindShow: 'inbox.displayedMessage.caseId',
          onClick(event: Event) {
            popSnippetsMenu(
              (event.target as HTMLElement).closest(
                'button'
              ) as HTMLButtonElement,
              undefined,
              inbox.displayedMessage!.caseId
            )
          },
        },
        icons.moreVertical()
      ),
      button(
        {
          class: 'default',
          title: 'reply',
          onClick() {
            replyTo(inbox.displayedMessage!)
          },
        },
        icons.messageCircle()
      ),
      button(
        {
          onClick() {
            inbox.displayedMessage = undefined
          },
        },
        icons.x()
      )
    ),
    div(
      { class: 'row' },
      strong('from:', { style: { width: '100px' } }),
      span({ bindText: 'inbox.displayedMessage.from' })
    ),
    div(
      { class: 'row' },
      strong('to:', { style: { width: '100px' } }),
      span({ bindText: 'inbox.displayedMessage.to' })
    ),
    div(
      { class: 'row', bindShow: 'inbox.displayedMessage.cc' },
      strong('cc:', { style: { width: '100px' } }),
      span({ bindText: 'inbox.displayedMessage.cc' })
    ),
    div(
      { class: 'row', bindShow: 'inbox.displayedMessage.bcc' },
      strong('bcc:', { style: { width: '100px' } }),
      span({ bindText: 'inbox.displayedMessage.bcc' })
    ),
    div(
      { class: 'row' },
      strong('sent:', { style: { width: '100px' } }),
      span({ bindDate: 'inbox.displayedMessage._created' })
    ),
    h4({
      class: 'no-drag',
      bindText: 'inbox.displayedMessage.subject',
      style: { margin: `${vars.spacing50} 0` },
    }),
    div(
      { class: 'elastic', style: { overflow: 'hidden auto' } },
      div({
        class: 'no-drag',
        bindShow: 'inbox.displayedMessage.html',
        bindHtml: 'inbox.displayedMessage.html',
      }),
      markdownViewer({
        class: 'no-drag',
        bindHide: 'inbox.displayedMessage.html',
        bindValue: 'inbox.displayedMessage.text',
      })
    ),
    xinSizer({ class: 'no-drag' })
  )

const sortByName = makeSorter((o: any) => [String(o.name)])
const popSnippetsMenu = (
  target: HTMLElement,
  message?: Message,
  caseId?: string
): void => {
  console.log(message, caseId)
  if (!caseId) {
    caseId = message?.caseId
  }
  const menuItems: MenuItem[] = inbox.snippets
    .filter(
      (snippet) =>
        snippet.isVisibleInMenus &&
        (snippet.context === 'none' ||
          (snippet.context === 'case' && caseId !== undefined) ||
          (snippet.context === 'message' && message !== undefined))
    )
    .map((snippet) => ({
      caption: snippet.name,
      action() {
        performSnippet(snippet, message, caseId)
      },
    }))
    .sort(sortByName)

  if (menuItems.length && caseId) {
    menuItems.push(null, {
      icon: 'edit',
      caption: 'Open case in admin…',
      action: `/data?c=case&id=${caseId}`,
    })
  }
  if (menuItems.length > 0) {
    popMenu({
      target,
      menuItems,
      width: 300,
    })
  } else {
    warn('No snippets are available in this context')
  }
}

const caseAdminView = (...contents: ElementPart[]): HTMLElement =>
  xinFloat(
    ...contents,
    {
      bindShow: 'inbox.currentCase',
      drag: true,
      style: {
        position: 'fixed',
        bottom: vars.spacing,
        right: vars.spacing,
        width: `calc(100% - ${vars.spacing400})`,
        maxWidth: '50em',
        height: 'auto',
        maxHeight: '100%',
        boxShadow: vars.outlineShadow,
      },
    },
    details(
      {
        class: 'case-admin-view',
        open: true,
        onInput(event: Event) {
          const adminView = (event.target as HTMLElement).closest(
            '.case-admin-view'
          ) as HTMLElement
          const saveButton = adminView.querySelector(
            '.save-button'
          ) as HTMLButtonElement
          saveButton.disabled = false
        },
      },
      summary(
        {
          class: 'row primary',
          style: {
            padding: vars.spacing50,
            alignItems: 'baseline',
          },
        },
        disclose(),
        h4({
          bindText: 'inbox.currentCase.title',
          style: { margin: 0 },
        }),
        span({ class: 'elastic' }),
        span({ bindDate: 'inbox.currentCase._created' }),
        button(icons.moreVertical(), {
          class: 'no-drag',
          bindEnabled: 'inbox.snippets.length',
          onClick(event: Event) {
            popSnippetsMenu(
              (event.target as HTMLElement).closest(
                'button'
              ) as HTMLButtonElement,
              undefined,
              inbox.currentCase?._id
            )
          },
        }),
        button(
          {
            class: 'no-drag',
            onClick() {
              inbox.currentCase = undefined
            },
          },
          icons.x()
        )
      ),
      div(
        { class: 'row no-drag', style: { alignItems: 'center' } },
        button(
          {
            onClick: newMessage,
            dataMessageType: 'business',
            bindEnabled: 'inbox.currentBusiness.escalationEmails.length',
            title: 'email business',
          },
          icons.mail()
        ),
        h3({ class: 'no-margin', bindText: 'inbox.currentCase.businessName' }),
        span({ class: 'elastic' })
      ),
      div(
        { class: 'row no-drag', style: { alignItems: 'center' } },
        button(
          {
            title: 'email customer',
            onClick: newMessage,
            dataMessageType: 'customer',
          },
          icons.mail(),
          span({ class: 'show-if-wide spacer' }),
          span({ class: 'show-if-wide', bindText: 'inbox.currentCase.email' })
        ),
        strong({ bindText: 'inbox.currentCase.nameFirst' }),
        strong({ bindText: 'inbox.currentCase.nameLast' }),
        span({ class: 'elastic' }),
        span({ bindText: 'inbox.currentCase.mobilePhone' })
      ),
      tabSelector(
        { class: 'no-drag' },
        div({
          name: 'details',
          bindMarkdown: 'inbox.currentCase.description',
          style: infoStyle,
        }),
        div({
          name: 'summary',
          bindMarkdown: 'inbox.currentCase.summary',
          dataMarkdownOptions: 'no-newlines replace-bullets',
          style: infoStyle,
        }),
        div(
          { name: 'links' },
          div(
            { class: 'row', style: { alignItems: 'center' } },
            a('Review Link', {
              target: '_blank',
              bindReviewlink: 'inbox.currentCase',
            }),
            button(
              {
                class: 'compact',
                onClick() {
                  const { _id, reviewLink } = inbox.currentCase!
                  navigator.clipboard.writeText(
                    `https://nonono.com/case/${_id}/?r=${reviewLink[0].token}`
                  )
                },
              },
              icons.copy()
            )
          )
        ),
        div(
          { name: 'status' },
          xinTagList({
            title: 'tags',
            class: 'elastic',
            custom: true,
            editable: true,
            bindValue: 'inbox.currentCase.tags',
            availableTags: CASE_TAGS,
            style: {
              marginTop: vars.spacing50,
            },
          }),
          div(
            label(
              input({
                type: 'checkbox',
                bindValue: 'inbox.currentCase.isPaid',
              }),
              span('paid')
            ),
            label(
              input({
                type: 'checkbox',
                bindValue: 'inbox.currentCase.isEscalated',
              }),
              span('escalated')
            ),
            label(
              input({
                type: 'checkbox',
                bindValue: 'inbox.currentCase.isResolved',
              }),
              span('resolved')
            ),
            label(
              input({
                type: 'checkbox',
                bindValue: 'inbox.currentCase.isReviewed',
              }),
              span('reviewed')
            ),
            label(
              input({
                type: 'checkbox',
                bindValue: 'inbox.currentCase.isClosed',
              }),
              span('closed')
            )
          )
        ),
        div(
          {
            class: 'toolbar',
            style: {
              background: 'transparent',
              margin: `${vars.spacing50} ${vars.spacing_100} 0`,
            },
          },
          span({ class: 'elastic' }),
          select(
            {
              title: 'case assignment',
              bindValue: 'inbox.currentCase.assignedUserId',
              bindList: {
                value: inbox.users,
                idPath: 'uid',
              },
            },
            template(option({ bindText: '^.description', bindValue: '^.uid' }))
          ),
          icons.chevronDown(),
          span({ class: 'spacer' }),
          button('Save', {
            disabled: true,
            class: 'default save-button',
            onClick(event: Event) {
              const { currentCase } = inbox
              if (currentCase === undefined) {
                return
              }
              const saveButton = (event.target as HTMLElement).closest(
                'button'
              ) as HTMLButtonElement
              saveButton.disabled = true
              if (currentCase !== undefined) {
                setRecord('case', currentCase as unknown as XinObject)
                  .then(() => {
                    success(
                      `Saved case ${currentCase.title as string} as case/${
                        currentCase._id
                      }`
                    )
                  })
                  .catch((err) => {
                    error(
                      `Error saving case ${
                        currentCase.title as string
                      } as case/${currentCase._id}`,
                      err
                    )
                  })
              }
            },
          })
        )
      )
    )
  )

const messageFilterBuilder = filterBuilder({
  class: 'elastic',
  fields: [
    { prop: 'subject' },
    { prop: 'from' },
    { prop: 'to' },
    { prop: 'html' },
    { prop: 'tags' },
  ],
  onChange(event: Event) {
    messageList.filter = (event.target as any).filter
  },
})

const messageFilterList = div(
  {
    class: 'row',
    style: {
      gap: vars.spacing30,
    },
    bindList: {
      value: inbox.messageFilters,
    },
  },
  template(
    button({
      class: 'filter',
      onClick(event) {
        const filterButton = event.target as HTMLButtonElement
        const filter = getListItem(filterButton) as MessageFilter | undefined
        if (filter !== undefined) {
          const filterButtons = [
            ...messageFilterList.querySelectorAll('button'),
          ]
          for (const button of filterButtons) {
            button.classList.toggle('active', button === filterButton)
          }
          messageFilterBuilder.state = filter.spec
        }
      },
      bindText: '^.name',
      bind: {
        value: '^',
        binding: {
          toDOM(element, value) {
            element.style.color = value.color || ''
            element.style.background = value.bg || ''
          },
        },
      },
    })
  )
)

export const inBox = (...contents: ElementPart[]): HTMLDivElement => {
  return div(
    ...contents,
    {
      class: 'in-box column',
      style: {
        alignItems: 'stretch',
        height: '100%',
        gap: 0,
      },
    },
    div(
      { class: 'toolbar primary' },
      messageFilterList,
      messageFilterBuilder,
      button(
        {
          dataTooltip: 'Save Filter',
        },
        icons.bookmark()
      ),
      span({ class: 'elastic' }),
      /*
      select(
        {
          title: 'action',
          bindList: {
            value: inbox.snippets,
            idPath: '_id',
          },
          onInput(event: Event) {
            event.preventDefault()
            event.stopPropagation()
          },
          onChange(event: Event) {
            event.preventDefault()
            performSnippet(targetFallback(event).value)
            targetFallback(event).value = PICK_ACTION_ID
          },
        },
        template(option({ bindText: '^.name', bindValue: '^._id' }))
      ),
      icons.chevronDown()
      */
      button(icons.messageCircle(), {
        bindDisabled: 'inbox.currentMessage',
        title: 'new message',
        onClick() {
          inbox.currentMessage = {
            to: '',
            subject: '',
          }
        },
      })
    ),
    messageList,
    caseAdminView(),
    messageView(),
    messageEditor(
      {
        style: {
          width: '100%',
        },
        bindShow: 'inbox.currentMessage',
        bindValue: 'inbox.currentMessage',
        // figure out why this doesn't work
        onSend(message: Message) {
          inbox.currentMessage = undefined
          inbox.currentMessages.unshift(message)
        },
      },
      button(
        {
          slot: 'topbar-widgets',
          onClick(event: Event) {
            popSnippetsMenu(
              (event.target as HTMLElement).closest(
                'button'
              ) as HTMLButtonElement,
              inbox.currentMessage,
              inbox.currentMessage?.caseId
            )
          },
        },
        icons.moreVertical()
      )
    )
  )
}
