import {
  menu_label_default
} from "../../chunks/chunk.ANQGGXQY.js";
import "../../chunks/chunk.ADO6EKKG.js";
import "../../chunks/chunk.ORTZCIID.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.RVOOE4AQ.js";
import "../../chunks/chunk.IFDWM6P4.js";
export {
  menu_label_default as default
};
