import { Component as WebComponent, elements, vars } from 'xinjs'

const { fragment, h1, slot } = elements

class BusinessSummary extends WebComponent {
  static styleSpec = {
    ':host': {
      display: 'block',
      padding: vars.spacing,
    },
  }

  content = fragment(h1(), slot())
}

export const businessSummary = BusinessSummary.elementCreator({
  tag: 'business-summary',
})
