export const oldFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
})

export const lastYearFormatter = new Intl.DateTimeFormat(navigator.language, {
  month: 'short',
  day: 'numeric',
})

export const lastWeekFormatter = new Intl.RelativeTimeFormat(
  navigator.language,
  {
    style: 'short',
  }
)

export const lastDayFormatter = new Intl.DateTimeFormat(navigator.language, {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
})

export const fullFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
})

const HOUR_MS = 3600 * 1000
const DAY_MS = 24 * HOUR_MS
const YEAR_MS = 365 * DAY_MS

export function niceDate(date: Date): string {
  const ms = Date.now() - date.valueOf()
  if (!isNaN(ms)) {
    if (ms < DAY_MS) {
      return lastDayFormatter.format(date)
    } else if (ms < HOUR_MS * 72) {
      return lastWeekFormatter.format(Math.round(-ms / HOUR_MS), 'hour')
    } else if (ms < YEAR_MS) {
      return lastYearFormatter.format(date)
    } else {
      return oldFormatter.format(date)
    }
  } else {
    return fullFormatter.format(date)
  }
}
