import { elements } from 'xinjs'
import { randomID } from '../random-id'
import {
  arrayFallback,
  numberFallback,
  stringFallback,
  targetFallback,
} from '../fallback'
import { stripePayment } from './stripe-payment'
import { populateTemplate } from '../populate-template'
import { icons, markdownViewer, xinTagList } from 'xinjs-ui'
export interface FormData {
  [key: string]: any
}

export type FormItemOption = string | { id: string; text: string }
export interface FormItem {
  type: keyof typeof ItemTypes
  caption: string
  key: string
  required?: boolean
  collection?: string
  displayField?: string
  options?: FormItemOption[]
  amount?: number
  isOtherAllowed?: boolean
}

export interface FormPage {
  title: string
  nextCaption: string
  items: FormItem[]
}

export interface Form {
  id: string
  title: string
  completionMessage?: string
  pages: FormPage[]
  path?: string
  collection?: string
}

const {
  fragment,
  label,
  input,
  fieldset,
  legend,
  div,
  span,
  datalist,
  option,
  textarea,
  mdViewer,
  select,
  slRating,
} = elements

function placeholder(item: FormItem, ph = ' '): string {
  return item.required === true ? ph : `${ph} ( optional )`
}

const caption = (item: FormItem, formData: FormData): HTMLDivElement => {
  return div(
    markdownViewer(populateTemplate(item.caption, formData._context)),
    span({ class: 'elastic' })
  )
}

export const ItemTypes = {
  info(item: FormItem, formData: FormData) {
    return mdViewer(populateTemplate(item.caption, formData._context))
  },

  pickOption(item: FormItem, formData: FormData) {
    const listId = randomID().replace(/-/g, '')

    return fragment(
      label(
        caption(item, formData),
        input({
          apply(elt: HTMLElement) {
            elt.setAttribute('list', listId)
            elt.addEventListener('input', (evt: Event) => {
              const elt = evt.target as HTMLInputElement
              if (
                item.options?.find((option) =>
                  typeof option === 'string'
                    ? option === elt.value
                    : option.text === elt.value
                ) == null
              ) {
                elt.setCustomValidity('We do not recognize this business')
              } else {
                elt.setCustomValidity('')
              }
            })
          },
          name: item.key,
          placeholder: placeholder(item),
          value: formData[item.key] != null ? formData[item.key] : '',
          required: item.required,
        }),
        datalist(
          {
            id: listId,
          },
          ...arrayFallback(item.options).map((_option) =>
            typeof _option === 'string'
              ? option({ value: _option })
              : option({ value: _option.text })
          )
        )
      )
    )
  },

  pickOne(item: FormItem, formData: FormData) {
    return fieldset(
      legend(populateTemplate(item.caption, formData._context)),
      ...arrayFallback(item.options).map((_option) => {
        const { id, text } =
          typeof _option === 'string' ? { id: _option, text: _option } : _option
        return label(
          input({
            type: 'radio',
            value: id,
            checked: formData[item.key] === id,
          }),
          span(text)
        )
      })
    )
  },

  selectOne(item: FormItem, formData: FormData) {
    const selectElement = select(
      option('Please select an option…', { value: '' }),
      ...arrayFallback(item.options).map((_option) =>
        option(_option, {
          selected: (_option === formData[item.key]) === _option,
        })
      ),
      ...(item.isOtherAllowed === true
        ? [option('Other (please specify)…', { value: '__specify__' })]
        : []),
      {
        name: item.key,
        onChange() {
          specify.hidden = selectElement.value !== '__specify__'
        },
        required: item.required,
      }
    )
    const specify = input({
      name: item.key,
      placeholder: item.caption,
      hidden: true,
    })
    return label(
      span(populateTemplate(item.caption, formData._context)),
      selectElement,
      specify
    )
  },

  string(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        name: item.key,
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  tagList(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      xinTagList({
        name: item.key,
        custom: true,
        value: Array.isArray(formData[item.key])
          ? formData[item.key]
          : formData[item.key].split(','),
        availableTags: item.options || [],
      })
    )
  },

  int(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        name: item.key,
        type: 'number',
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  rating(item: FormItem, formData: FormData) {
    const stars = slRating({
      value: typeof formData[item.key] === 'number' ? formData[item.key] : 0,
    })
    const hiddenInput = input({
      class: 'hidden-input',
      type: 'number',
      tabindex: -1,
      name: item.key,
      required: item.required,
      value:
        typeof formData[item.key] === 'number'
          ? String(formData[item.key])
          : '',
    })
    stars.addEventListener('sl-change', (event: Event) => {
      const value = Number((event.target as HTMLInputElement).value)
      hiddenInput.value = value > 0 ? String(value) : ''
      // FIXME for some reason triggering the change event doesn't work?!
      if (hiddenInput.value !== '') {
        formData[item.key] = Number(hiddenInput.value)
      } else {
        delete formData[item.key]
      }
    })
    return label(
      caption(item, formData),
      span({ class: 'row' }, hiddenInput, stars)
    )
  },

  currency(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        name: item.key,
        type: 'number',
        step: 0.01,
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  float(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        name: item.key,
        type: 'number',
        step: 'any',
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  text(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      textarea({
        name: item.key,
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  json(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      textarea({
        name: '.',
        placeholder: placeholder(item, 'valid JSON'),
        value: JSON.stringify(formData, null, 2),
        required: item.required,
      })
    )
  },

  email(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        type: 'email',
        placeholder: placeholder(item),
        name: item.key,
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  phone(item: FormItem, formData: FormData) {
    return label(
      caption(item, formData),
      input({
        type: 'tel',
        name: item.key,
        pattern: '\\+?\\d[\\d\\-\\s]{7,}',
        placeholder: placeholder(item),
        value: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
      })
    )
  },

  boolean(item: FormItem, formData: FormData) {
    return label(
      {
        style: {
          flexDirection: 'row',
        },
      },
      input({
        type: 'checkbox',
        name: item.key,
        checked: formData[item.key] === true,
        required: item.required,
        style: { alignSelf: 'center' },
      }),
      span(populateTemplate(item.caption, formData._context))
    )
  },

  thumbs(item: FormItem, formData: FormData) {
    return label(
      markdownViewer(populateTemplate(item.caption, formData._context)),
      span(
        { class: 'row', style: { alignItems: 'center', gap: 0 } },
        input({
          type: 'radio',
          name: item.key,
          value: 'yes',
          tabindex: -1,
          required: item.required,
          checked: formData[item.key] === 'yes',
          class: 'hidden-input',
        }),
        span(
          {
            class: 'selectable icon-true',
            title: 'yes',
            tabindex: 0,
            onClick(event: Event) {
              ;(
                targetFallback(event)
                  .previousElementSibling as HTMLButtonElement
              ).click()
              event.preventDefault()
            },
            onKeydown(event: Event) {
              if ((event as KeyboardEvent).key === ' ') {
                ;(
                  targetFallback(event)
                    .previousElementSibling as HTMLButtonElement
                ).click()
                event.preventDefault()
              }
            },
          },
          icons.thumbsUp(),
          span('Yes')
        ),
        span({ class: 'spacer' }),
        input({
          type: 'radio',
          name: item.key,
          value: 'no',
          tabindex: -1,
          required: item.required,
          checked: formData[item.key] === 'no',
          class: 'hidden-input',
        }),
        span(
          {
            class: 'selectable icon-true',
            title: 'no',
            tabindex: 0,
            onClick(event: Event) {
              ;(
                targetFallback(event)
                  .previousElementSibling as HTMLButtonElement
              ).click()
              event.preventDefault()
            },
            onKeydown(event: Event) {
              if ((event as KeyboardEvent).key === ' ') {
                ;(
                  targetFallback(event)
                    .previousElementSibling as HTMLButtonElement
                ).click()
                event.preventDefault()
              }
            },
          },
          icons.thumbsDown(),
          span('No')
        )
      )
    )
  },

  mustcheck(item: FormItem, formData: FormData) {
    return label(
      {
        style: {
          flexDirection: 'row',
        },
      },
      input({
        type: 'checkbox',
        name: item.key,
        checked: formData[item.key] != null ? formData[item.key] : '',
        required: item.required,
        style: { alignSelf: 'center' },
      }),
      mdViewer(populateTemplate(item.caption, formData._context))
    )
  },

  payment(item: FormItem, formData: FormData, form: Form) {
    const amount = numberFallback(formData.price, 15).toFixed(2)
    const name = formData.businessName
    const origin = stringFallback(window.location.origin)
    const path = stringFallback(form.path)
    const id = stringFallback(formData._id)
    const title =
      typeof formData.title === 'string'
        ? '/' +
          encodeURIComponent(
            formData.title.replace(/( )+/g, '-').toLocaleLowerCase()
          )
        : ''

    return fragment(
      markdownViewer(populateTemplate(item.caption, formData._context)),
      stripePayment({
        items: [{ id: formData._id, name, amount }],
        name: item.key,
        value: formData[item.key],
        destinationUrl: `${origin}/${path}/${id}${title}`,
      })
    )
  },
}
