import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { xinFloat, xinSizer, icons } from 'xinjs-ui'
import { closeButton } from './close-button'
import { uploadFile, listFiles, pathToUrl, deleteFile } from '../firebase'
import { success, error } from '../notifications'

const { h4, label, input, button, div, select, option, span } = elements

export const fileType = (path: string): string => {
  switch (path.split('.').pop()) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
    case 'webp':
    case 'gif':
      return 'image'
    case 'mov':
    case 'mp4':
    case 'm4v':
      return 'video'
    default:
      return 'special'
  }
}

class AssetManager extends WebComponent {
  uploadFile = () => {
    const { fileInput, pathSelector, filePath } = this.parts as {
      fileInput: HTMLInputElement
      pathSelector: HTMLSelectElement
      filePath: HTMLInputElement
    }
    const basePath = pathSelector.value
    if (fileInput.files?.length === 1) {
      const file = fileInput.files[0]
      uploadFile(file, `/${basePath}/${filePath.value}`).then(
        (path: string) => {
          success(`${file.name} uploaded to path '${path}'`)
          filePath.value = ''
          fileInput.value = ''
          this.getFiles()
        }
      )
    } else {
      error('Pick a file first!')
    }
  }

  setPath = () => {
    const { fileInput, filePath } = this.parts as {
      fileInput: HTMLInputElement
      filePath: HTMLInputElement
    }
    if (fileInput.files?.length === 1) {
      filePath.value = fileInput.files[0].name.replace(/\s+/g, '-')
    } else {
      filePath.value = ''
    }
  }

  makeAsset = (file: { name: string; path: string }): HTMLDivElement => {
    const { getFiles } = this
    return div(
      { class: 'row', style: { alignItems: 'center' } },
      span(file.name, { class: 'text-nowrap elastic' }),
      button(
        {
          title: 'copy markdown',
          async onClick() {
            const url = await pathToUrl(file.path)
            if (url === undefined) {
              error(`${file.path} no longer found in storage`)
              return
            }
            switch (fileType(file.path)) {
              case 'image':
                navigator.clipboard.writeText(`![${file.name}](${url})`)
                break
              case 'video':
                navigator.clipboard.writeText(
                  `<video title="${file.name}" src="${url}">`
                )
                break
              default:
                navigator.clipboard.writeText(`[${file.name}](${url})`)
            }
          },
        },
        icons.copy()
      ),
      button(
        {
          title: 'copy <img> tag',
          async onClick() {
            const url = await pathToUrl(file.path)
            if (url === undefined) {
              error(`${file.path} no longer found in storage`)
              return
            }
            switch (fileType(file.path)) {
              case 'image':
                navigator.clipboard.writeText(
                  `<img alt="${file.name}" src="${url}">`
                )
                break
              case 'video':
                navigator.clipboard.writeText(
                  `<video title="${file.name}" controls src="${url}"></video>`
                )
                break
              default:
                navigator.clipboard.writeText(`[${file.name}](${url})`)
            }
          },
        },
        icons.code()
      ),
      button(
        {
          title: 'delete',
          async onClick() {
            if (confirm(`Delete "${file.path}"?`)) {
              if (await deleteFile(file.path)) {
                success(
                  `File ${file.path} was has ceased to be. Bereft of life, it rests in peace. It is a an ex-file.`
                )
              } else {
                error(
                  `${file.path} was not deleted, or was already deleted by someone else. Who knows…?`
                )
              }
              getFiles()
            }
          },
        },
        icons.trash()
      )
    )
  }

  getFiles = async () => {
    const { pathSelector, assetList } = this.parts as {
      pathSelector: HTMLSelectElement
      assetList: HTMLElement
    }
    const files = await listFiles(pathSelector.value)
    assetList.textContent = ''

    assetList.append(...files.map(this.makeAsset))
  }

  content = () =>
    xinFloat(
      {
        class: 'compact',
        drag: true,
        style: {
          bottom: '10px',
          left: '10px',
          maxWidth: '100%',
          minHeight: '200px',
          width: '300px',
          boxShadow: vars.zShadow,
          borderRadius: vars.roundedRadius,
          overflow: 'hidden',
        },
      },
      h4('Asset Manager', {
        class: 'primary',
        style: { textAlign: 'center', padding: vars.spacing50 },
      }),
      label(
        {
          class: 'row no-drag',
          style: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: `${vars.spacing50} ${vars.spacing}`,
          },
        },
        span('Path'),
        select(
          { part: 'pathSelector', onChange: this.getFiles },
          option('internal', { selected: true }),
          option('public')
        ),
        icons.chevronDown()
      ),
      div({
        part: 'assetList',
        class: 'column elastic no-drag',
        style: {
          height: '300px',
          overflow: 'hidden scroll',
          alignItems: 'stretch',
          content: ' ',
          margin: `${vars.spacing50} ${vars.spacing}`,
        },
      }),
      div(
        {
          class: 'column no-drag',
          style: {
            alignItems: 'stretch',
            padding: vars.spacing,
            gap: vars.spacing50,
            background: vars.panelBg,
          },
        },
        label(
          { class: 'row', style: { alignItems: 'center', padding: 0 } },
          span('File'),
          input({
            part: 'fileInput',
            type: 'file',
            onChange: this.setPath,
            class: 'elastic',
          })
        ),
        label(
          { class: 'row', style: { alignItems: 'center', padding: 0 } },
          span('Path'),
          input({
            placeholder: 'File Name',
            part: 'filePath',
            class: 'elastic',
          })
        ),
        button(
          { class: 'row', style: { alignSelf: 'center' } },
          span('Upload'),
          icons.upload(),
          {
            onClick: this.uploadFile,
          }
        )
      ),
      xinSizer({ class: 'no-drag' }),
      closeButton({
        target: 'asset-manager',
        class: 'no-drag',
        style: {
          '--close-button-color': 'white',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      })
    )

  connectedCallback() {
    super.connectedCallback()

    this.getFiles()
  }
}

export const assetManager = AssetManager.elementCreator({
  tag: 'asset-manager',
}) as ElementCreator<AssetManager>
