import { elements, css, vars, varDefault, Color, XinStyleSheet } from 'xinjs'
import { icons, svg2DataUrl } from 'xinjs-ui'
const { style } = elements

const brandColor = Color.fromCss('#020296')
const legacyOrange = Color.fromCss('#ff6500')
const primaryColor = legacyOrange.desaturate(0.1).brighten(0.25)
const black = Color.fromCss('#000')
const textColor = Color.fromCss('#2E3438')
const white = Color.fromCss('#fff')
const brandTextColor = white
const primaryTextColor = white

export const readableWidth = '40em'

const cssVars = {
  _font: '"Oxygen", sans-serif',
  _slFontSans: vars.font,
  _codeFont: 'Menlo, Monaco, monospace',
  _fontSize: 16,
  _fontBoldWeight: '400',
  _fontNormalWeight: '300',
  _codeFontSize: 13,
  _maxColumnWidth: '840px',
  _readableWidth: readableWidth,
  _lineHeight: 24,
  _spacing: 16,
  _touchSize: `calc(${vars.lineHeight} + ${vars.spacing} * 1.25)`,
  _textColor: textColor.html,
  _placeholderColor: '#2228',
  _emphasisColor: vars.brandColor,
  _itemSpacing: vars.spacing50,
  _footerBg: '#F2F2F2',
  _background: '#FEFEFE',
  _panelBg: brandColor.opacity(0.05).html, //'#ddd',
  _inputBg: white.html,
  _buttonBg: black.opacity(0.1).html,
  _buttonTextColor: vars.textColor,
  _hoverBg: brandColor.opacity(0.25).html,
  _activeBg: brandColor.opacity(0.5).html,
  _selectedBg: '#ddf',
  _borderColor: '#0004',
  _darkBorderColor: '#0002',
  _lightBorderColor: '#fff4',
  _inputBorder: '1px solid var(--border-color)',
  _lightBorder: '1px solid var(--light-border-color)',
  _darkBorder: '1px solid var(--dark-border-color)',
  _brandColor: brandColor.html,
  _linkColor: legacyOrange.html,
  _primaryColor: primaryColor.html,
  _bubbleColor: legacyOrange.html,
  _bubbleTextColor: '#eee',
  _brandTextColor: brandTextColor.html,
  _primaryTextColor: primaryTextColor.html,
  _roundedRadius: vars.spacing50,
  _borderShadow:
    'inset 0 0 0 2px transparent, inset 0 0 0 1px var(--dark-border-color)',
  _inputBorderShadow: 'inset 0 0 0 1px var(--border-color)',
  _zOffset: vars.spacing25,
  _outlineShadow: 'inset 0 0 0 1px var(--border-color)',
  _zShadow: `0 ${vars.zOffset} ${vars.spacing} ${black.opacity(0.125).html}`,
  _hoverShadow: `inset 0 0 2px 2px ${brandColor.opacity(0.5).html}`,
  _activeShadow: 'inset 0 0 2px 2px var(--brand-color)',
  _invalidShadow: 'inset 0 0 0 2px red',
  _focusShadow: 'inset 0 0 0 2px var(--primary-color)',
  _toolbarHeight: `calc(${vars.lineHeight} + ${vars.spacing})`,
  _headerHeight: '66px',
  _xinTabsSelectedColor: vars.brandColor,
  _xinTabsBarColor: vars.darkBorderColor,
  _vh: '100vh',
  _sidebarWidth: '15em',
  _complaintButtonColor: legacyOrange.html,
  _iconCheck: svg2DataUrl(icons.check(), 'green'),
  _iconCross: svg2DataUrl(icons.x(), 'red'),
  _iconPlus: svg2DataUrl(icons.plus(), legacyOrange.html),
  _iconLink: svg2DataUrl(icons.chevronRight(), legacyOrange.html),
}

const codeVars = {
  _codeColor: '#eee',
  _codeBg: '#333',
}

const rules: XinStyleSheet = {
  '@import': `https://fonts.googleapis.com/css2?family=Exo+2:wght@600&family=Oxygen:wght@300;400;700&display=swap`,
  ':root': {
    ...cssVars,
    ...codeVars,
  },
  body: {
    boxSizing: 'border-box',
    fontFamily: vars.font,
    background: vars.footerBg,
    color: vars.textColor,
    margin: 0,
    fontSize: vars.fontSize,
    lineHeight: vars.lineHeight,
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent',
    fontWeight: vars.fontNormalWeight,
    opacity: 1,
  },
  '@media screen and (min-width: 900px)': {
    '.hide-if-wide': {
      display: 'none',
    },
  },
  '@media screen and (max-width: 600px)': {
    ':root': {
      _spacing: 12,
    },
    'body h1': {
      fontSize: vars.fontSize150,
      lineHeight: vars.lineHeight125,
    },
    'body h2': {
      fontSize: vars.fontSize125,
      lineHeight: vars.lineHeight110,
    },
  },
  '.show-if-wide': {
    display: 'flex',
    alignItems: 'center',
    gap: vars.spacing50,
  },
  '@media screen and (max-width: 899px)': {
    '.show-if-wide': {
      display: 'none !important',
    },
  },
  '*': {
    boxSizing: 'border-box',
  },
  '.brand-font': {
    fontFamily: '"Exo 2", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: '600',
    fontStyle: 'normal !important',
  },
  'h1, h2, h3': {
    margin: `${vars.spacing200} 0 ${vars.spacing}`,
  },
  'h1, h2, h3, h4, h5, h6, b, strong': {
    fontWeight: vars.fontBoldWeight,
    color: vars.emphasisColor,
  },
  'h4, h5, h6': {
    margin: 0,
  },
  h1: {
    fontSize: vars.fontSize180,
    lineHeight: vars.lineHeight150,
  },
  h2: {
    fontSize: vars.fontSize140,
    lineHeight: vars.lineHeight130,
  },
  'p, pre, blockquote, .p, li, th, td': {
    color: vars.textColor,
    fontSize: vars.fontSize,
    lineHeight: vars.lineHeight,
    margin: 0,
  },
  'p:not(:last-child), pre:not(:last-child), blockquote:not(:last-child), .p:not(:last-child)':
    {
      margin: `0 0 ${vars.spacing}`,
    },
  blockquote: {
    background: vars.panelBg,
    padding: `${vars.spacing50} ${vars.spacing}`,
    borderRadius: vars.roundedRadius50,
  },
  'pre, blockquote': {
    fontSize: vars.fontSize80,
  },
  'ul, ol': {
    margin: `0 ${vars.spacing200} ${vars.spacing} 0`,
  },
  li: {
    margin: `0 0 ${vars.spacing25}`,
  },
  'labeled-input, labeled-value': {
    display: 'block',
  },
  'label, input, button, textarea, .button, select, [contenteditable]': {
    fontFamily: vars.font,
    fontWeight: vars.fontBoldWeight,
    display: 'inline-block',
    fontSize: vars.fontSize,
    padding: `${vars.spacing75} ${vars.spacing125}`,
    lineHeight: vars.lineHeight,
    color: vars.textColor,
    border: '0 !important',
    minWidth: vars.touchSize,
  },
  'input, button, textarea, .button, select, [contenteditable], xin-segmented::part(options)':
    {
      boxShadow: vars.borderShadow,
    },
  'button :not(input), .button :not(input)': {
    pointerEvents: 'none',
  },
  '.button': {
    textAlign: 'center',
  },
  'header, footer, .toolbar': {
    '--border-shadow': 'none',
    // '--input-border-shadow': 'none',
    boxShadow: 'none',
  },
  'header, footer': {
    '--input-bg': 'transparent',
    '--button-bg': 'transparent',
  },
  'header *': {
    transition: '0.25s linear',
  },
  'header a.button': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  [`@media (max-width: ${readableWidth})`]: {
    'header a.button:has(+*+:focus-within) b': {
      display: 'none',
    },
    'header a.button:has(+*+:focus-within) .badge': {
      animation: 'none',
    },
    'header find-business:not(:focus-within)': {
      maxWidth: '96px',
    },
  },
  '.toolbar': {
    flexWrap: 'wrap',
    '--input-bg': black.opacity(0.1).html,
    background: black.opacity(0.1).html,
  },
  '.orange.orange': {
    background: vars.complaintButtonColor,
    color: vars.textColor,
    '--text-color': vars.primaryTextColor,
    '--placeholder-color': white.opacity(0.5).html,
    '--button-text-color': 'white',
  },
  '.complaint.complaint, .primary.primary': {
    // background: vars.complaintButtonColor,
    background: `linear-gradient(0deg, rgb(249,75,0) 0%, rgb(249,127,0) 100%)`,
    color: vars.textColor,
    '--text-color': vars.primaryTextColor,
    '--button-text-color': vars.primaryTextColor,
    '--placeholder-color': white.opacity(0.5).html,
  },
  '.warning.warning': {
    background: 'orange',
    color: 'white',
  },
  '.danger.danger': {
    background: 'red',
    color: 'white',
  },
  '.primary.primary:not(.toolbar):not(header):not(footer)': {
    '--input-bg': black.opacity(0.15).html,
    '--button-bg': black.opacity(0.25).html,
  },
  '.default': {
    '--button-bg': brandColor.html,
    '--button-text-color': brandTextColor.html,
  },
  'button, .button, select': {
    borderRadius: vars.roundedRadius,
    background: vars.buttonBg,
    color: vars.buttonTextColor,
    lineHeight: vars.lineHeight,
  },
  '.no-padding': {
    padding: '0 !important',
  },
  '.no-margin': {
    margin: '0 !important',
  },
  table: {
    borderCollapse: 'collapse',
    borderBottom: vars.darkBorder,
  },
  a: {
    '--text-color': vars.linkColor,
    color: vars.textColor,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    maxWidth: 300,
    position: 'relative',
    verticalAlign: 'bottom',
  },
  'a:not(.unadorned)': {
    paddingRight: vars.fontSize,
  },
  'a > *': {
    display: 'inline',
  },
  'a:not(.button):not(.unadorned):not(.xin-menu-item)::after': {
    content: vars.iconLink,
    display: 'block',
    width: vars.fontSize,
    height: vars.fontSize,
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%) translateY(-1px)',
  },
  'a:not(:disabled):not(:focus-visible):hover': {
    boxShadow: `0 1px 0 ${vars.textColor}`,
  },
  '.current-route, .current': {
    background: vars.inputBg,
    zIndex: 1,
    pointerEvents: 'none',
  },
  '.current': {
    color: vars.primaryColor,
  },
  th: {
    background: brandColor.opacity(0.2).html,
  },
  'td, th, .td, .th': {
    padding: `${vars.spacing25} ${vars.spacing75}`,
  },
  'tr, .tr': {
    borderBottom: vars.darkBorder,
  },
  'tr:hover, .tr:hover': {
    background: brandColor.opacity(0.1).html,
  },
  'tr[aria-selected], .tr[aria-selected], [aria-selected]': {
    background: primaryColor.opacity(0.5).html,
  },
  'input, textarea, [contenteditable]': {
    background: vars.inputBg,
    boxShadow: vars.inputBorderShadow,
    borderRadius: vars.roundedRadius50,
  },
  '[contenteditable]': {
    whiteSpace: 'pre-wrap',
  },
  ':focus-visible': {
    boxShadow: vars.focusShadow,
    outline: 'none',
  },
  select: {
    borderRadius: vars.roundedRadius,
    '-webkit-appearance': 'none',
  },
  'select:has(+ .icon-chevron-down)': {
    paddingRight: 48,
  },
  'select + .icon-chevron-down': {
    fill: vars.buttonTextColor,
    pointerEvents: 'none',
    marginLeft: -24,
    marginRight: 10,
  },
  'input[type="search"]': {
    borderRadius: '99px',
    paddingLeft: vars.spacing150,
  },
  ':disabled': {
    opacity: '0.5 !important',
  },
  [`button:not(:disabled):not(:focus-visible):hover,
  .button:not(:disabled):not(:focus-visible):hover,
  select:not(:disabled):not(:focus-visible):hover,
  .selectable:not(:disabled):not(:focus-visible):hover,
  input:not(:disabled):not(:focus-visible):hover,
  [contenteditable]:not(:focus-visible):hover,
  textarea:not(:disabled):not(:focus-visible):hover`]: {
    boxShadow: vars.hoverShadow,
  },
  // select:not(:disabled):active removed because it doesn't render as expected
  'button:not(:disabled):active, .button:not(:disabled):active, a:not(:disabled):active, .selectable:not(:disabled):active':
    {
      boxShadow: `${vars.activeShadow} !important`,
      _emphasisColor: vars.brandTextColor,
      _buttonTextColor: vars.brandTextColor,
      background: vars.brandColor,
    },
  'a:not(.xin-menu-item):visited': {
    opacity: 0.8,
  },
  '.xin-menu a': {
    fontWeight: '400',
  },
  '[hidden]': {
    display: 'none !important',
  },
  'code, pre, .code': {
    fontFamily: vars.codeFont,
    fontSize: vars.codeFontSize,
  },
  img: {
    objectFit: 'cover',
    maxWidth: '100%',
  },
  '.icon-button': {
    fontSize: vars.fontSize200,
    background: 'transparent',
    lineHeight: vars.lineHeight200,
    borderRadius: 1000,
    padding: 0,
    minWidth: vars.lineHeight200,
  },
  'pre, .code': {
    background: vars.codeBg,
    color: vars.codeColor,
    borderRadius: vars.roundedRadius50,
    padding: vars.spacing,
  },
  '#root': {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    padding: `${vars.spacing50} ${vars.spacing}`,
    '--focus-shadow': `inset 0 0 0 2px ${vars.brandTextColor}`,
    background: vars.footerBg,
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    justifyContent: 'center',
    transition: 'ease-in 0.5s 0.25s',
  },
  '.blur-content *': {
    filter: 'blur(4px)',
    opacity: 0.75,
    transition: 'opacity 0.25s ease-in-out',
  },
  main: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: vars.background,
    overflowX: 'hidden',
    minHeight: `calc(${vars.vh} - ${vars.toolbarHeight} - ${vars.spacing})`,
  },
  'main > :first-child': {
    paddingTop: `${vars.headerHeight} !important`,
  },
  'main > *, footer > *': {
    width: '100%',
  },
  'main.full-width': {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    height: vars.vh,
  },
  'main:not(.full-width) > *': {
    maxWidth: vars.maxColumnWidth,
    padding: `0 ${vars.spacing}`,
  },
  'crud-tool': {
    height: `calc(${vars.vh} - ${vars.toolbarHeight} - ${vars.spacing})`,
    position: 'relative',
  },
  '.elastic': {
    flex: '1 1 auto',
  },
  '.spacer': {
    display: 'inline-block',
    width: vars.spacing50,
    flex: `0 0 ${vars.spacing50}`,
  },
  '.row, .row-if-wide': {
    display: 'flex',
    flexDirection: 'row',
    gap: vars.spacing50,
    justifyContent: 'center',
  },
  '.row-if-wide > *': {
    flex: '1 1 0px',
  },
  '@media screen and (max-width: 840px)': {
    '.row-if-wide': {
      flexDirection: 'column !important',
      alignItems: 'center',
      maxWidth: vars.widthIfColumn,
    },
  },
  '.column': {
    display: 'flex',
    flexDirection: 'column',
    gap: vars.spacing25,
    alignItems: 'center',
  },
  '.avatar': {
    display: 'inline-block',
    width: vars.lineHeight150,
    height: vars.lineHeight150,
    lineHeight: vars.lineHeight150,
    borderRadius: vars.lineHeight75,
    verticalAlign: 'middle',
  },
  '.center-justified': {
    textAlign: 'center',
  },
  '.center-content': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.centered': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '.ph': {
    width: '25vmin',
    height: '25vin',
    transformOrigin: '40% 100%',
    animation: 'no-no-no 0.4s ease-in-out infinite alternate',
    opacity: 0.5,
  },
  '.compact': {
    '--spacing': '8px',
    '--font-size': vars.fontSize75,
    '--line-height': vars.lineHeight75,
    lineHeight: vars.lineHeight,
  },
  '@keyframes no-no-no': {
    '0%': {
      transform: 'translate(-50%, -60%) rotateZ(-15deg)',
    },
    '100%': {
      transform: 'translate(-50%, -60%) rotateZ(15deg)',
    },
  },
  '@keyframes popin': {
    '0%': {
      transform: `translateY(${vars.spacing}) scale(0.9)`,
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px) scale(1)',
      opacity: 1,
    },
  },
  '.badge, .badge-focus': {
    height: 24,
    width: 18,
  },
  '.badge, :focus > .badge-focus, :hover > .badge-focus': {
    transformOrigin: '40% 100%',
    animation: 'no-no-no-badge 1s ease-in-out infinite alternate',
  },
  '@keyframes no-no-no-badge': {
    '0%': {
      transform: 'rotateZ(-10deg)',
    },
    '100%': {
      transform: 'rotateZ(10deg)',
    },
  },
  '.spin-clockwise': {
    animation: 'spin-clockwise 0.5s linear infinite',
  },
  '@keyframes spin-clockwise': {
    '0%': {
      transform: 'rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateZ(45deg)',
    },
  },
  '.whole-page': {
    maxWidth: 'none',
  },
  header: {
    backdropFilter: 'blur(4px)',
    '-webkitBackdropFilter': 'blur(4px)',
    background: white.opacity(0.75).html,
    position: 'fixed',
  },
  '#root > header': {
    height: vars.headerHeight,
  },
  'header, .toolbar': {
    display: 'flex',
    left: 0,
    right: 0,
    gap: vars.spacing25,
    alignItems: 'center',
    padding: `0 ${vars.spacing25}`,
  },
  'header label, .toolbar label': {
    display: 'flex',
    gap: vars.spacing50,
  },
  'header button, header .button, header label, .toolbar select, .toolbar button, .toolbar .button, .toolbar label, .toolbar > input':
    {
      margin: `${vars.spacing_13} 0`,
    },
  'header button, header .button': {
    color: vars.brandColor,
  },
  '.selectable': {
    padding: `${vars.spacing25} ${vars.spacing}`,
    borderRadius: vars.roundedRadius50,
    cursor: 'default',
  },
  '[data-selected]': {
    color: vars.inputBg,
    background: vars.brandColor,
  },
  '[data-deleted]': {
    display: 'none',
  },
  '.show-deleted [data-deleted]': {
    textDecoration: 'line-through',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  '.transparent': {
    background: 'transparent',
  },
  '.no-outline': {
    boxShadow: 'none !important',
    border: 'none !important',
  },
  '.round': {
    borderRadius: '999px',
    padding: 0,
    textAlign: 'center',
    height: vars.toolbarHeight,
    width: vars.toolbarHeight,
    lineHeight: vars.toolbarHeight,
  },
  '.padded': {
    padding: vars.spacing,
  },
  '.text-nowrap': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  /* forms */
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    gap: vars.spacing50,
    position: 'relative',
    minWidth: '300px',
    minHeight: '150px',
  },
  '.row-reverse': {
    flexFlow: 'row-reverse',
  },
  'form label': {
    display: 'flex',
    flexDirection: 'column',
    gap: vars.spacing50,
    padding: `${vars.spacing50} 0`,
  },
  'form input, :host textarea, [contenteditable]': {
    padding: `${vars.spacing50} ${vars.spacing75}`,
    border: 'none',
    borderRadius: vars.roundedRadius50,
    boxShadow: vars.inputBorderShadow,
  },
  'input[type="checkbox"]': {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  'form input[type="checkbox"]': {
    boxShadow: 'none',
    marginRight: vars.spacing,
  },
  '::placeholder': {
    color: vars.placeholderColor,
  },
  'form label > div': {
    display: 'flex',
    gap: vars.spacing50,
  },
  textarea: {
    resize: 'vertical',
  },
  'form textarea, form div[contenteditable]': {
    minHeight: vars.lineHeight800,
  },
  '[data-caption], [data-title]': {
    position: 'relative',
  },
  '[data-caption]::after': {
    content: 'attr(data-caption)',
    display: 'block',
    position: 'absolute',
    textAlign: 'center',
    left: 0,
    right: 0,
    top: vars.spacing50,
    fontSize: '75%',
  },
  '[data-title]::before': {
    content: 'attr(data-title)',
    display: 'block',
    position: 'absolute',
    padding: `0 ${vars.spacing}`,
    top: vars.lineHeight_50,
    left: '50%',
    transform: 'translateX(-50%)',
    background: vars.background,
    fontWeight: 'bold',
    color: vars.brandColor,
  },
  '.framed': {
    borderRadius: vars.roundedRadius,
    padding: `${vars.spacing} ${vars.spacing150}`,
    boxShadow: vars.borderShadow,
  },
  '::-webkit-scrollbar': {
    width: vars.spacing50,
    background: '#fdfdfe',
  },
  '::-webkit-scrollbar-thumb': {
    background: brandColor.opacity(0.1).html,
    borderRadius: vars.spacing25,
  },
  // dialogs
  dialog: {
    border: 0,
    background: vars.background,
    padding: vars.spacing200,
    borderRadius: vars.roundedRadius200,
    boxShadow: vars.zShadow,
    animation: 'popin 0.25s ease-out',
  },
  'dialog > .row-if-wide': {
    alignItems: 'stretch',
  },
  ':modal::backdrop': {
    background: black.opacity(0.25).html,
    backdropFilter: 'blur(2px)',
  },
  // toasts
  '.sl-toast-stack': {
    top: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: vars.spacing200,
  },
  // details
  details: {
    position: 'relative',
  },
  'details .disclose': {
    display: 'block',
    color: vars.brandColor,
    alignSelf: 'center',
    transition: '0.25s ease-out',
  },
  'details[open] .disclose': {
    transform: 'rotateZ(90deg)',
  },
  summary: {
    display: 'block',
  },
  'summary::-webkit-details-marker': {
    display: 'none',
  },
  '.outlined': {
    boxShadow: vars.outlineShadow,
    borderRadius: vars.roundedRadius50,
  },
  // misc
  'li::marker': {
    color: vars.emphasisColor,
  },
  '.hr': {
    border: 0,
    content: ' attr(data-label) ',
    height: '1px',
    margin: `${vars.spacing} 0`,
    width: '100%',
    background: brandColor.opacity(0.5).html,
  },
  // business-profile
  '.unpad-first-element > :nth-child(1)': {
    marginTop: 0,
  },
  'li a': {
    display: 'inline',
  },
  '[data-bullet]': {
    display: 'flex',
  },
  '[data-bullet]::before': {
    content: 'attr(data-bullet)',
    background: vars.brandColor,
    color: vars.brandTextColor,
    fontWeight: 'bold',
    flex: '0 0 1.5em',
    height: '1.5em',
    lineHeight: '1.5em',
    borderRadius: '0.75em',
    textAlign: 'center',
    marginRight: '0.5em',
  },
  '[data-label]': {
    position: 'relative',
  },
  '[data-label]::after': {
    content: 'attr(data-label)',
    position: 'absolute',
    color: vars.brandColor,
    lineHeight: vars.spacing,
    top: vars.spacing_50,
    left: '50%',
    transform: 'translateX(-50%)',
    background: vars.background,
    padding: `0 ${vars.spacing}`,
  },
  // animations
  '.appearance': {
    animation: '0.5s ease-out appear',
  },
  '@keyframes appear': {
    '0%': {
      opacity: 0,
      filter: 'blur(2px)',
      transform: 'scale(0.8)',
    },
    '25%': {
      opacity: 0,
      filter: 'blur(2px)',
      transform: 'scale(0.8)',
    },
    '100%': {
      opacity: 1,
      filter: 'blur(0px)',
      transform: 'scale(1)',
    },
  },
  '.spin': {
    animation: '2s linear infinite spin',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateZ(360deg)',
    },
  },
  // concealing personal info
  '.withheld': {
    display: 'inline-block',
    padding: `0 ${vars.spacing50}`,
    margin: `0 ${vars.spacing25}`,
    filter: 'blur(4px)',
  },
  '.withheld::after': {
    content: '" withheld"',
  },
  // firebase emulator
  '.firebase-emulator-warning': {
    opacity: 0.25,
    pointerEvents: 'none',
  },
  // radio icons
  '.selectable *': {
    pointerEvents: 'none',
  },
  '.selectable:has(svg)': {
    display: 'flex',
    background: vars.panelBg,
    alignItems: 'center',
    gap: vars.spacing50,
  },
  '.selectable svg': {
    opacity: 0.75,
  },
  'input:checked + .selectable': {
    outline: `2px solid ${vars.brandColor}`,
    color: vars.brandColor,
    _textColor: vars.brandColor,
  },
  'input:checked + .selectable svg': {
    opacity: 1,
  },
  '.hidden-input': {
    opacity: 0,
    padding: 0,
    margin: -1,
    width: 2,
    minWidth: 2,
  },
  // popMenu
  'button.menu-trigger': {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: vars.touchSize,
    height: vars.touchSize,
  },
  '.xin-menu svg[class*="icon-"]': {
    fill: vars.primaryColor,
  },
  '@media (max-width: 30em)': {
    '.xin-float:has(.xin-menu)': {
      left: `${vars.spacing50} !important`,
      right: `${vars.spacing50} !important`,
    },
  },
  '.xin-menu-item:active': {
    '--menu-item-color': vars.brandTextColor,
  },
  // data-table
  '.th, .td': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: `0 ${vars.spacing}`,
    borderRight: vars.darkBorder,
  },
  '.thead': {
    background: vars.brandColor,
    color: vars.brandTextColor,
  },
  // progress
  'progress[title]': {
    position: 'relative',
  },
  'progress[title]::after': {
    content: 'attr(title)',
    display: 'block',
    position: 'absolute',
    top: vars.spacing50,
    left: '50%',
    fontSize: '12px',
    color: vars.brandColor,
    transform: 'translateX(-50%)',
  },
  // filter-builder
  'filter-builder > input': {
    borderRadius: '99px',
  },
  // multi-select
  'select[multiple]': {
    padding: 0,
  },
  'select[multiple] > option': {
    padding: `${vars.spacing50} ${vars.spacing}`,
  },
  // pages
  'main > md-viewer': {
    marginBottom: vars.spacing200,
  },
  ':not([hidden]) + .show-after-hidden': {
    display: 'none',
  },
  'form-viewer': {
    flex: '1 1 auto',
    position: 'relative',
    marginBottom: vars.spacing,
  },
  'stripe-payment, stripe-payment iframe': {
    minHeight: '220px',
  },
  'svg[class*="icon-"]': {
    // FIXME figure out why this is being corrupted
    display: 'inline-block',
    height: varDefault.fontSize('16px'),
    width: varDefault.fontSize('16px'),
    verticalAlign: 'middle',
    fill: vars.textColor,
    pointerEvents: 'none',
  },
  'button svg[class*="icon-"], .button svg[class*="icon-"]': {
    fill: vars.buttonTextColor,
  },
  '#root xin-filter': {
    width: 'auto',
    gridTemplateColumns: '44px auto 44px',
  },
  'xin-filter-part': {
    alignItems: 'center',
  },
  'button.filter': {
    borderRadius: vars.spacing75,
    opacity: 0.75,
  },
  'button.filter.active': {
    opacity: 1,
  },
  'xin-filter-part select': {
    paddingRight: 32,
  },
  'xin-filter-part select, xin-filter-part button, xin-filter-part input': {
    height: 42,
    lineHeight: 42,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  'xin-filter-part [part="condition"]': {
    width: 120,
  },
  // tag-list

  'xin-tag [part="remove"]': {
    minWidth: 'auto',
  },
  'xin-tag-list': {
    display: 'block',
  },
  'xin-tag': {
    '--text-color': vars.brandTextColor,
  },
  // xin-float
  'xin-float': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    boxShadow: vars.zShadow,
    borderRadius: vars.roundedRadius,
    overflow: 'hidden',
    background: vars.background,
  },
  'button.close-float': {
    background: 'transparent',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '32px',
    padding: 0,
    lineHeight: '32px',
    boxShadow: 'none',
  },
  // message bubbles
  '.bubble': {
    display: 'inline-block',
    background: vars.bubbleColor,
    color: vars.bubbleTextColor,
    padding: `${vars.spacing10} ${vars.spacing50}`,
    margin: vars.spacing25,
    lineHeight: `calc(45px - ${vars.spacing})`,
    maxWidth: `calc(100% - ${vars.spacing400})`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: vars.spacing50,
  },
  '.bubble[data-type^="to"]': {
    borderBottomRightRadius: 0,
    position: 'absolute',
    right: vars.spacing,
    '--bubble-color': brandColor.brighten(0.45).html,
    '--bubble-text-color': vars.brandTextColor,
  },
  '.bubble[data-type="to-internal"]': {
    '--bubble-color': '#ffa',
    '--bubble-text-color': '#777',
  },
  '.bubble[data-type="to-business"]': {
    '--bubble-color': brandColor.brighten(0.15).html,
  },
  '.bubble[data-type="to-customer"]': {
    '--bubble-color': brandColor.brighten(0.3).html,
  },
  '.bubble[data-type^="from"]': {
    borderBottomLeftRadius: 0,
  },
  '.bubble[data-type="from-customer"]': {
    '--bubble-color': legacyOrange.html,
  },
  '.bubble[data-type="from-business"]': {
    '--bubble-color': legacyOrange.darken(0.3).html,
  },
  '.bubble[data-type="unattached"]': {
    '--bubble-color': '#666',
    borderBottomLeftRadius: 0,
  },
  // tooltips
  '.tooltip': {
    padding: `0 ${vars.spacing50}`,
    borderRadius: vars.spacing25,
    background: '#000a',
    marginTop: vars.spacing50,
    color: 'white',
    boxShadow: vars.zShadow,
    animation: 'fade-in ease-out 0.5s',
  },
  '@keyframes fade-in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  '.readable-width': {
    maxWidth: vars.readableWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  // bullets
  '.good-points ul, .bad-points ul, .plus-points ul': {
    listStyle: 'none',
  },
  '.good-points li, .bad-points li, .plus-points li': {
    position: 'relative',
    marginLeft: `0 0 0 ${vars.fontSize200}`,
  },

  '.good-points li::before, .bad-points li::before, .plus-points li::before': {
    position: 'absolute',
    display: 'block',
    left: vars.fontSize_200,
    width: vars.fontSize150,
    height: vars.fontSize150,
  },

  '.good-points li::before': {
    content: vars.iconCheck,
  },

  '.bad-points li::before': {
    content: vars.iconCross,
  },

  '.plus-points li::before': {
    content: vars.iconPlus,
    width: vars.fontSize125,
    height: vars.fontSize125,
  },
  // semantic html
  'section + section': {
    marginTop: vars.spacing,
  },
  // footer
  'footer table, footer tr, footer td, footer th': {
    background: 'transparent',
    border: 0,
  },
  'footer h4': {
    color: vars.brandColor,
  },
  // carousel
  'xin-carousel::part(back), xin-carousel::part(forward)': {
    top: '100%',
    height: 48,
    width: 48,
    background: vars.inputBg,
    borderRadius: vars.spacing25,
  },
  'xin-carousel::part(back)': {
    left: `calc(100% - 98px)`,
  },
  'xin-carousel::part(progress)': {
    height: 24,
    alignItems: 'center',
  },
  'xin-carousel': {
    marginBottom: vars.spacing,
  },
}

document.head.append(style({ id: 'base-style' }, css(rules)))

// adapted from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const setTrueHeight = (): void => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
}
setTrueHeight()
window.addEventListener('resize', setTrueHeight)
