import { service } from './firebase'
import { error } from './notifications'
import * as prompts from './mocks/prompts'

export async function gpt(promptId: string, source: string): Promise<string> {
  const prompt = (prompts as { [key: string]: string })[promptId]
  if (prompt === undefined) {
    const errorMessage = `prompt ${promptId} does not exist…`
    error(errorMessage)
    throw new Error(errorMessage)
  }
  try {
    const response = await service.askgpt.post({
      prompt: `${prompt}\n\n${source as string}`,
    })
    return response.result[0].text
  } catch (err) {
    const errorMessage = `prompt ${promptId} failed hard…`
    error(errorMessage)
    throw new Error(errorMessage)
  }
}
