import { bindings, getListItem, xinValue, xinPath, elements, vars } from 'xinjs'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import { getRecord } from './firebase'
import { stringFallback } from './fallback'
import { icons } from 'xinjs-ui'
import { fullFormatter, niceDate } from './nice-date'

const DEFAULT_AVATAR_URL = '/images/avatar.svg'

bindings.hide = {
  toDOM(element, value: any) {
    const match = element.dataset.hideMatchValue
    if (match === undefined && Array.isArray(value)) {
      value = value.length !== 0
    }
    if (match !== undefined ? value !== match : !value) {
      element.removeAttribute('hidden')
    } else {
      element.setAttribute('hidden', '')
    }
  },
}

bindings.show = {
  toDOM(element, value: any) {
    const hiddenClass = element.dataset.hiddenClass
    const match = element.dataset.showMatchValue
    if (match === undefined && Array.isArray(value)) {
      value = value.length !== 0
    }
    if (match !== undefined ? value === match : !!value) {
      if (hiddenClass !== undefined) {
        element.classList.remove(hiddenClass)
      } else {
        element.removeAttribute('hidden')
      }
    } else {
      if (hiddenClass !== undefined) {
        element.classList.add(hiddenClass)
      } else {
        element.setAttribute('hidden', '')
      }
    }
  },
}

bindings.fullWidth = {
  toDOM(element, value: any) {
    element.classList.toggle('full-width', value === true)
  },
}

bindings.imageSrc = {
  toDOM(element, value: any) {
    if (element instanceof HTMLImageElement) {
      if (typeof value === 'string') {
        element.src = value
      } else {
        element.src = DEFAULT_AVATAR_URL
      }
    }
  },
}

bindings.title = {
  toDOM(element, value: any) {
    element.title = value
  },
}

bindings.selected = {
  toDOM(element, value: any) {
    if (value === true) {
      element.setAttribute('data-selected', '')
    } else {
      element.removeAttribute('data-selected')
    }
  },
}

bindings.current = {
  toDOM(element, value: any) {
    if (
      Array.isArray(value)
        ? value.includes(getListItem(element))
        : getListItem(element) === xinValue(value)
    ) {
      element.setAttribute('data-selected', '')
    } else {
      element.removeAttribute('data-selected')
    }
  },
}

bindings.html = {
  toDOM(element, value: any) {
    if (typeof value === 'string') {
      element.innerHTML = value.replace(/-xin-data/g, '')
    }
  },
}

// case bindings

bindings.case = {
  toDOM(a, caseId: string) {
    a.setAttribute('href', `/inbox?c=${caseId}/`)
  },
}

bindings.caselink = {
  toDOM(element, value) {
    console.warn('remove caseLink binding!')
    element.setAttribute('href', `/case/${value}/`)
  },
}

bindings.reviewlink = {
  toDOM(a, caseData?: any) {
    a.setAttribute(
      'href',
      caseData != null
        ? `/case/${caseData._id}?r=${caseData.reviewLink[0].token}`
        : ''
    )
  },
}

bindings.strings = {
  toDOM(element, value) {
    if (Array.isArray(value)) {
      element.textContent =
        Array.isArray(value) && typeof value[0] === 'string'
          ? value.join(', ')
          : ''
    } else if (typeof value === 'string') {
      element.textContent = value
        .split(',')
        .filter((s) => s.trim() !== '')
        .join(', ')
    }
  },
}

bindings.markdown = {
  toDOM(element, value) {
    if (value instanceof HTMLElement) {
      element.textContent = ''
      element.append(xinValue(value))
    } else if (typeof value === 'string' && value !== '') {
      // some responses from chatGPT use bullet characters which is endearing
      if (element.dataset.markdownOptions?.includes('replace-bullets')) {
        value = value.replace(/• /g, '- ')
      }
      if (element.dataset.markdownOptions?.includes('no-newlines') !== true) {
        value = value
          .replace(/\n+/g, '\n\n')
          .replace(/\n\n(-\s|\*\s|\d+\.\s)/g, '\n$1')
      }
      element.innerHTML = DOMPurify.sanitize(
        marked(value, { mangle: false, headerIds: false })
      )
    } else {
      element.textContent = '( render error )'
    }
  },
}

const NAV_KEYS = /^(Arrow|Alt|Meta|Control|Tab|Shift)/

const fauxChange = (event: KeyboardEvent): void => {
  if (NAV_KEYS.test(event.code)) {
    return
  }
  event.target?.dispatchEvent(new Event('input'))
}

bindings.editable = {
  toDOM(element: HTMLElement, text: string | null | undefined) {
    if (typeof text !== 'string') {
      text = ''
    }
    text = text.replace(/-xin-data/g, '')
    element.addEventListener('keyup', fauxChange)
    if (element.hasAttribute('data-styled')) {
      if (element.innerHTML !== text) {
        element.innerHTML = text
      }
    } else {
      if (element.innerText !== text) {
        element.innerText = text
      }
    }
  },

  fromDOM(element: HTMLElement): any {
    if (element.hasAttribute('data-styled')) {
      return element.innerHTML
    } else {
      return element.innerText
    }
  },
}

// brand bindings / business bindings

bindings.businessLink = {
  toDOM(element, path: string) {
    element.setAttribute('href', `/${path}-customer-service/`)
  },
}

bindings.businessPath = {
  toDOM(element, path: string) {
    element.setAttribute('path', path)
  },
}

bindings.user = {
  toDOM(element, userId: string) {
    if (userId === undefined || userId === 'unassigned') {
      element.textContent = 'unassigned'
    } else {
      element.textContent = userId
      getRecord('privileges', userId)
        .then((priv) => {
          element.textContent = stringFallback(
            priv?.description as string,
            userId
          )
        })
        .catch(() => {})
    }
  },
}

bindings.dataTable = {
  toDOM(elt: any, value: any[]) {
    const { columns } = elt.value
    elt.value = {
      ...elt.value,
      array: value,
      columns: Array.isArray(columns) ? columns : null,
    }
  },
}

bindings.dataTableColumns = {
  toDOM(elt: any, value: any[]) {
    elt.value = {
      ...elt.value,
      columns: value,
    }
  },
}

bindings.filterFields = {
  toDOM(elt: any, value?: any[]) {
    elt.fields = value || []
    elt.reset()
  },
}

bindings.dataTableFilter = {
  toDOM(elt: any, value: (array: any[]) => any[]) {
    elt.value = {
      ...elt.value,
      filter: value,
    }
  },
}

bindings.date = {
  toDOM(elt: any, value?: string | { timestamp: string }) {
    // firebase timestamps are objects
    if (typeof value === 'object') {
      value = value.timestamp
    }
    if (value === undefined) {
      elt.textContent = ''
      elt.dataset.tooltip = 'bad date'
      return
    }
    const date = new Date(value as string)
    elt.dataset.tooltip = fullFormatter.format(date)
    elt.textContent = niceDate(date)
  },
}

function extractEmail(a?: string): string {
  return (
    a === undefined
      ? ''
      : a.split('<').length === 2
      ? a.split('<')[1].split('>')[0]
      : a
  ).toLocaleLowerCase()
}

function isSameEmail(a: string, b: string): boolean {
  return extractEmail(a) === extractEmail(b)
}

const textDiv = elements.div()
function messageText(message: any): string {
  let summary = ''
  if (
    message.subject &&
    !message.subject.toLocaleLowerCase().startsWith('re:')
  ) {
    summary = message.subject
  }
  if (message.text) {
    summary += ' ' + message.text
  } else if (message.html) {
    textDiv.innerHTML = message.html
    summary += ' ' + textDiv.textContent
  }
  return summary.trim() || '…'
}

bindings.messageBubble = {
  toDOM(elt: HTMLElement, message: any) {
    const { caseData, caseId, phone, from } = message
    let tooltip: string | undefined = from

    if (phone !== undefined) {
      elt.dataset.type = 'from-form'
      if (from === undefined) {
        tooltip = 'via contact form'
      }
    } else if (caseId === undefined) {
      elt.dataset.type = message.from?.match(/@(\w+.)?nonono.com(>)?/)
        ? 'to-unattached'
        : 'unattached'
    } else {
      if (message.from?.match(/@(\w+.)?nonono.com(>)?/)) {
        if (message.to[0] === caseData?.email) {
          elt.dataset.type = 'to-customer'
        } else if (message.to[0].endsWith('nonono.com')) {
          elt.dataset.type = 'to-internal'
        } else {
          elt.dataset.type = 'to-business'
        }
        elt.dataset.from = extractEmail(message.from).split('@')[0]
        tooltip = extractEmail(message.from)
      } else if (caseData !== undefined) {
        if (isSameEmail(message.from, caseData.email)) {
          elt.dataset.type = 'from-customer'
          elt.dataset.from = `${caseData.nameFirst} ${caseData.nameLast}`
          tooltip = extractEmail(caseData.email)
        } else {
          elt.dataset.type = 'from-business'
          elt.dataset.from = caseData.businessName
        }
      } else {
        elt.dataset.type = 'from'
      }
    }
    elt.textContent = messageText(message)
    elt.dataset.tooltip = tooltip
  },
}

bindings.messageSummary = {
  toDOM(elt: HTMLElement, message: any) {
    const { caseData, caseId } = message
    elt.textContent =
      caseId === undefined
        ? '( unattached )'
        : caseData === undefined
        ? '( case not loaded )'
        : `${caseData.businessName} / ${caseData.title}`
  },
}

bindings.count = {
  toDOM(elt: any, count: any) {
    elt.textContent = ''
    const noun = elt.dataset.noun
    if (noun === undefined) {
      elt.textContent = count
    } else {
      if (count === 1) {
        elt.textContent = `${count} ${noun}`
      } else {
        elt.textContent = `${count} ${noun}s`
      }
    }
  },
}

bindings.aveScore = {
  toDOM(elt: any, business: any) {
    elt.textContent = ''
    if (business?.reviews !== undefined && business.reviews.count > 0) {
      elt.value = business.reviews.totalScore / business.reviews.count
    }
  },
}

bindings.opened = {
  toDOM(elt: any, status: string) {
    elt.textContent = ''
    if (status === 'open') {
      elt.append(icons.check())
    }
  },
}

bindings.valueEmpty = {
  toDOM(elt: any, text: any) {
    if (elt.value !== text) {
      elt.value = text || ''
    }
  },

  fromDOM(elt: any): any {
    return elt.value != null && elt.value !== 'undefined' ? elt.value : ''
  },
}

bindings.price = {
  toDOM(elt: any, amount: string | number | undefined) {
    if (amount === undefined) {
      elt.textContent = ''
    } else {
      const currencyMarker =
        elt.dataset.currency !== undefined ? elt.dataset.currency : '$'
      elt.textContent = currencyMarker + Number(amount).toFixed(2)
    }
  },
}

function bindKeys(
  elt: HTMLElement,
  value: { [key: string]: any },
  objPath: string,
  keys: string[]
) {
  for (const key of keys) {
    const path = `${objPath}.${key}`
    const prop = value[key]
    if (prop === undefined || prop === null) {
      continue
    }
    switch (typeof prop) {
      case 'boolean':
        elt.append(
          elements.label(
            { class: 'row' },
            elements.input({ type: 'checkbox', bindValue: path }),
            elements.span(key)
          )
        )
        break
      case 'string':
        elt.append(
          elements.label(
            elements.span(key),
            elements.input({ bindValue: path })
          )
        )
        break
      case 'number':
        elt.append(
          elements.label(
            elements.span(key),
            elements.input({ type: 'number', step: 0.0001, bindValue: path })
          )
        )
        break
      case 'object':
        elt.append(elements.label(elements.span(key), { bindObject: prop }))
    }
  }
}

const boundRef = Symbol('boundObject')
bindings.object = {
  toDOM(elt: any, value: any) {
    if (elt[boundRef] === value) {
      return
    }
    elt.textContent = ''
    elt[boundRef] = value
    if (
      typeof value !== 'object' ||
      value === null ||
      typeof value === 'function'
    ) {
      elt.textContent = value === null ? 'null' : typeof value
    } else if (Array.isArray(value)) {
      const arrayPath = xinPath(value)
      if (value.length > 0 && arrayPath !== undefined) {
        // create a table
      } else {
        elt.append(elements.span(`array[${value.length}]`))
      }
    } else if (typeof value === 'object') {
      const objPath = xinPath(value)
      const keys = Object.keys(value).sort()
      if (objPath !== undefined) {
        bindKeys(elt, value, objPath, keys)
      } else {
        elt.append(elements.span(`{${keys.join(',')}}`))
      }
    }
  },
}

bindings.messageContent = {
  toDOM(elt: any, message: any) {
    elt.textContent = message.text || message.html || '(no content)'
  },
}

bindings.json = {
  toDOM(elt: any, obj: any) {
    elt.value = obj ? JSON.stringify(obj, undefined, 2) : ''
  },
}

const TAG_SPECS: {
  [key: string]: { color: string; icon?: string } | undefined
} = {
  paid: { color: '#1dbcac' },
  'payment-logged': { color: '#159689', icon: 'check' },
  featured: { color: '#159689', icon: 'star' },
  escalated: { color: '#397e16' },
  resolved: { color: '#24a' },
  reviewed: { color: '#2a4' },
  closed: { color: '#444' },
  respond: { color: '#e47017' },
  urgent: { color: '#a22' },
}

const DEFAULT_TAG_COLOR = '#338'

const { xinTag } = elements
const tagList = (tags?: string[]): HTMLElement[] => {
  return (tags || []).map((tag: string) => {
    const icon = TAG_SPECS[tag]?.icon
    if (icon === undefined) {
      return xinTag(tag, {
        style: { '--tag-bg': TAG_SPECS[tag]?.color || DEFAULT_TAG_COLOR },
      })
    } else {
      return xinTag(
        icons[icon]({
          style: { marginTop: `calc(${vars.lineHeight50} - 5px)` },
        }),
        {
          dataTooltip: tag,
          style: {
            '--tag-bg': TAG_SPECS[tag]?.color || DEFAULT_TAG_COLOR,
          },
        }
      )
    }
  })
}

bindings.tags = {
  toDOM(elt: any, tags?: string[]) {
    elt.textContent = ''
    elt.append(...tagList(tags))
  },
}

const simplifyEmail = (email: any): string => {
  if (Array.isArray(email)) {
    return (email as any[]).map(simplifyEmail).join(', ')
  }
  if (typeof email !== 'string') {
    return ''
  }
  return email.match(/[@.]nonono.com$/) ? email.split('@')[0] : email
}

bindings.email = {
  toDOM(elt: any, email: any) {
    elt.textContent = simplifyEmail(email)
  },
}

bindings.selected = {
  toDOM(elt: any, selected: boolean | undefined) {
    const row = elt.closest('tr,.tr')
    if (row instanceof HTMLElement) {
      elt = row
    }
    if (selected === true) {
      elt.setAttribute('aria-selected', '')
    } else {
      elt.removeAttribute('aria-selected')
    }
  },
}
