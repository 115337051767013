import {
  Component as WebComponent,
  elements,
  vars,
  XinStyleRule,
  getListItem,
} from 'xinjs'
import { collections } from '../collections'
import { setRecord, getRecords } from '../firebase'
import { makeSorter } from '../sort'
import { error } from '../notifications'
import { PIVOT_DATE } from '../mocks/message'
import {
  dataTable,
  icons,
  tabSelector,
  filterBuilder,
  DataTable,
  TabSelector,
} from 'xinjs-ui'

interface UploadWindow extends Window {
  files: string[]
  tables: DataTable[]
  migration: any
}

const { a, h3, div, button, span, input, select, option, progress, label } =
  elements

const selectStyle: XinStyleRule = { flex: '0 0 200px' }

async function migrate(
  doItForReal = false,
  startingDate: Date,
  collectionsToUpdate: string[]
): Promise<void> {
  const [userName, caseName, reviewName] = (window as unknown as UploadWindow)
    .files as string[]
  if (
    reviewName === undefined ||
    !userName.includes('user') ||
    !caseName.includes('case') ||
    !reviewName.includes('comment')
  ) {
    alert('Expecting three files containing user, case, and comment records')
    return
  }

  const [users, cases, reviews] = (
    window as unknown as UploadWindow
  ).tables.map((table) => table.array)

  const migration = {
    customers: [] as any[],
    cases: [] as any[],
    reviews: [] as any[],
    businesses: [] as any[],
    casesWithNoAuthors: [] as any[],
    casesWithNoBusiness: [] as any[],
    reviewsWithNoAuthors: [] as any[],
    reviewsWithNoCases: [] as any[],
  }

  // rebuild customers
  for (const user of users) {
    const filteredCases = cases
      .filter((c: any) => c.author_id === user.id && c.status === 'published')
      .map((c: any) => ({
        caseId: c.id,
        rating: c.score !== null ? c.score : 0,
        wdba: c.wydba,
        title: c.title !== null ? c.title : '',
        paid: c.paid,
        resolved: c.resolved,
      }))

    const customer = {
      nameFirst: user.first_name,
      nameLast: user.last_name,
      email: user.email,
      cases: filteredCases,
      _id: String(user.id),
    }

    migration.customers.push(customer)
  }

  migration.businesses = await getRecords('business-profile', undefined, 9999)
  // rebuild cases
  for (const legacyCase of cases) {
    const legacyUser = users.find((u: any) => u.id === legacyCase.author_id)
    if (legacyUser === undefined) {
      migration.casesWithNoAuthors.push(legacyCase.id)
      continue
    }
    const business = migration.businesses.find(
      (b) => b._id === String(legacyCase.targetcompany_id)
    )
    if (business === undefined) {
      migration.casesWithNoBusiness.push(legacyCase.id)
      continue
    }
    const _created = new Date(legacyCase.date).toISOString()
    const caseData = {
      customerId: String(legacyCase.author_id),
      isClosed:
        legacyCase.paid !== true ||
        legacyCase.resolved === true ||
        legacyCase.status === 'draft',
      isPaid: legacyCase.paid,
      isAwaitingEscalation: false,
      isReviewed: legacyCase.rating !== null,
      title: legacyCase.title,
      isDirectReview: false,
      description: legacyCase.story,
      wouldDoBusiness: false,
      _initialized: true,
      _created,
      _id: String(legacyCase.id),
      email: legacyUser.email,
      assignedUserId: 'unassigned',
      isEscalated: legacyCase.paid,
      history: [],
      nameLast: legacyUser.last_name,
      isResolved: legacyCase.resolved,
      nameFirst: legacyUser.first_name,
      slug: legacyCase.slug,
      businessName: business.name,
      businessId: String(legacyCase.targetcompany_id),
    }

    migration.cases.push(caseData)
  }

  for (const legacyReview of reviews) {
    if (legacyReview.isreview === false) {
      continue
    }
    const user = users.find((u: any) => u.id === legacyReview.user_id)
    if (user === undefined) {
      migration.reviewsWithNoAuthors.push(legacyReview.id)
      continue
    }
    const legacyCase = cases.find((c: any) => c.id === legacyReview.case_id)
    if (legacyCase === undefined) {
      migration.reviewsWithNoCases.push(legacyReview.id)
      continue
    }
    const review = {
      _created: new Date(legacyReview.date).toISOString(),
      _id: String(legacyReview.id),
      nameFirst: user.first_name,
      businessId: String(legacyCase.targetcompany_id),
      caseId: String(legacyCase.id),
      rating: legacyReview.casescore,

      wouldDoBusiness: (legacyCase.wydba as boolean) ? 'yes' : 'no',
      detail: legacyReview.text,
    }

    migration.reviews.push(review)
  }

  const pivotDate = new Date(PIVOT_DATE)
  for (const business of migration.businesses) {
    business.cases = migration.cases
      .filter(
        (caseData) =>
          caseData.businessId === business._id &&
          caseData.isPaid === true &&
          new Date(caseData._created) > pivotDate
      )
      .reduce(
        (
          cases: { caseIds: any[]; count: number; resolved: number },
          caseData
        ) => {
          cases.count += 1
          if (caseData.isResolved === true) {
            cases.resolved += 1
          }
          cases.caseIds.push(caseData._id)
          return cases
        },
        {
          caseIds: [],
          count: 0,
          resolved: 0,
        }
      )

    const reviewSorter = makeSorter((review: any) => [review.date], false)
    business.reviews = migration.reviews
      .filter(
        (review) =>
          review.businessId === business._id &&
          new Date(review._created) > pivotDate
      )
      .reduce(
        (
          reviews: {
            count: number
            totalScore: number
            reviews: any[]
            wouldDoBusiness: number
          },
          review
        ) => {
          if (typeof review.rating === 'number') {
            reviews.count += 1

            reviews.totalScore += Number(review.rating)
            if (review.wouldDoBusiness === 'yes') {
              reviews.wouldDoBusiness += 1
            }
            reviews.reviews.push({
              reviewId: review._id,
              date: review._created,
              rating: review.rating,
              wdba: review.wouldDoBusiness === 'yes',
            })
          }
          return reviews
        },
        {
          count: 0,
          totalScore: 0,
          reviews: [],
          wouldDoBusiness: 0,
        }
      )

    business.reviews.reviews.sort(reviewSorter)
  }

  const dateFilter = (data: any): boolean =>
    new Date(data._created) > startingDate
  if (doItForReal) {
    console.log('GET READY!')
    const { customers, cases, reviews, businesses } = migration

    if (collectionsToUpdate.includes('customer')) {
      for (const customer of customers.filter(dateFilter)) {
        await setRecord('customer', customer)
        console.log(`saved customer/${customer._id as string}`)
      }
    }

    if (collectionsToUpdate.includes('case')) {
      for (const caseData of cases.filter(dateFilter)) {
        await setRecord('case', caseData)
        console.log(`saved case/${caseData._id as string}`)
      }
    }
    if (collectionsToUpdate.includes('review')) {
      for (const review of reviews.filter(dateFilter)) {
        await setRecord('review', review)
        console.log(`saved review/${review._id as string}`)
      }
    }
    if (collectionsToUpdate.includes('business')) {
      for (const business of businesses) {
        await setRecord('business-profile', business)
        console.log(`saved business/${business._id as string}`)
      }
    }
    console.log('DONE!')
  } else {
    migration.cases = migration.cases.filter(dateFilter)
    migration.reviews = migration.reviews.filter(dateFilter)
    migration.customers = migration.customers.filter(dateFilter)
    console.log(migration, collectionsToUpdate)
  }

  ;(window as unknown as UploadWindow).migration = migration
}

const parseText = (fileContent: string): any[] | null => {
  // JSON files will be ARRAYS and contain no tabs
  if (fileContent.startsWith('[') && !fileContent.includes('\t')) {
    return JSON.parse(fileContent)
  } else if (fileContent.startsWith('{') && !fileContent.includes('\t')) {
    // bizarro Amazon PostGres "JSON"
    const records = fileContent
      .split('\n')
      .map((line) => {
        if (line === '') {
          return false
        }
        try {
          const data = JSON.parse(line.replace(/\\\\/g, '\\'))
          return data
        } catch (e) {
          console.log('parse failed', e, line)
          return false
        }
      })
      .filter((rec) => rec !== false)
    return records
  }
  // love how typescript doesn't know that split always returns an array of one element or more
  const lines = fileContent.split('\n')
  // eslint-disable-next-line
  const columns = lines.shift()!.split('\t')

  const records = lines.map((line) => {
    const values: string[] = line.split('\t')
    const record: { [key: string]: string | number | boolean } = {}
    for (let i = 0; i < values.length; i++) {
      const key = columns[i]
      const value = values[i]
      if (value === 'NULL') {
        // do nothing
      } else if (value === 'true') {
        record[key] = true
      } else if (value === 'false') {
        record[key] = false
      } else if (value.match(/^\d+(\.\d+)?$/) != null) {
        record[key] = Number(value)
      } else {
        record[key] = value
          .replace(/\\n/g, '\n')
          .replace(/\\t/g, '\t')
          .replace(/\\\\/g, '\\')
      }
    }
    return record
  })

  return records
}

const fileViewer = (name: string, records: any[]): HTMLDivElement => {
  const fileTable = dataTable({
    style: { minHeight: '200px', width: '100%', height: '100%' },
    part: 'fileContent',
    value: {
      array: records.sort(
        (a, b) => Object.keys(b).length - Object.keys(a).length
      ),
    },
    onClick(event: Event) {
      const record = getListItem(event.target as HTMLElement)
      if (record !== undefined) {
        console.log(JSON.stringify(record, null, 2))
      }
    },
  })

  const fileFilter = filterBuilder({
    onChange() {
      fileTable.value = {
        ...fileTable.value,
        filter: fileFilter.filter,
      }
    },
  })

  const columnsTable = dataTable({
    hidden: true,
    style: { minHeight: '200px', width: '100%', height: '100%' },
    part: 'fileContent',
  })

  const pickCollection = select(
    {
      style: selectStyle,
      onChange(event: Event) {
        saveButton.disabled = (event.target as HTMLInputElement).value === ''
      },
    },
    option('Pick a collection', { value: '' }),
    option('test'),
    ...collections.map((collection) => option(collection.name))
  )

  const progressBar = progress({
    hidden: true,
    min: 0,
  })

  const saveButton = button('save', {
    class: 'default',
    disabled: true,
    async onClick() {
      const records: any[] =
        fileTable.value.filter != null
          ? fileTable.value.filter(fileTable.value.array)
          : fileTable.value.array
      const collection = pickCollection.value
      const columns = fileTable.value.columns
      saveButton.hidden = true
      progressBar.hidden = false
      progressBar.value = 0
      progressBar.setAttribute('max', String(records.length))
      try {
        for (const record of records) {
          progressBar.value += 1
          progressBar.title = `${progressBar.value}/${records.length}`
          const id = record._id !== undefined ? record._id : record.id
          const data: { [key: string]: any } = {}
          columns?.forEach((column: any) => {
            if (column.prop === 'id' || column.prop === '_id') {
              return
            }
            if (column.export === true && record[column.prop] !== undefined) {
              const newProp =
                column.newProp === undefined ? column.prop : column.newProp
              data[newProp] = record[column.prop]
            }
          })
          await setRecord(collection, data, String(id))
        }
      } catch (e) {
        console.error(e)
        return
      } finally {
        progressBar.hidden = true
        saveButton.hidden = false
      }
    },
  })

  return div(
    {
      name,
      class: 'column',
      style: { width: '100%', height: '100%', alignItems: 'stretch' },
    },
    div(
      {
        class: 'toolbar',
        style: {
          alignItems: 'center',
          height: '50px',
          padding: `0 ${vars.spacing}`,
        },
      },
      h3(name, { class: 'no-margin' }),
      span({ class: 'spacer' }),
      fileFilter,
      span({ class: 'elastic' }),
      label(
        'show columns',
        input({
          type: 'checkbox',
          onClick(event: Event) {
            const showColumns = (event.target as HTMLInputElement).checked
            columnsTable.hidden = !showColumns
            fileTable.hidden = !columnsTable.hidden
            saveButton.hidden = !columnsTable.hidden
            if (columnsTable.hidden) {
              fileTable.value = {
                ...fileTable.value,
                columns: columnsTable.value.array,
              }
            } else {
              columnsTable.value = {
                columns: [
                  {
                    name: 'prop',
                    prop: 'prop',
                    width: 160,
                  },
                  {
                    name: 'name',
                    prop: 'name',
                    width: 160,
                    dataCell() {
                      return input({
                        style: {
                          padding: `0 ${vars.spacing50}`,
                        },
                        bindValue: '^.name',
                        title: 'replace property',
                      })
                    },
                  },
                  {
                    name: 'new prop',
                    prop: 'newProp',
                    width: 120,
                    dataCell() {
                      return input({
                        style: {
                          padding: `0 ${vars.spacing50}`,
                        },
                        bindValue: '^.newProp',
                        title: 'replace property',
                      })
                    },
                  },
                  {
                    name: 'visible',
                    prop: 'visible',
                    width: 60,
                    dataCell() {
                      return span(
                        input({ type: 'checkbox', bindValue: '^.visible' })
                      )
                    },
                  },
                  {
                    name: 'export',
                    prop: 'export',
                    width: 60,
                    dataCell() {
                      return span(
                        input({ type: 'checkbox', bindValue: '^.export' })
                      )
                    },
                  },
                ],
                array: !Array.isArray(fileTable?.value?.columns)
                  ? []
                  : fileTable.value.columns.map((c: any) => {
                      if (c.newProp === undefined) {
                        c.newProp = c.prop
                      }
                      if (c.export === undefined) {
                        c.export = true
                      }
                      if (c.name === undefined) {
                        c.name = c.prop
                      }
                      return c
                    }),
              }
            }
          },
        })
      ),
      pickCollection,
      progressBar,
      saveButton
    ),
    fileTable,
    columnsTable
  )
}

export class UploadTsv extends WebComponent {
  content = div(
    {
      class: 'column',
      style: { height: '100%', alignItems: 'stretch' },
    },
    div(
      { class: 'toolbar primary' },
      a(icons.chevronLeft(), {
        title: 'upload tsv',
        class: 'button',
        href: '/data',
      }),
      input({
        part: 'tsvFile',
        accept: 'text/tab-separated-values,application/json',
        type: 'file',
      }),
      span({ part: 'spacer', class: 'elastic' })
    ),
    tabSelector(
      {
        part: 'collection',
        class: 'elastic',
        style: { height: '100%', display: 'flex' },
      },
      div(
        { name: 'main' },
        div(
          { class: 'centered column' },
          div(
            { class: 'framed', dataTitle: 'Instructions' },
            'load users, cases, comments in that order'
          ),
          label(
            { class: 'column' },
            span('only update records created after…'),
            input({
              id: 'start-date',
              type: 'date',
              value: new Date(Date.now() - 10 * 24 * 3600 * 1000)
                .toISOString()
                .split('T')[0],
            })
          ),
          label(
            { class: 'column' },
            span('collections to update'),
            select(
              { id: 'collections-to-update', multiple: true },
              option('case'),
              option('review'),
              option('customer'),
              option('business', {
                apply(elt: HTMLElement) {
                  elt.setAttribute('selected', '')
                },
              })
            )
          ),
          label(
            input({ id: 'do-it-for-real', type: 'checkbox' }),
            span('do it for real! (save records)')
          ),
          button(span('YOLO!'), {
            class: 'default',
            async onClick(event: Event) {
              const startingDate = new Date(
                (
                  document.getElementById('start-date') as HTMLInputElement
                ).value
              )
              const collectionsToUpdate = [
                ...(
                  document.getElementById(
                    'collections-to-update'
                  ) as HTMLSelectElement
                ).querySelectorAll('option'),
              ]
                .filter((o) => o.selected)
                .map((o) => o.value)
              ;(
                (event.target as HTMLElement).closest(
                  'button'
                ) as HTMLButtonElement
              ).disabled = true

              const doItForReal = (
                document.getElementById('do-it-for-real') as HTMLInputElement
              ).checked
              await migrate(doItForReal, startingDate, collectionsToUpdate)
              ;(
                (event.target as HTMLInputElement).closest(
                  'button'
                ) as HTMLButtonElement
              ).disabled = false
            },
          })
        )
      )
    )
  )

  connectedCallback(): void {
    super.connectedCallback()
    const { collection, tsvFile } = this.parts

    ;(window as unknown as UploadWindow).tables = []
    ;(window as unknown as UploadWindow).files = []

    tsvFile.addEventListener('change', () => {
      const files = (tsvFile as HTMLInputElement).files as FileList
      if (files.length > 0) {
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const records = parseText(reader.result as string)
            if (records === null) {
              return
            }
            const fv = fileViewer(files[0].name, records)
            ;(window as unknown as UploadWindow).tables.push(
              fv.querySelector('data-table') as DataTable
            )
            ;(window as unknown as UploadWindow).files.push(files[0].name)
            ;(collection as TabSelector).append(fv)
            ;(collection as TabSelector).setupTabs()
            ;(collection as TabSelector).value = collection.children.length - 1
            ;(collection as TabSelector).render()
          } catch (e) {
            error(`parsing ${files[0].name} failed`, e)
          }
        }
        reader.readAsText(files[0])
      }
    })
  }
}

export const uploadTsv = UploadTsv.elementCreator({ tag: 'upload-tsv' })
