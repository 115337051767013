import { Component as WebComponent, elements } from 'xinjs'
import { service, logEvent } from '../firebase'
// import { loadingSpinner } from './loading-spinner'
import { scriptTag } from 'xinjs-ui'
import { error } from '../notifications'
import { randomID } from '../random-id'

const { div, slot } = elements

let STRIPE_CLIENT_KEY = ''
const isProduction = ['new.nonono.com', 'nonono.com'].includes(
  window.location.host
)
if (isProduction) {
  STRIPE_CLIENT_KEY = 'pk_live_kR5HYYY2lBP8Vrsgn4XiXkSW'
} else {
  STRIPE_CLIENT_KEY = 'pk_test_phr7ABlqpvgRHHAcSlSCaUvO'
}

export const prefetchStripe = () => {
  scriptTag('https://js.stripe.com/v3/')
}

interface OrderItem {
  id: string
  name: string
  amount: string
}

export class StripePayment extends WebComponent {
  name = ''
  itemId?: string
  itemName = 'Purchased Item'
  value: any | null = null
  _items: OrderItem[] = []
  stripeElements: any | undefined
  destinationUrl = '/'
  showPayButton = false
  paymentIntent: string | undefined
  stripe?: any

  set items(newItems: OrderItem[]) {
    this._items = newItems
    this.queueRender()
  }

  get items(): OrderItem[] {
    if (this._items.length === 0 && this.value) {
      this._items.push({
        id: this.itemId || randomID(24),
        name: this.itemName,
        amount: String(this.value || 15),
      })
    }
    return this._items
  }

  constructor() {
    super()
    this.initAttributes('destinationUrl', 'showPayButton', 'name')
  }

  initialize = async (): Promise<void> => {
    const { Stripe } = await scriptTag('https://js.stripe.com/v3/')

    if (!this.itemId) {
      const pageNavigator = this.closest('page-navigator') as any
      if (pageNavigator) {
        this.itemId = pageNavigator.value?._id
      }
    }

    // The items the customer wants to buy
    const clientSecret = await service.intenttopay.post(this.items)
    this.value = clientSecret.split('_secret')[0]

    const options = {
      clientSecret,
      // Fully customizable with appearance API.
      appearance: {
        /* ... */
      },
    }

    this.stripe = Stripe(STRIPE_CLIENT_KEY)
    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
    this.stripeElements = this.stripe.elements(options)

    // GA Funnel
    if (isProduction) {
      logEvent('begin_checkout', {
        transaction_id: this.items[0].id,
        currency: 'USD',
        items: [
          {
            item_id: `${this.items[0].name.replace(/ /g, '-')}-complaint`,
            item_name: `${this.items[0].name} Complaint Resolution Service`,
          },
        ],
        value: this.items[0].amount,
      })
    }

    // Create and mount the Payment Element
    const paymentElement = this.stripeElements.create('payment')
    paymentElement.mount('.payment-element')
  }

  submitPayment = async (
    event?: Event
  ): Promise<{ error: string } | undefined> => {
    event?.preventDefault()
    try {
      const outcome = await this.stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements: this.stripeElements,
        confirmParams: {
          return_url: this.destinationUrl,
        },
      })
      if (outcome.error) {
        error(outcome.error.message)
      }
    } catch (error) {
      logEvent('exception', {
        info: 'payment_exception',
        transaction_id: this.items[0].id,
        currency: 'USD',
        items: [
          {
            item_id: `${this.items[0].name.replace(/ /g, '-')}-complaint`,
            item_name: `${this.items[0].name} Complaint Resolution Service`,
          },
        ],
        value: this.items[0].amount,
      })
      console.error(error)
      return { error: error as string }
    }
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.initialize().catch((error: any) => console.log(error))
  }

  content = (): HTMLElement[] => [
    slot(),
    div(
      { part: 'payment', class: 'payment-element' },
      div('contacting stripe…')
    ),
    div({ id: 'error-message' }),
    slot({ name: 'footer' }),
  ]
}

export const stripePayment = StripePayment.elementCreator({
  tag: 'stripe-payment',
  styleSpec: {
    ':host': {
      display: 'flex',
      flexDirection: 'column',
    },
    ':host [part="payment"]': {
      flex: 1,
    },
  },
})
