import { xin, elements } from 'xinjs'
import { Message } from '../mocks/message'
import { Case } from '../mocks/case'
import { caseStateTests } from '../case-state'
import { getRecord } from '../firebase'
import { BrandProfile } from '../mocks/business-profile'
import { populateTemplate } from '../populate-template'
import { stringFallback } from '../fallback'
import { loadingSpinner } from '../components/loading-spinner'
import { randomID } from '../random-id'
import { gpt } from '../gpt'
import { marked } from 'marked'

export interface Snippet {
  _id?: string
  name: string
  context: 'case' | 'message' | 'none'
  action: keyof typeof snippetActions
  setEscalated: boolean
  setResolved: boolean
  assignToMe: boolean
  requiredCaseState: keyof typeof caseStateTests
  subject?: string
  body: string
  isVisibleInMenus: boolean
  tags?: string[]
}

async function buildSnippetContext(
  caseData?: Case
): Promise<BrandProfile | undefined> {
  const business =
    caseData?.businessId !== undefined
      ? ((await getRecord(
          'business-profile',
          caseData.businessId
        )) as unknown as BrandProfile)
      : undefined

  xin.snippetContext = {
    business,
    case: caseData !== undefined ? caseData : {},
    server: {
      host: window.location.origin,
      summarize(text: string) {
        const id = randomID()
        gpt('summarize', text)
          .then((summary: string) => {
            const spinner = document.getElementById(id)
            if (spinner !== null) {
              const div = elements.div()
              div.innerHTML = marked(summary.replace(/• /g, '- '), {
                mangle: false,
                headerIds: false,
              })
              spinner.replaceWith(...div.childNodes)
            }
          })
          .catch(() => {
            const spinner = document.getElementById(id)
            if (spinner !== null) {
              spinner.replaceWith(elements.span('An error occurred…'))
            }
          })
        return loadingSpinner({ id }, 'watch this space!').outerHTML
      },
      test(...args: any[]) {
        return args.join('<br>')
      },
    },
  }
  return business
}

function populateMessage(
  snippet: Snippet,
  message: Message,
  caseData?: Case,
  addToMessage = false
): void {
  const html = populateTemplate(snippet.body, 'snippetContext', true, true)
  if (addToMessage) {
    message.html = `${stringFallback(message.html)}\n${html}`
    return
  }
  message.html = html
  if (typeof snippet?.subject === 'string' && snippet.subject !== '') {
    message.subject = populateTemplate(
      snippet.subject,
      'snippetContext',
      false,
      false
    )
  } else if (caseData?.title !== undefined) {
    message.subject = `Re: ${caseData.title} Ref #${caseData._id}`
  }
}

const snippetContexts = {
  case: true,
  message: true,
}

export const SNIPPET_CONTEXTS = Object.keys(snippetContexts)

type SnippetAction = (
  snippet: Snippet,
  message: Message,
  caseData?: Case
) => Promise<void>

export const snippetActions: { [key: string]: SnippetAction } = {
  'message to customer': async (
    snippet: Snippet,
    message: Message,
    caseData?: Case
  ) => {
    await buildSnippetContext(caseData)
    if (caseData?.email !== undefined) {
      message.to = caseData.email
    }
    populateMessage(snippet, message, caseData)
  },
  'message to business': async (
    snippet: Snippet,
    message: Message,
    caseData?: Case
  ) => {
    const business = await buildSnippetContext(caseData)
    message.to = (business?.escalationEmails || []).join(', ')
    populateMessage(snippet, message, caseData)
  },
  'add to message': async (
    snippet: Snippet,
    message: Message,
    caseData?: Case
  ) => {
    await buildSnippetContext(caseData)
    populateMessage(snippet, message, caseData, true)
  },
}

export const SNIPPET_ACTIONS = Object.keys(snippetActions)

export const snippet: Snippet = {
  name: 'default-escalation',
  context: 'case',
  requiredCaseState: 'any',
  action: 'createMessage',
  setEscalated: true,
  setResolved: false,
  assignToMe: false,
  isVisibleInMenus: false,
  subject: '{{case.title}} -- Case#{{case._id}}',
  body: `
Re: Case#{{case._id}}

Hey there,

We have received a detailed complaint from a customer of yours:
## {{case.nameFirst}} {{case.nameSecond}}
**Email**: [{{case.email}}](mailto:{{case.email}})
**Phone**: [{{case.phone}}](tel:{{case.phone}})

with details as follows:

## {{case.title}}

{{case.description}}

We hope you can resolve this matter to {{case.nameFirst}}'s and
your own mutual satisfaction.

Best regards,


Customer Care,
NoNoNo.com
`,
}
