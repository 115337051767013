import { elements } from 'xinjs'
import { marked } from 'marked'
import { icons } from 'xinjs-ui'

const { slAlert, h4 } = elements

const variantMap = {
  primary: 'primary',
  info: 'primary',
  success: 'success',
  danger: 'danger',
  error: 'danger',
  warning: 'warning',
  neutral: 'neutral',
}
export type NotificationVariant = keyof typeof variantMap

export function notify(
  message: string,
  variant: NotificationVariant = 'primary',
  icon = 'info',
  duration = 5000
): void {
  if (message.length > 200) {
    message = message.substring(0, 200) + '…'
  }
  const alert = slAlert(
    {
      variant: variantMap[variant],
      closable: true,
      duration,
      apply(elt: HTMLElement) {
        elt.innerHTML += marked(`${message as unknown as string}`, {
          mangle: false,
          headerIds: false,
        })
      },
    },
    icons[icon]({ slot: 'icon' }),
    h4(variant)
  )

  document.body.append(alert)

  alert.toast()
}

export function log(message: string, ...args: any) {
  notify(message, 'info', 'info')
  console.log(message, ...args)
}

export function warn(message: string, ...args: any) {
  notify(message, 'warning', 'flag')
  console.warn(message, ...args)
}

export function error(message: string, ...args: any) {
  notify(message, 'error', 'thumbsDown')
  console.error(message, ...args)
}

export function success(message: string, ...args: any) {
  notify(message, 'success', 'thumbsUp')
  console.log(message, ...args)
}
