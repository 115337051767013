import { Component as WebComponent, ElementCreator, elements, xin } from 'xinjs'
import { XinForm, XinField } from 'xinjs-ui'
import { StripePayment } from './stripe-payment'
import { error } from '../notifications'
import { setRecord, logEvent, gk } from '../firebase'
import { randomID } from '../random-id'

const { slot } = elements

class PageNavigator extends WebComponent {
  page: string | number = Number(window.location.hash.substring(1))
  pages: HTMLElement[] = []
  collection = ''
  value: { [key: string]: any } = {
    _id: randomID(24),
  }
  persistId = ''
  persistDurationHours = 24

  content = () => slot()

  static styleSpec = {
    ':host': {
      display: 'block',
    },
  }

  constructor() {
    super()

    this.initAttributes(
      'page',
      'collection',
      'json',
      'persistId',
      'persistDurationHours'
    )
  }

  save = async () => {
    if (this.collection) {
      this.value.gk = gk
      await setRecord(this.collection, this.value)
    }
  }

  persist = (event: Event) => {
    const field = event.target
    if (this.persistId && field && field instanceof XinField) {
      try {
        JSON.stringify(field.value)
        this.value[field.key] = field.value
        this.value._timestamp = Date.now()
        localStorage.setItem(this.persistId, JSON.stringify(this.value))
      } catch (e) {
        console.warn('cannot persist field value for key', field)
      }
    }
  }

  restore = () => {
    if (this.persistId) {
      const persisted = localStorage.getItem(this.persistId)
      if (persisted) {
        try {
          const value = JSON.parse(persisted)
          if (
            !value._timestamp ||
            value._timestamp <
              Date.now() - Number(this.persistDurationHours * 3600 * 1000)
          ) {
            console.warn(this.persistId, 'out of date')
            return
          }
          for (const key of Object.keys(value)) {
            const field = this.querySelector(
              `xin-field[key="${key}"]`
            ) as XinField | null
            if (field) {
              field.value = value[key]
            }
          }
        } catch (e) {
          console.error('Error parsing persisted data', this.persistId)
        }
      }
    }
  }

  logNavigation = () => {
    const { route } = xin as any
    logEvent('page_view', {
      page_title: route.title + `-${this.page}`,
      page_path: route.href,
      page_location: window.location.pathname,
    })
    this.queueRender()
  }

  next = () => {
    const page = Number(this.page)
    if (page < this.pages.length - 1) {
      this.page = page + 1
      window.history.pushState(
        {},
        '',
        window.location.pathname + `#${this.page}`
      )
      this.logNavigation()
    }
  }

  previous = () => {
    const page = Math.min(Number(this.page), this.pages.length - 1)
    if (page > 0) {
      this.page = page - 1
      window.history.pushState(
        {},
        '',
        window.location.pathname + `#${this.page}`
      )
      this.logNavigation()
    }
  }

  handleFormSubmit = (formData: any, isValid: boolean) => {
    Object.assign(this.value, formData)
    this.save()
    if (isValid) {
      this.next()
    }
  }

  handleClick = async (event: Event) => {
    const target = event.target as HTMLElement | null

    if (!target) {
      return
    }

    const button = target.closest('button') as HTMLButtonElement | null
    if (button) {
      button.disabled = true
    }
    const role = target.getAttribute('aria-role')
    switch (role) {
      case 'continue':
        this.next()
        break
      case 'back':
        this.back()
        break
      case 'submit':
        {
          const form = target.closest('xin-form') as XinForm | null
          if (form) {
            form.submitCallback = this.handleFormSubmit
            form.submit()
          } else {
            this.next()
          }
        }
        break
      case 'submit-payment':
        {
          const stripePayment = this.querySelector(
            'stripe-payment'
          ) as StripePayment | null
          if (stripePayment) {
            stripePayment.destinationUrl = `${location.protocol}//${location.host}/thank-you/${this.value._id}`
            this.value[stripePayment.name] = stripePayment.value
            await this.save()
            try {
              await stripePayment.submitPayment()
            } catch (errorMessage) {
              error(errorMessage as string)
            }
          }
        }
        break
    }
    if (button) {
      button.disabled = false
    }
  }

  hashchange = () => {
    this.page = Number(window.location.hash.substring(1))
    this.logNavigation()
  }

  connectedCallback() {
    super.connectedCallback()

    this.addEventListener('click', this.handleClick)
    this.addEventListener('change', this.persist, true)
    window.addEventListener('hashchange', this.hashchange)

    requestAnimationFrame(this.restore)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('hashchange', this.hashchange)
  }

  render() {
    super.render()

    this.pages = [...this.children] as HTMLElement[]

    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].toggleAttribute('hidden', i !== this.page)
    }

    this.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export const pageNavigator = PageNavigator.elementCreator({
  tag: 'page-navigator',
}) as ElementCreator<PageNavigator>
