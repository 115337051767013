import { Component as WebComponent, elements, vars, throttle } from 'xinjs'
import {
  tabSelector,
  xinFloat,
  codeEditor,
  CodeEditor,
  icons,
  xinSizer,
  xinTagList,
  XinTagList,
  popMenu,
} from 'xinjs-ui'
import { randomID } from '../random-id'
import { MarkdownViewer } from './markdown-viewer'
import { service } from '../firebase'
import { error, success } from '../notifications'
import { Page } from '../../functions/shared/page'
import { niceDate } from '../nice-date'
import { Version } from '../../functions/src/collections'

const { form, h4, label, span, input, button, textarea } = elements

interface AttributeMap {
  [key: string]: string
}

type SerializedNode = string | [string, AttributeMap, SerializedNode[]]

function serializeElement(elt: Node): SerializedNode {
  if (elt instanceof Text) {
    return (elt.textContent || '').trim()
  } else if (!(elt instanceof Element)) {
    return ''
  }
  return [
    elt.tagName.toLocaleLowerCase(),
    elt.getAttributeNames().reduce((map: AttributeMap, name: string) => {
      if (name) {
        map[name] = elt.getAttribute(name) as string
      }
      return map
    }, {}),
    [...elt.childNodes].map(serializeElement).filter((s) => s !== ''),
  ]
}

type PageEditorParts = {
  cssEditor: CodeEditor
  markdownEditor: CodeEditor
  pathInput: HTMLInputElement
  title: HTMLInputElement
  description: HTMLInputElement
  tags: XinTagList
  classField: HTMLInputElement
  dummyContext: HTMLInputElement
}

const blankPage = Object.freeze({
  class: '',
  css: '',
  description: '',
  markdown: 'create a new page!',
  title: '',
  path: '',
  tags: [] as string[],
})

export class PageEditor extends WebComponent {
  pageId = ''
  path = ''
  dummyContext =
    'business-profile/287, case/6y50kfe1x2g5t03i, review/e37siwwejjphwhi1'
  loadedContext: any = undefined
  // page record
  private _page: Page | null = null

  get page(): Page {
    return this._page !== null
      ? Object.assign({}, blankPage, this._page)
      : Object.assign({}, blankPage)
  }

  set page(p: any) {
    this._page = Object.assign({}, blankPage, p)
  }

  // markdown viewer
  pageView: MarkdownViewer | null = null

  get pageData(): Page {
    const {
      cssEditor,
      markdownEditor,
      pathInput,
      title,
      description,
      tags,
      classField,
    } = this.parts as PageEditorParts
    return {
      _id: this.page._id || randomID(16),
      path: pathInput.value,
      title: title.value || '',
      description: description.value,
      markdown: markdownEditor.value,
      css: cssEditor.value,
      tags: tags.tags,
      class: classField.value,
    }
  }

  async save(publish = false): Promise<void> {
    let { pageData } = this
    if (pageData.markdown.trim() === '') {
      error('Save failed. Make sure your page has content!')
      return
    }
    const versionNote = prompt('Version note (required)')
    if (!versionNote) {
      return
    }

    pageData = await service.record.put({
      p: `page/${pageData._id}`,
      data: pageData,
      versionNote,
      publish,
    })

    if (this.page === null) {
      this.page = pageData
    } else {
      this.page = Object.assign(this.page, pageData)
    }

    success(
      `${publish ? 'Published' : 'Version saved'} page ${pageData.path} page/${
        pageData._id
      }`
    )
  }

  saveVersion = async (): Promise<void> => {
    this.save()
  }

  publish = async (): Promise<void> => {
    if (confirm('This will update production data, are you sure?')) {
      this.save(true)
    }
  }

  deletePage = (): void => {
    if (this.page === null || !this.page?._id) {
      error('No page records exists yet, nothing to delete')
      return
    }
    const description = `page (path: "${this.page!.path}", id: ${
      this.page!._id
    })`
    if (confirm(`Delete ${description}?`)) {
      service.record
        .delete({ p: `page/${this.page._id}` })
        .then(() => success(`Deleted ${description}`))
        .catch((err) => error(`Failed to delete ${description}`, err))
    }
  }

  refresh = (): void => {
    const { pageView } = this
    const { markdownEditor, cssEditor, classField } = this
      .parts as PageEditorParts
    if (pageView !== null) {
      pageView.context = this.loadedContext
      pageView.value = markdownEditor.value
      pageView.css = cssEditor.value
      if (classField.value.trim()) {
        ;(pageView as unknown as HTMLElement).setAttribute(
          'class',
          classField.value
        )
      } else {
        ;(pageView as unknown as HTMLElement).removeAttribute('class')
      }
    }
  }

  serialize = () => {
    if (this.pageView) {
      const target = this.pageView.querySelector('[serialize]')
      if (target) {
        console.log(serializeElement(target))
      } else {
        console.error('no [serialize] target found')
      }
    }
  }

  getContext = async () => {
    const { dummyContext } = this.parts as { dummyContext: HTMLInputElement }
    const requests = dummyContext.value
      .split(',')
      .map((c: string) => c.trim().split('/'))
      .filter((c: string[]) => c.length === 2)
      .map(([collection, id]) =>
        service.record.get({ p: `${collection}/${id}` })
      )

    const records = await Promise.all(requests)

    const context: { [key: string]: any } = {}
    for (const record of records) {
      if (record._collection === 'business-profile') {
        context.business = record
      } else {
        context[record._collection] = record
      }
    }
    return context
  }

  handleInput = throttle(() => {
    this.refresh()
  }, 2500)

  restoreVersion = async (version: Version) => {
    const data = await service.record.get({
      p: `page/${this.page._id}`,
      version: version.versionId,
    })
    success(`Loaded version "${version.note}"`)
    this.page = Object.assign({}, this.page, data)
    this.queueRender()
  }

  showVersions = async (event: Event) => {
    const versions: Version[] =
      (await service.record.get({
        p: `page/${this.page._id}`,
        versions: 50,
      })) || []
    if (versions.length) {
      const { restoreVersion } = this
      popMenu({
        target: event.target as HTMLElement,
        menuItems: versions.map((version: any) => ({
          icon: version.published ? 'check' : undefined,
          caption: version.note
            ? `${version.note} (${niceDate(
                new Date(Number(version.timestamp))
              )})`
            : niceDate(new Date(Number(version.timestamp || version))),
          action() {
            restoreVersion(version)
          },
        })),
      })
    } else {
      error('No previous versions of this page are available')
    }
  }

  showMenu = (event: Event) => {
    const { saveVersion, publish, revert, deletePage, serialize } = this
    popMenu({
      target: event.target as HTMLElement,
      menuItems: [
        {
          icon: 'menu',
          caption: 'Pages',
          action: '/data?c=page',
        },
        null,
        {
          icon: 'downloadCloud',
          caption: 'Revert',
          action: revert,
        },
        {
          icon: 'uploadCloud',
          caption: 'Save',
          action: saveVersion,
        },
        {
          icon: 'uploadCloud',
          caption: 'Publish',
          action: publish,
        },
        {
          icon: 'code',
          caption: 'Serialize',
          action: serialize,
        },
        null,
        {
          icon: icons.trash({ style: { fill: 'red' } }),
          caption: 'Delete',
          action: deletePage,
        },
      ],
    })
  }

  content = () =>
    xinFloat(
      {
        drag: true,
        remainOnScroll: 'remain',
        remainOnResize: 'remain',
        style: {
          height: '400px',
          width: '600px',
          minWidth: '300px',
          maxWidth: '100%',
          bottom: '10px',
          right: '10px',
        },
        oninput: this.handleInput,
      },
      h4('Page Editor', {
        class: 'primary no-margin',
        style: {
          textAlign: 'center',
          padding: vars.spacing50,
          color: vars.textColor,
        },
      }),
      tabSelector(
        { class: 'no-drag', style: { flex: '1 1 auto', width: '100%' } },
        form(
          {
            name: 'config',
            class: 'compact',
            style: { padding: vars.spacing, height: '100%' },
          },
          label(
            span('path'),
            input({
              part: 'pathInput',
              pattern: '[\\w\\-\\/]+',
              placeholder: 'path/to/page',
            })
          ),
          label(
            span('<title> and <meta og:title>'),
            textarea({
              part: 'title',
              placeholder: 'title for page and SEO',
            })
          ),
          label(
            span('<meta og:description>'),
            input({
              part: 'description',
              placeholder: 'description for page and SEO',
            })
          ),
          label(
            span('tags'),
            xinTagList({
              part: 'tags',
              custom: true,
              editable: true,
              placeholder: 'page tags',
              availableTags: ['admin', 'menu'],
              style: {
                '--line-height': '24px',
              },
              onClick(event: Event) {
                event.stopPropagation()
                event.preventDefault()
              },
            })
          ),
          label(
            span('class'),
            input({
              part: 'classField',
              placeholder: 'css classes for page as a whole',
            })
          ),
          label(
            span('dummy context'),
            span(
              { class: 'row' },
              input({
                part: 'dummyContext',
                class: 'elastic',
                placeholder: 'collection/id, collection/id',
                value: this.dummyContext,
              })
            )
          )
        ),
        codeEditor({
          name: 'markdown',
          mode: 'markdown',
          class: 'code',
          part: 'markdownEditor',
          theme: 'ace/theme/monokai',
          options: { wrap: true },
          style: { width: '100%', height: '100%' },
        }),
        codeEditor({
          name: 'css',
          mode: 'css',
          class: 'code',
          part: 'cssEditor',
          theme: 'ace/theme/monokai',
          options: { wrap: true },
          style: { width: '100%', height: '100%' },
        }),
        button(
          {
            slot: 'after-tabs',
            title: 'versions',
            class: 'compact transparent no-outline',
            onClick: this.showVersions,
          },
          icons.rewind()
        ),
        button(
          {
            slot: 'after-tabs',
            title: 'menu',
            class: 'compact transparent no-outline',
            onClick: this.showMenu,
          },
          icons.moreVertical()
        )
      ),
      xinSizer({ class: 'no-drag', style: { zIndex: 10, background: '#fff8' } })
    )

  constructor() {
    super()

    this.initAttributes('path')
  }

  revert = () => {
    if (window.confirm('Reload page? Any unsaved changes will be lost.')) {
      this.loadPage()
    }
  }

  loadDummyContext = async (): Promise<void> => {
    this.loadedContext = await this.getContext()
    this.queueRender()
  }

  loadPage = async (): Promise<void> => {
    const { path, pageId } = this
    let page
    if (pageId) {
      page = await service.record.get({ p: `page/${pageId}` })
    } else if (path) {
      page = await service.record.get({ p: `page/path=${path}` })
    }
    this.page = Object.assign({}, blankPage, page || {})
    await this.loadDummyContext()
  }

  connectedCallback() {
    super.connectedCallback()

    const { dummyContext } = this.parts as { dummyContext: HTMLElement }
    dummyContext.addEventListener('change', this.loadDummyContext)

    void this.loadPage()
  }

  render(): void {
    super.render()

    const { page } = this

    const {
      cssEditor,
      markdownEditor,
      pathInput,
      title,
      description,
      tags,
      classField,
    } = this.parts as PageEditorParts

    if (page !== null) {
      markdownEditor.value = page.markdown
      cssEditor.value = page.css
      pathInput.value = page.path
      title.value = page.title
      description.value = page.description
      tags.value = page.tags
      classField.value = page.class
    }

    this.refresh()
  }
}

export const pageEditor = PageEditor.elementCreator({ tag: 'page-editor' })
