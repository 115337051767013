// copied from outer project -- FIXME replace original with this file

export interface Tagged {
  tags?: string[]
}

export const hasTags = (tagged: Tagged, ...tags: string[]): boolean => {
  if (tagged.tags === undefined) {
    return tags.length === 0
  }
  return !tags.find((tag) => !tagged.tags!.includes(tag))
}

export const hasLegacyStatus = (tagged: Tagged, tag: string): boolean => {
  const statusProp = `is${tag[0].toLocaleUpperCase()}${tag.slice(1)}`
  return (tagged as any)[statusProp] === true || hasTags(tagged, tag)
}

export const addTags = (tagged: Tagged, ...tags: string[]): void => {
  tags = [...new Set(tags)]
  if (tagged.tags === undefined) {
    tagged.tags = tags
  } else {
    for (const tag of tags) {
      if (!tagged.tags.includes(tag)) {
        tagged.tags.push(tag)
      }
    }
  }
}

export const removeTags = (tagged: Tagged, ...tags: string[]) => {
  if (tagged.tags === undefined) {
    return
  }
  tagged.tags = tagged.tags.filter((tag) => !tags.includes(tag))
}

export const setTag = (
  tagged: Tagged,
  tag: string,
  shouldHaveTag: boolean
): void => {
  if (shouldHaveTag) {
    addTags(tagged, tag)
  } else {
    removeTags(tagged, tag)
  }
}

interface TagMap {
  [key: string]: string
}

export const inferTags = (
  orig: any,
  tagMap: TagMap,
  removeLegacyProps = false
): any & Tagged => {
  const tagged = { tags: [] as string[], ...orig }
  if (!Array.isArray(tagged.tags)) {
    console.error('inferTags failed:', tagged, 'has a non-array tags property')
    throw new Error(
      'inferTags cannot tag an object with a non-array tags property'
    )
  }
  for (const legacyProp of Object.keys(tagMap)) {
    setTag(tagged, tagMap[legacyProp], tagged[legacyProp])
    if (removeLegacyProps) {
      delete tagged[legacyProp]
    }
  }
  return tagged
}

function prop2Tag(key: string) {
  return key.substring(2, 3).toLocaleLowerCase() + key.substring(3)
}

export function tagProxy(
  tagged: { [key: string]: any },
  prop = 'tags'
): { [key: string]: boolean | string[] } {
  const tags: string[] = (tagged && tagged[prop]) || []
  for (const key of Object.keys(tagged)) {
    if (key.match(/^is[A-Z]/)) {
      const tag = prop2Tag(key)
      if (tagged[key]) {
        if (!tags.includes(tag)) {
          tags.push(tag)
        }
      } else {
        delete tagged[key]
      }
    }
  }
  tagged[prop] = tags
  return new Proxy([], {
    get(_: string[], tag: string | symbol): boolean | string[] {
      if (tag === prop) {
        return tags
      }
      if (typeof tag === 'string') {
        if (tag.match(/^is[A-Z]/)) {
          tag = prop2Tag(tag)
        }
        return typeof tag === 'string' && tags.includes(tag)
      } else {
        return false
      }
    },

    set(_: string[], tag: string | symbol, value: any, receiver: any): boolean {
      if (typeof tag === 'string') {
        if (tag.match(/^is[A-Z]/)) {
          tag = prop2Tag(tag)
        }
        if (value && !tags.includes(tag)) {
          tags.push(tag)
        } else if (!value && tags.includes(tag)) {
          tags.splice(tags.indexOf(tag), 1)
        }
      }
      return true
    },
  }) as unknown as { [key: string]: boolean | string[] }
}
