/*
# shoelace web-components

TODO comment out the ones not used or, ideally, build a proxy that automatically tracks
which <sl-...> components are being used and create an optimized cherry-picked file
automatically.
*/
import '@shoelace-style/shoelace/dist/themes/light.css'
import '@shoelace-style/shoelace/dist/components/alert/alert'
// import '@shoelace-style/shoelace/dist/components/animated-image/animated-image'
// import '@shoelace-style/shoelace/dist/components/animation/animation'
// import '@shoelace-style/shoelace/dist/components/avatar/avatar'
import '@shoelace-style/shoelace/dist/components/badge/badge'
// import '@shoelace-style/shoelace/dist/components/breadcrumb/breadcrumb'
// import '@shoelace-style/shoelace/dist/components/breadcrumb-item/breadcrumb-item'
// import '@shoelace-style/shoelace/dist/components/button/button'
// import '@shoelace-style/shoelace/dist/components/button-group/button-group'
//import '@shoelace-style/shoelace/dist/components/card/card'
import '@shoelace-style/shoelace/dist/components/carousel/carousel'
import '@shoelace-style/shoelace/dist/components/carousel-item/carousel-item'
// import '@shoelace-style/shoelace/dist/components/checkbox/checkbox'
// import '@shoelace-style/shoelace/dist/components/color-picker/color-picker'
// import '@shoelace-style/shoelace/dist/components/details/details'
// import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/divider/divider'
import '@shoelace-style/shoelace/dist/components/drawer/drawer'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
// import '@shoelace-style/shoelace/dist/components/format-bytes/format-bytes'
// import '@shoelace-style/shoelace/dist/components/format-date/format-date'
// import '@shoelace-style/shoelace/dist/components/format-number/format-number'
// import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button'
// import '@shoelace-style/shoelace/dist/components/image-comparer/image-comparer'
// import '@shoelace-style/shoelace/dist/components/include/include'
// import '@shoelace-style/shoelace/dist/components/input/input'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label'
// import '@shoelace-style/shoelace/dist/components/mutation-observer/mutation-observer'
// import '@shoelace-style/shoelace/dist/components/option/option'
// import '@shoelace-style/shoelace/dist/components/popup/popup'
// import '@shoelace-style/shoelace/dist/components/progress-bar/progress-bar'
// import '@shoelace-style/shoelace/dist/components/progress-ring/progress-ring'
// import '@shoelace-style/shoelace/dist/components/qr-code/qr-code'
// import '@shoelace-style/shoelace/dist/components/radio/radio'
// import '@shoelace-style/shoelace/dist/components/radio-button/radio-button'
// import '@shoelace-style/shoelace/dist/components/radio-group/radio-group'
// import '@shoelace-style/shoelace/dist/components/range/range'
import '@shoelace-style/shoelace/dist/components/rating/rating'
// import '@shoelace-style/shoelace/dist/components/relative-time/relative-time'
// import '@shoelace-style/shoelace/dist/components/resize-observer/resize-observer'
// import '@shoelace-style/shoelace/dist/components/select/select'
// import '@shoelace-style/shoelace/dist/components/skeleton/skeleton'
// import '@shoelace-style/shoelace/dist/components/spinner/spinner'
// import '@shoelace-style/shoelace/dist/components/split-panel/split-panel'
// import '@shoelace-style/shoelace/dist/components/switch/switch'
// import '@shoelace-style/shoelace/dist/components/tab/tab'
// import '@shoelace-style/shoelace/dist/components/tab-group/tab-group'
// import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel'
// import '@shoelace-style/shoelace/dist/components/tag/tag'
// import '@shoelace-style/shoelace/dist/components/textarea/textarea'
// import '@shoelace-style/shoelace/dist/components/tooltip/tooltip'
// import '@shoelace-style/shoelace/dist/components/tree/tree'
// import '@shoelace-style/shoelace/dist/components/tree-item/tree-item'
// import '@shoelace-style/shoelace/dist/components/visually-hidden/visually-hidden'
