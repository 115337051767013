import { Tagged } from './tags'

export interface CaseHistoryItem {
  timestamp: string
  description: string
}

export interface Case extends Tagged {
  isReviewed: boolean
  customerLink: [
    {
      token: string
      expires: string
    }
  ]
  isPaid?: boolean
  nameLast: string
  _collection: string
  reviewLink: [
    {
      expires: string
      token: string
    },
    {
      token: string
      expires: string
    }
  ]
  nameFirst: string
  businessId: string
  businessPath?: string
  description: string
  mobilePhone: string
  customerId: string
  _id: string
  businessName: string
  agreeToTerms?: boolean
  businessLink: [
    {
      expires: string
      token: string
    }
  ]
  summary: string
  title: string
  _created: string
  email: string
  assignedUserId: string
  isResolved: boolean
  isClosed: boolean
  _initialized?: boolean
  isEscalated: boolean
  price: number
  history?: CaseHistoryItem[]
  _modified?: string
  paymentIntent?: string
  tags?: string[]
  referrer?: string
  gk?: string[]
  slug?: string
  reviewId?: string
}

export const CaseStatusTags = {
  paymentLogged: 'payment-logged',
  paid: 'paid',
  resolved: 'resolved',
  reviewed: 'reviewed',
  escalated: 'escalated',
  closed: 'closed',
  assigned(userId: string) {
    return `assigned-${userId}`
  },
}
