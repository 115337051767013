import { Component as WebComponent, vars } from 'xinjs'

class CardView extends WebComponent {
  static styleSpec = {
    ':host': {
      background: vars.inputBg,
      boxShadow: vars.zShadow,
      display: 'block',
      padding: vars.spacing,
      borderRadius: vars.roundedRadius,
      minWidth: 200,
    },

    '::slotted(:first-child)': {
      marginTop: '0 !important',
    },

    '::slotted': {
      maxWidth: '100%',
    },
  }
}

export const cardView = CardView.elementCreator({ tag: 'card-view' })
