import {
  Component as WebComponent,
  elements,
  debounce,
  vars,
  xinProxy,
  observe,
  getListItem,
} from 'xinjs'
import { CollectionSpec } from '../collection-spec'
import { DeepSearch } from './search'
import { loadingSpinner } from './loading-spinner'
import { getRecord, getRecords, setRecord, deleteRecord } from '../firebase'
import { stringFallback } from '../fallback'
import { error, success } from '../notifications'
import { randomID } from '../random-id'
import {
  dataTable,
  DataTable,
  filterBuilder,
  FilterBuilder,
  availableFilters,
  xinFloat,
  XinFloat,
  xinSizer,
  xinSelect,
  XinSelect,
  SelectOptions,
  icons,
  popMenu,
} from 'xinjs-ui'
import { makeSorter } from '../sort'
import { app } from '../app'

const DEFAULT_LIMIT = 500

const download = (name: string, data: string): void => {
  const link = elements.a({
    download: name,
    href: `data:text/plain;charset=UTF-8,${encodeURIComponent(data)}`,
  })
  link.click()
}

availableFilters.hasTags = {
  caption: 'has tags',
  makeTest: (value: string) => {
    const desiredTags = value
      .split(',')
      .filter((t) => t.trim())
      .filter((t) => t !== '')
    return (obj: any) => {
      return (
        desiredTags.length === 0 ||
        !desiredTags.find((tag) =>
          tag.startsWith('~')
            ? Array.isArray(obj.tags) && !obj.tags.includes(tag.slice(1))
            : Array.isArray(obj.tags) && obj.tags.includes(tag.slice(1))
        )
      )
    }
  },
}

export const { dbTool } = xinProxy({
  dbTool: {
    collection: '',
    collections: [] as CollectionSpec[],
    stats: 'no records',
    object: undefined as any,
    records: [] as any[],
    visibleRecords(): any[] {
      const crud = document.querySelector('crud-tool') as CrudTool
      return crud ? (crud.parts.table as DataTable).visibleRows : []
    },
    export() {
      const rows = dbTool.visibleRecords() as any[]
      if (!rows.length) {
        error('No data to export')
      }
      const fields: string[] = [
        ...rows.reduce((fieldSet: Set<string>, row: any) => {
          const fields = Object.keys(row)
          for (const field of fields) {
            fieldSet.add(field)
          }
          return fieldSet
        }, new Set() as Set<string>),
      ]
      fields.sort()
      const exportRows = [fields.join('\t')]
      for (const row of rows) {
        exportRows.push(
          fields
            .map((field) => {
              const value = row[field] != undefined ? `${row[field]}` : ''
              return value.replace(/\n/g, '\\n').replace(/\t/g, '\\t')
            })
            .join('\t')
        )
      }
      download(
        `${rows[0]._collection} x ${
          rows.length
        } exported ${new Date().toDateString()}.tsv`,
        exportRows.join('\n')
      )
    },
    tableColumns: null as null | any[],
    getColumns() {
      const table = document.querySelector('data-table') as DataTable
      dbTool.getJSON(table.value.columns)
    },
    get collectionSpec(): CollectionSpec | undefined {
      return dbTool.collections.find((c) => c.name == dbTool.collection) as
        | CollectionSpec
        | undefined
    },
    get listProps(): string[] {
      const { collectionSpec } = dbTool
      const props = collectionSpec
        ? collectionSpec.columns.map((spec) => spec.prop)
        : []
      if (!props.includes('_id')) {
        props.unshift('_id')
      }
      return props
    },
    async getList(): Promise<void> {
      const { collectionSpec } = dbTool

      dbTool.records = []
      if (collectionSpec === undefined) {
        return
      }

      const sortField = collectionSpec.sortField || '_created desc'
      const [field, direction] = sortField.split(' ')
      const sorter = makeSorter(
        (obj: any) => [String(obj[field]).toLocaleLowerCase()],
        direction !== 'desc'
      )

      theSpinner.hidden = false

      const records = await getRecords(
        collectionSpec.name,
        collectionSpec.where,
        collectionSpec.limit || DEFAULT_LIMIT,
        '_created desc'
      )
      dbTool.tableColumns = collectionSpec.columns || null
      dbTool.records = records.sort(sorter)
      const filterElement = document.querySelector('crud-tool xin-filter') as
        | FilterBuilder
        | undefined
      if (filterElement !== undefined && collectionSpec.filter !== undefined) {
        // FIXME hack, waiting for filter columns to be available
        setTimeout(() => {
          filterElement.state = collectionSpec.filter!
        }, 100)
      }
      theSpinner.hidden = true

      if (records.length === 0) {
        success('0 records found')
      }
    },
    getJSON(obj: any, stripQuotes = false) {
      const w = window.open() as Window
      const pre = w.document.createElement('pre')
      const json = JSON.stringify(obj, null, 2)
      pre.innerText = stripQuotes ? json.replace(/"(\w+)":/g, '$1:') : json
      w.document.body.append(pre)
    },
    filter: null as ((array: any[]) => any[]) | null,
    _dirty: false as false | string,
    async revert(event: Event) {
      event?.preventDefault()
      const { _collection, _id } = dbTool.object
      dbTool.object = await getRecord(_collection, _id)
    },
    async save(event: Event) {
      event.preventDefault()
      const lastChange = dbTool._dirty
      dbTool._dirty = false
      const collection = dbTool.collections.find(
        (c) => c.name === dbTool.object._collection || dbTool.collection
      )
      if (collection === undefined) {
        error(`save failed, ${dbTool.collection} not a recognized collection`)
        return
      }
      const { uniqueFields } = collection
      if (uniqueFields !== undefined) {
        for (const field of uniqueFields) {
          // check if the field value is unique
          const value = dbTool.object[field]

          if (value === undefined) {
            error(`Save failed. ${field} must exist and be unique`)
            return
          }

          const existing = await getRecords(dbTool.collection, {
            field,
            operator: '==',
            value,
          })
          if (existing.find((e) => e._id !== dbTool.object._id) !== undefined) {
            error(`Save not allowed. This record's ${field} is not unique.`)
            return
          }
        }
      }

      console.log('saving', dbTool.object)

      setRecord(dbTool.collection, dbTool.object)
        .then(() => {
          success(
            `${dbTool.collection}/${dbTool.object._id as string} was saved`
          )

          void dbTool.getList()
        })
        .catch((err) => {
          error(
            `Error saving ${dbTool.collection}/${dbTool.object._id as string}`,
            err
          )
          dbTool._dirty = lastChange
        })
    },
    create() {
      const object = { _id: randomID(), _collection: dbTool.collection }
      const crudTool = document.querySelector('crud-tool') as CrudTool
      crudTool.editRecord(object)
    },
    close() {
      dbTool.object = undefined
      const crudTool = document.querySelector('crud-tool')
      if (crudTool instanceof CrudTool) {
        if (
          dbTool._dirty === false ||
          confirm('You have unsaved changes, close the record anyway?')
        ) {
          // TODO hacky, fix this
          crudTool.removeEditor()
          crudTool.record = ''
          dbTool._dirty = false
          pushSearch()
        }
      }
    },
    async delete(event: Event) {
      event.preventDefault()
      if (!confirm('Are you SURE you want to delete this record?!')) {
        return
      }
      if (dbTool.object._created === undefined) {
        dbTool._dirty = false
        dbTool.close()
        return
      }
      try {
        await deleteRecord(dbTool.object)
        success(
          `${dbTool.collection}/${dbTool.object._id as string} was deleted`
        )
        dbTool.object = undefined
        dbTool._dirty = false
        dbTool.close()
        await dbTool.getList()
      } catch (err) {
        error(
          `Error deleting ${dbTool.collection}/${dbTool.object._id as string}`,
          err
        )
      }
    },
  },
})

setInterval(() => {
  const crud = document.querySelector('crud-tool')
  const table = document.querySelector('xin-table') as DataTable
  if (crud && table) {
    dbTool.stats = table.visibleRows.length + '/' + dbTool.records.length
  }
}, 500)

observe(
  /^dbTool\.object\b./,
  debounce(() => {
    dbTool._dirty = new Date().toISOString()
  })
)

const { div, button, span, form, h3 } = elements

const toolbar = (): HTMLDivElement =>
  div(
    {
      class: 'toolbar',
      style: { flexWrap: 'nowrap', background: vars.panelBg },
    },
    h3(
      { class: 'no-margin text-nowrap elastic' },
      span({ bindText: 'dbTool.object._collection' }),
      '/',
      span({ bindText: 'dbTool.object._id' })
    ),
    span({ class: 'elastic' }),
    button('Save', {
      part: 'save',
      class: 'default no-drag',
      bindEnabled: 'dbTool._dirty',
      onClick: 'dbTool.save',
    }),
    button(
      { title: 'Delete', class: 'danger no-drag', onClick: 'dbTool.delete' },
      icons.trash()
    ),
    button(
      { title: 'Close', onClick: 'dbTool.close', class: 'no-drag' },
      icons.x()
    )
  )

const editorFloatStyle = {
  top: '20%',
  right: '10px',
  alignItems: 'stretch',
  height: '60%',
  minWidth: '350px',
  overflow: 'hidden',
}

const editor = (): XinFloat =>
  xinFloat(
    { class: 'column', style: editorFloatStyle, drag: true },
    toolbar(),
    form({
      class: 'column no-drag',
      style: {
        overflowY: 'scroll',
        alignItems: 'stretch',
        padding: vars.spacing,
      },
      bindObject: dbTool.object,
      onSubmit(event: Event) {
        event.preventDefault()
        event.stopPropagation()
      },
    }),
    xinSizer({ class: 'no-drag' })
  )

const spinnerText = span('loading…')
const theSpinner = loadingSpinner(
  {
    hidden: true,
    style: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  spinnerText
)

// TODO refactor into lib
const getParam = (param: string): string => {
  const params = new URLSearchParams(window.location.search)
  const value = params.get(param)
  return typeof value !== 'string' ? '' : value
}

function pushSearch(): void {
  const collection = dbTool.collection
  const record = dbTool.object !== undefined ? dbTool.object._id : ''
  const fb = (document.querySelector('crud-tool') as any).parts.filter
  const query = fb instanceof HTMLInputElement ? fb.value : ''

  if (
    getParam('c') === collection &&
    getParam('id') === record &&
    getParam('q') === query
  ) {
    return
  }

  let search
  if (collection === '') {
    search = ''
  } else if (record === '') {
    search = `?c=${collection}`
  } else {
    search = `?c=${collection}&id=${record as string}`
  }
  if (search !== '' && query !== '') {
    search += `&q=${query}`
  }
  if (window.location.search !== search) {
    const href = `${window.location.pathname}${search}`
    window.history.pushState(href, '', href)
  }
}

export class CrudTool extends WebComponent {
  record = ''
  query = ''

  private _collections: CollectionSpec[] = []

  set collections(collections: CollectionSpec[]) {
    this._collections = collections
    dbTool.collections = collections
    this.queueRender()
  }

  get collections(): CollectionSpec[] {
    return this._collections
  }

  get descriptionKey(): string {
    const collection = this.collections.find(
      (collection) => collection.name === dbTool.collection
    )
    return stringFallback(collection?.descriptionKey, 'description')
  }

  toggleProduction = (event: Event): void => {
    let message = 'switch to production'
    const inputElement = event.target as HTMLInputElement
    if (!inputElement.checked) {
      message = 'switch to emulation'
    }
    if (window.confirm(message)) {
      if (inputElement.checked) {
        localStorage.setItem('use-prod', 'production')
      } else {
        localStorage.removeItem('use-prod')
      }
      window.location.pathname = '/'
    } else {
      inputElement.checked = !inputElement.checked
    }
  }

  private collectionPromise?: Promise<any>
  pickCollection = async (
    event: Event | string,
    recordId?: string | null,
    query?: string
  ): Promise<void> => {
    if (recordId === null) {
      recordId = ''
    }

    const { filter, collections } = this.parts as {
      filter: FilterBuilder
      collections: XinSelect
    }
    const newCollection =
      typeof event === 'string'
        ? event
        : event.target instanceof XinSelect
        ? (event.target as XinSelect).value!
        : dbTool.collection

    const collectionChanged = newCollection !== dbTool.collection
    if (collectionChanged || this.collectionPromise === undefined) {
      this.loaded = true
      dbTool.collection = newCollection
      dbTool.records = []
      dbTool.object = undefined
      this.removeEditor()
      if (dbTool.collection === '') {
        return
      }
      filter.value = query !== undefined ? query : ''

      if (collections.value !== newCollection) {
        collections.value = newCollection
      }
      dbTool.getList()
      if (typeof recordId === 'string') {
        void this.showRecord(dbTool.collection as string, recordId)
      } else {
        pushSearch()
      }
    } else if (typeof recordId === 'string') {
      void this.collectionPromise.then(() => {
        void this.showRecord(dbTool.collection as string, recordId as string)
      })
    } else {
      dbTool.object = undefined
      this.removeEditor()
      pushSearch()
    }
  }

  get collectionsOptions(): SelectOptions {
    const { parts } = this
    return [
      ...dbTool.collections.map((collectionSpec) => collectionSpec.name),
      null,
      {
        icon: 'database',
        caption: 'Deep Search…',
        value() {
          DeepSearch.toggle(true, (_, description) => {
            ;(parts.collections as XinSelect).value = description
          })
          return ''
        },
      },
    ]
  }

  content = (): HTMLDivElement =>
    div(
      {
        style: {
          height: `100%`,
          display: 'flex',
          flexDirection: 'column',
        },
      },
      div(
        { class: 'toolbar primary' },
        xinSelect({
          title: 'collection',
          part: 'collections',
          editable: false,
          options: this.collectionsOptions,
          onAction: this.pickCollection,
        }),
        span({ class: 'spacer' }),
        span({ bindText: 'dbTool.stats' }),
        span({ class: 'spacer' }),
        filterBuilder({
          class: 'elastic',
          part: 'filter',
          style: {
            borderRadius: '99px',
          },
          bindFilterFields: 'dbTool.tableColumns',
          async onChange(event: Event) {
            if (event.target instanceof HTMLInputElement) {
              return
            }
            // FIXME update q parameter but only when necessary
            // pushSearch()
            const { filter } = event.target as FilterBuilder
            const testBusinesses = await app.testBusinesses()
            dbTool.filter = (objects: any[]): any[] => {
              if (!app.isTestDataVisible) {
                if (dbTool.collection === 'business-profile') {
                  objects = objects.filter(
                    (obj: any) => !testBusinesses.ids.includes(obj._id)
                  )
                } else {
                  objects = objects.filter((obj: any) =>
                    obj.businessId !== undefined
                      ? !testBusinesses.ids.includes(obj.businessId)
                      : !testBusinesses.names.includes(obj.businessName)
                  )
                }
              }
              return filter(objects)
            }
          },
        }),
        span({ class: 'spacer' }),

        button(
          {
            onClick(event) {
              popMenu({
                target: event.target as HTMLElement,
                menuItems: [
                  {
                    icon: 'filePlus',
                    caption: `Create new "${dbTool.collection}" record`,
                    action: dbTool.create,
                    enabled(): boolean {
                      return !!dbTool.collection
                    },
                  },
                  null,
                  {
                    icon: 'downloadCloud',
                    caption: `Export Records...`,
                    action: dbTool.export,
                    enabled(): boolean {
                      return !!dbTool.visibleRecords().length
                    },
                  },
                ],
              })
            },
          },
          icons.chevronDown()
        )
      ),
      div(
        {
          class: 'row',
          part: 'body',
          style: { overflow: 'hidden', gap: 0, flex: '1 1 auto' },
        },
        dataTable({
          part: 'table',
          style: { flex: '1 1 200px', width: '200px', overflow: 'hidden' },
          bindDataTableFilter: 'dbTool.filter',
          bindDataTable: 'dbTool.records',
          bindDataTableColumns: 'dbTool.tableColumns',
          bindShow: 'dbTool.records.length',
          onClick: this.showRecord,
        })
      ),
      theSpinner
    )

  constructor() {
    super()
    this.initAttributes('collection', 'query', 'record')
  }

  handleUrl = (): void => {
    this.pickCollection(getParam('c'), getParam('id'), getParam('q'))
  }

  warnSaveChanges = (): boolean => {
    if (
      dbTool._dirty === false ||
      confirm('You have unsaved changes, do you wish to proceed?')
    ) {
      return true
    }
    return false
  }

  connectedCallback(): void {
    super.connectedCallback()

    console.log('connected crud')
    window.addEventListener('popstate', this.handleUrl)
    this.pickCollection(getParam('c'), getParam('id'), getParam('q'))
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    window.removeEventListener('popstate', this.handleUrl)
  }

  removeEditor = (): void => {
    const { body } = this.parts
    if (body.children.length > 1) {
      body.children[1].remove()
    }
  }

  editRecord = (object: any): void => {
    this.removeEditor()
    console.log('editing', object)

    if (object !== undefined) {
      this.record = object._id
      dbTool.object = object
      const { body } = this.parts
      const { _collection } = object
      const collection = this.collections.find(
        (collection) => collection.name === _collection
      )
      if (collection?.editor !== undefined) {
        const editorInstance = collection.editor({
          value: object,
          class: 'no-drag',
        })
        if (editorInstance instanceof HTMLElement) {
          body.append(
            xinFloat(
              { drag: 'true', style: editorFloatStyle },
              toolbar(),
              editorInstance,
              xinSizer({ class: 'no-drag' })
            )
          )
        }
      } else {
        body.append(editor())
      }
    } else {
      this.record = ''
      dbTool.object = undefined
      dbTool._dirty = false
    }
  }

  showRecord = async (
    event: Event | string | undefined,
    recordId = ''
  ): Promise<void> => {
    let object
    if (dbTool._dirty !== false) {
      if (!confirm('There are unsaved changes, do you wish to proceed?')) {
        this.editRecord(dbTool.object)
        return
      }
    }
    if (typeof event === 'string' && event !== '' && recordId !== '') {
      object = await getRecord(event, recordId as string | number)
    } else if (event instanceof Event) {
      const { _id, _collection } = getListItem(event.target as HTMLElement)
      object = await getRecord(_collection, _id)
    } else {
      return
    }

    dbTool._dirty = false
    this.editRecord(object)
    // eslint too stupid to realize body.children[1] may be undefined
    pushSearch()
  }
}

export const crudTool = CrudTool.elementCreator({ tag: 'crud-tool' })
