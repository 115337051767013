import {
  xin,
  Component as WebComponent,
  elements,
  vars,
  ElementProps,
  xinValue,
} from 'xinjs'
import { icons, xinFloat, xinSizer } from 'xinjs-ui'
import { success, error } from '../notifications'
import { Message } from '../mocks/message'
import { Case } from '../mocks/case'

import { service } from '../firebase'
import { stringFallback } from '../fallback'

const {
  h3,
  details,
  summary,
  label,
  span,
  input,
  div,
  a,
  p,
  br,
  button,
  xinSlot,
} = elements

const messageEditorStyle: ElementProps = {
  style: {
    width: '50em',
    minWidth: '300px',
    maxHeight: `calc(100% - ${vars.toolbarHeight})`,
    minHeight: '300px',
    position: 'fixed',
    left: vars.spacing,
    bottom: vars.spacing,
    alignItems: 'stretch',
    padding: vars.spacing50,
  },
}

const labelStyle: ElementProps = {
  style: {
    display: 'flex',
    flexDirection: 'row',
    gap: vars.spacing,
    padding: 0,
  },
}

const labelCaptionStyle: ElementProps = {
  style: {
    lineHeight: vars.toolbarHeight,
    flex: '0 0 100px',
    textAlign: 'right',
    alignItems: 'center',
  },
}

type MessageHandler = (message: Message) => null

export class MessageEditor extends WebComponent {
  onSend: MessageHandler | null = null
  // initialMessageData: Message | null = null
  private isNeedsSubject = true

  private _message?: Message
  get value(): Message | null {
    const { _message } = this
    if (_message === undefined) {
      return null
    }
    const { to, cc, bcc, html, subject } = this.parts as {
      [key: string]: HTMLInputElement
    }
    _message.to = to.value
    _message.cc = cc.value
    _message.bcc = bcc.value
    _message.from = (xin.app as any).user.email.replace(
      /@nonono.com/,
      '@justsay.nonono.com'
    )
    _message.subject = subject.textContent != null ? subject.textContent : ''
    _message.html = html.innerHTML
    _message.sendEmail = to.value.match(/@(justsay.)?nonono.com$/) === null
    return _message
  }

  set value(newMessage: Message | undefined | null) {
    if (newMessage === undefined || newMessage === null) {
      return
    }
    if (this._message === xinValue(newMessage)) {
      return
    }
    this._message = xinValue(newMessage)
    console.log(this._message)
    const { to, cc, bcc, html, subject } = this.parts as {
      [key: string]: HTMLInputElement
    }
    to.value = newMessage.to
    cc.value = stringFallback(newMessage.cc)
    bcc.value = stringFallback(newMessage.bcc)
    html.innerHTML = stringFallback(newMessage.html)
    this.isNeedsSubject =
      newMessage.subject === undefined || newMessage.subject === ''
    subject.value = stringFallback(newMessage.subject)
    this.queueRender()
  }

  handleInput = (event: KeyboardEvent): void => {
    const target = event.target as HTMLElement
    const text = target.innerText != null ? target.innerText : ''
    if (!this.isNeedsSubject) {
      return
    }
    const titleElement = target.parentElement?.querySelector('h3')
    if (titleElement != null) {
      titleElement.textContent = text.split(/[\n.]/)[0]
    }
    if (event.metaKey && event.key === 'Enter') {
      this.send().catch(() => {})
    }
  }

  send = async (): Promise<void> => {
    const { subject, send, signature } = this.parts as {
      [key: string]: HTMLInputElement
    }

    if (send.disabled) {
      return
    }
    send.disabled = true

    if (subject.textContent == null || subject.textContent.trim() === '') {
      error('Cannot send email without a subject line')
      return
    }

    const _case = xin['inbox.currentCase'] as Case | undefined
    if (_case !== undefined) {
      const caseRef = `Ref #${_case._id}`
      if (!subject.textContent.endsWith(caseRef)) {
        subject.textContent =
          subject.textContent.replace(/#\w+/, '') + ` ${caseRef}`
      }
    }

    const message: Message = this.value as Message
    // if this is send via email it will be overridden later
    message._created = new Date().toISOString()
    const signatureClone = signature.cloneNode(true) as Element
    // remove binding classes
    ;[...signatureClone.querySelectorAll('.-xin-data')].forEach((elt) =>
      elt.classList.remove('-xin-data')
    )
    message.html = `${stringFallback(message.html)}\n<br>\n<br>\n${
      signatureClone.innerHTML
    }`

    const id = xin['inbox.currentCase._id'] as string | undefined
    if (id !== undefined) {
      message.caseId = id
    }

    try {
      await service.message.post(message)
      ;(xin.inbox as any).currentMessage = undefined
      ;(xin.inbox as any).currentMessages.unshift(message)
      // TODO figure out why this doesn't get called
      if (typeof this.onSend === 'function') {
        this.onSend(message)
      }
      success('Message sent!')
    } catch (e) {
      error(`Message failed to send.\n\n${e as string}`)
    }
    send.disabled = false
  }

  content = (): HTMLElement =>
    xinFloat(
      { drag: true, class: 'column', ...messageEditorStyle },
      details(
        {
          class: 'column primary',
          style: {
            padding: vars.spacing50,
            margin: vars.spacing_50,
            marginBottom: 0,
            alignItems: 'stretch',
            alignContent: 'stretch',
          },
        },
        summary(
          { class: 'row' },
          // TODO replace with disclose component
          span(
            {
              class: 'disclose no-drag',
              onClick(event) {
                const details = (event.target as HTMLElement).closest(
                  'details'
                ) as HTMLDetailsElement
                details.open = !details.open
                event.stopPropagation()
                event.preventDefault()
              },
            },
            icons.chevronRight()
          ),
          label(
            { class: 'elastic' },
            labelStyle,
            span(
              labelCaptionStyle,
              { class: 'row' },
              span({ class: 'elastic' }, 'Message To')
            ),
            input({
              class: 'elastic no-drag',
              part: 'to',
              placeholder: 'email addresses',
              bindValue: 'inbox.currentMessage.to',
            })
          ),
          xinSlot({ class: 'no-drag', name: 'topbar-widgets' }),
          button(
            { part: 'send', class: 'default no-drag', onClick: this.send },
            'Send'
          ),
          button(
            {
              class: 'no-drag',
              onClick() {
                ;(xin.inbox as any).currentMessage = undefined
              },
            },
            icons.x()
          )
        ),
        label(
          labelStyle,
          span('CC', labelCaptionStyle),
          input({
            class: 'elastic no-drag',
            part: 'cc',
            placeholder: 'email addresses',
          })
        ),
        label(
          labelStyle,
          span('BCC', labelCaptionStyle),
          input({
            class: 'elastic no-drag',
            part: 'bcc',
            placeholder: 'email addresses',
          })
        )
      ),
      h3({
        part: 'subject',
        class: 'no-drag',
        tabindex: 0,
        contenteditable: true,
        bindEditable: 'inbox.currentMessage.subject',
        style: { content: ' ', width: '100%', margin: 0 },
      }),
      div(
        {
          part: 'html',
          class: 'no-drag elastic',
          tabindex: 0,
          contenteditable: true,
          style: { content: ' ', width: '100%', overflow: 'hidden auto' },
          bindEditable: 'inbox.currentMessage.html',
          dataStyled: true,
          onKeyup: this.handleInput,
        },
        p()
      ),
      div(
        { class: 'no-drag', part: 'signature' },
        span('Best regards,'),
        br(),
        // span({ bindText: 'app.user.displayName' }), br(),
        span(a('No No No', { href: 'https://nonono.com', tabindex: -1 }))
      ),
      xinSizer({ class: 'no-drag', style: { zIndex: 10 } })
    )
}

export const messageEditor = MessageEditor.elementCreator({
  tag: 'message-editor',
})
