import {
  menu_default
} from "../../chunks/chunk.LRR6WVQZ.js";
import "../../chunks/chunk.2SLLA5P2.js";
import "../../chunks/chunk.VVA35HTY.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.RVOOE4AQ.js";
import "../../chunks/chunk.IFDWM6P4.js";
export {
  menu_default as default
};
