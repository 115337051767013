import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { xinFloat, icons } from 'xinjs-ui'
import { getRecord, setRecord } from '../firebase'
import { error, success } from '../notifications'

const { h4, div, button, label, span, input } = elements

class GkEditor extends WebComponent {
  config?: {
    [key: string]: string
  }

  addSetting = () => {
    const settingName = prompt('Name the new setting')
    if (settingName != null && settingName.trim() !== '') {
      if (this.config === undefined) {
        this.config = {}
      }
      this.config[settingName] = '0.5'
      this.queueRender()
    }
  }

  updateSetting = (event: Event) => {
    const input = (event.target as HTMLElement).closest(
      'input'
    ) as HTMLInputElement
    const key = input.dataset.key as string
    if (this.config === undefined) {
      this.config = {}
    }
    this.config[key] = input.value
  }

  removeSetting = (event: Event) => {
    const button = (event.target as HTMLElement).closest(
      'button'
    ) as HTMLButtonElement
    const key = button.dataset.key as string
    if (
      confirm(
        `Remove gatekeeper ${key}? Please be very sure no tests against this key are live!`
      )
    ) {
      delete this.config![key]
      this.queueRender()
    }
  }

  saveSettings = async () => {
    if (this.config === undefined) {
      error('No config to save')
      return
    }
    if (confirm('Save settings to cloud config?')) {
      try {
        await setRecord('config', this.config, 'gk-config')
        success('Gatekeeper Config Saved')
      } catch (e) {
        error('Save failed, see console for details', e)
      }
    }
  }

  close = () => {
    this.remove()
  }

  content = () =>
    xinFloat(
      {
        class: 'compact',
        drag: true,
        style: {
          bottom: '10px',
          left: '10px',
          width: '300px',
          /*
        maxWidth: '100%',
        minHeight: '200px',
        
        boxShadow: vars.zShadow,
        borderRadius: vars.roundedRadius,
        overflow: 'hidden',
        */
        },
      },
      h4('Gatekeeper Editor', {
        class: 'primary',
        style: { textAlign: 'center', padding: vars.spacing50 },
      }),
      button(
        {
          class: 'transparent close-float no-drag',
          onClick: this.close,
        },
        icons.x()
      ),
      div(
        { part: 'tools', class: 'toolbar no-drag' },
        button(icons.plus(), {
          dataTooltip: 'Add Setting…',
          onClick: this.addSetting,
        }),
        span({ class: 'elastic' }),
        button(icons.upload(), {
          dataTooltip: 'Save Settings',
          onClick: this.saveSettings,
        })
      ),
      div({
        part: 'settings',
        class: 'no-drag',
        style: { padding: vars.spacing },
      })
    )

  connectedCallback(): void {
    super.connectedCallback()

    getRecord('config', 'gk-config').then((config) => {
      this.config = config
      this.queueRender()
    })
  }

  render() {
    const { config } = this
    const { settings } = this.parts

    if (config === undefined) {
      settings.textContent = 'no settings yet'
      return
    }

    settings.textContent = ''

    settings.append(
      ...Object.keys(config)
        .filter((config) => !config.startsWith('_'))
        .map((gkSetting) =>
          label(
            { class: 'row', style: { alignItems: 'center' } },
            span(gkSetting, { style: { flex: '1' } }),
            input({
              value: config[gkSetting] as string,
              style: { flex: '1', minWidth: 0 },
              dataKey: gkSetting,
              onChange: this.updateSetting,
            }),
            button(icons.trash(), {
              dataTooltip: 'Delete Key',
              dataKey: gkSetting,
              onClick: this.removeSetting,
            })
          )
        )
    )
  }
}

export const gkEditor = GkEditor.elementCreator({
  tag: 'gk-editor',
}) as ElementCreator<GkEditor>
