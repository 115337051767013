import {
  debounce,
  elements,
  xinProxy,
  vars,
  ElementProps,
  Component as WebComponent,
  ElementCreator,
} from 'xinjs'
import { service, logEvent } from '../firebase'
import { error } from '../notifications'
import { cardView } from './card-view'
import { BrandProfile } from '../mocks/business-profile'
import { complaintButton } from './complaint-button'
import { makeSorter, SortCallback } from '../sort'
import { icons, popFloat, positionFloat, XinFloat, menu } from 'xinjs-ui'
import { loadRoute } from '../routes'

const isProd = ['nonono.com', 'new.nonono.com'].includes(window.location.host)

const sortByResolvedThenName = makeSorter<BrandProfile>(
  (business: BrandProfile) => {
    const { _sort, reviews } = business
    return [
      reviews === undefined || reviews.count === 0 ? 1 : 0,
      _sort as string,
    ]
  }
)

const makeSortByFilter = (filter: string): SortCallback<BrandProfile> => {
  return makeSorter<BrandProfile>((business: BrandProfile) => {
    const { _sort, reviews } = business as BrandProfile & { _sort: string }
    return [
      reviews === undefined || reviews.count === 0 ? 1 : 0,
      _sort.startsWith(filter) ? -1 : 1,
      _sort,
    ]
  })
}

const { brands } = xinProxy({
  brands: {
    all: [] as BrandProfile[],
    lastFilter: ' ',
    filter: '',
    found: [] as BrandProfile[],
    cardsVisibleOnFilter: 24,
    cardsVisibleIfNoFilter: 24,
  },
})

const simplify = (s: string): string => {
  return s
    .trim()
    .replace(/[^\w\s]/g, '')
    .toLocaleLowerCase()
}

const _filterBusinesses = (filter: string): void => {
  if (filter !== '') {
    filter = simplify(filter)
    logEvent('search', {
      search_term: filter,
    })
    brands.found = brands.all
      .filter((profile) => {
        if (profile.status === 'draft') {
          return false
        }
        if (typeof profile.name !== 'string') {
          console.error('bad profile', profile)
          return false
        }
        return simplify(profile.name).includes(filter)
      })
      .sort(makeSortByFilter(filter))
      .slice(0, brands.cardsVisibleOnFilter)
  } else {
    brands.found = brands.all.slice(0, brands.cardsVisibleIfNoFilter)
  }
}

const filterBusinesses = debounce(
  (filter: string, forceRefresh: boolean = false) => {
    if (filter === brands.lastFilter && !forceRefresh) {
      return
    }
    _filterBusinesses(filter)
    brands.lastFilter = filter
  },
  500
)

service.list
  .get({ id: 'business-search' })
  .then((list: { records: any[] }) => {
    brands.all = (list.records as unknown as BrandProfile[])
      .filter((profile) => {
        const isOK = isProd
          ? profile.isTestData !== true &&
            profile.path != null &&
            typeof profile.name === 'string'
          : profile.path != null && typeof profile.name === 'string'

        if (isOK) {
          profile._sort = (
            (profile.name.toLocaleLowerCase() + '~z').match(
              /[a-z~].*/
            ) as string[]
          )[0]
        }
        return isOK
      })
      // eslint-disable-next-line
      .sort(sortByResolvedThenName)
    filterBusinesses(brands.filter, true)
  })
  .catch(error)

const { h1, h2, input, div, span, template, a, slRating } = elements

class FindBusiness extends WebComponent {
  menuFloat: XinFloat | null = null
  placeholder = 'Get a response from a business FAST…'

  closeMenu = () => {
    const { filter } = this.parts as { filter: HTMLInputElement }
    if (this.menuFloat) {
      if (filter.value) {
        filter.value = ''
        filter.blur()
      }
      this.menuFloat.remove()
      this.menuFloat = null
    }
  }

  doSearch = async () => {
    const { filter } = this.parts as { filter: HTMLInputElement }
    const search_term = filter.value.toLocaleLowerCase()
    _filterBusinesses(search_term)
    const menuItems = brands.found.map((brand: BrandProfile) => ({
      caption: brand.name,
      action: () => {
        this.closeMenu()
        logEvent('view_search_results', {
          search_term,
          item_viewed: brand.name,
        })
        loadRoute(`/${brand.path}-customer-service/`)
      },
    }))
    // FIXME add keyboard navigation support
    if (this.menuFloat && document.body.contains(this.menuFloat)) {
      if (filter.value.trim() === '') {
        this.closeMenu()
        return
      }
      this.menuFloat.textContent = ''
      this.menuFloat.append(menu({ target: this, menuItems }))
      positionFloat(this.menuFloat, this, 's')
    } else {
      this.menuFloat = popFloat({
        target: this,
        position: 's',
        content: menu({ target: this, menuItems }),
      })
      this.menuFloat.remainOnScroll = 'remove'
      Object.assign(this.menuFloat.style, {
        width: '280px',
      })
    }
  }

  content = () =>
    div(
      { style: { position: 'relative' } },
      icons.search(),
      input({
        part: 'filter',
        type: 'search',
        onInput: this.doSearch,
      })
    )

  constructor() {
    super()
    this.initAttributes('placeholder')
  }

  connectedCallback() {
    super.connectedCallback()

    document.querySelector('.xin-menu')?.remove()
  }

  render() {
    super.render()

    this.parts.filter.setAttribute('placeholder', this.placeholder)
  }
}

export const findBusiness = FindBusiness.elementCreator({
  tag: 'find-business',
  styleSpec: {
    ':host': {
      display: 'block',
      position: 'relative',
      maxWidth: '25em',
      margin: 'auto',
    },
    ':host svg': {
      position: 'absolute',
      '--text-color': '#aaa',
      top: '50%',
      left: vars.spacing,
      transform: 'translateY(-50%)',
    },
    ':host input[type="search"]': {
      width: '100%',
      borderRadius: '3px',
      paddingLeft: vars.spacing275,
      // textAlign: 'center',
    },
  },
}) as ElementCreator<FindBusiness>

export const businessSearch = (
  elementProps: ElementProps = {}
): HTMLDivElement => {
  const { cardsVisibleIfNoFilter, cardsVisibleOnFilter } = elementProps
  brands.cardsVisibleIfNoFilter =
    cardsVisibleIfNoFilter !== undefined ? cardsVisibleIfNoFilter : 24
  brands.cardsVisibleOnFilter =
    cardsVisibleOnFilter !== undefined ? cardsVisibleOnFilter : 24
  _filterBusinesses(brands.lastFilter)
  return div(
    { class: 'business-search' },
    Object.assign(
      {
        class: 'column business-search',
        style: {
          maxWidth: '100%',
          maxHeight: '100%',
          padding: 0,
        },
      },
      elementProps
    ),
    (elementProps.isEmbedded ? h2 : h1)(
      {
        part: 'heading',
        style: {
          margin: `${vars.spacing200} ${vars.spacing} ${vars.spacing}`,
          textAlign: 'center',
        },
      },
      'Businesses we have helped customers with…'
    ),
    div(
      { class: 'row', style: { background: 'transparent' } },
      span({ class: 'elastic' }),
      input({
        style: {
          width: '25em',
          maxWidth: 'calc(100% - 6em)',
          textAlign: 'center',
        },
        class: 'elastic',
        type: 'search',
        placeholder: 'filter by business name',
        bindValue: 'brands.filter',
        onInput(event: Event) {
          const target = event.target as HTMLInputElement
          filterBusinesses(target.value)
        },
      }),
      span({ class: 'elastic' })
    ),
    div(
      {
        style: {
          content: ' ',
          padding: vars.spacing50,
          textAlign: 'center',
          width: '100%',
        },
        bindList: {
          value: 'brands.found',
          idPath: 'path',
        },
      },
      template(
        cardView(
          {
            class: 'column appearance',
            style: {
              borderRadius: vars.spacing50,
              display: 'inline-grid',
              width: '300px',
              height: '210px',
              gridTemplateRows: '32px 20px 20px 32px 60px',
              gap: '4px',
              margin: vars.spacing50,
              justifyItems: 'center',
            },
          },

          h2({
            class: 'text-nowrap',
            style: { margin: 0, maxWidth: '100%' },
            bindText: '^.name',
          }),
          div(span({ bindCount: '^.reviews.count', dataNoun: 'review' })),
          slRating({
            bindAveScore: '^',
            apply(elt) {
              elt.setAttribute('readonly', '')
            },
          }),
          a(
            {
              bindBusinessLink: '^.path',
              onClick(event: Event) {
                const target = event.target as HTMLElement
                const heading = target.parentElement!.querySelector(
                  'h2'
                ) as HTMLHeadingElement
                logEvent('view_search_results', {
                  search_term: brands.filter.toLocaleLowerCase(),
                  item_viewed: heading.textContent,
                })
              },
            },
            'See business profile…'
          ),
          complaintButton({
            bindBusinessPath: '^.path',
          })
        )
      )
    )
  )
}
