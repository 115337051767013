import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { icons } from 'xinjs-ui'

const { button } = elements

class CloseButton extends WebComponent {
  target = ''
  hide = false

  styleNode = WebComponent.StyleNode({
    ':host': {
      display: 'inline-block',
      '--close-button-size': '24px',
      '--close-button-color': vars.textColor,
      '--close-button-bg': 'transparent',
    },
    ':host button': {
      height: vars.closeButtonSize,
      width: vars.closeButtonSize,
      lineHeight: vars.closeButtonSize,
      background: vars.closeButtonBg,
      textAlign: 'center',
      border: 0,
      padding: 0,
    },
    ':host svg': {
      verticalAlign: 'middle',
      fill: vars.closeButtonColor,
      width: vars.closeButtonSize67,
      height: vars.closeButtonSize67,
      opacity: 0.75,
      transition: '0.2s ease-out',
    },
    ':host button:hover svg': {
      opacity: 1,
    },
    ':host button:active svg': {
      transform: 'scale(1.33)',
    },
  })

  content = () => button({ part: 'button' }, icons.x({ part: 'icon' }))

  doClose = () => {
    const target = this.target ? this.closest(this.target) : this.parentElement
    if (target) {
      if (this.hide) {
        target.setAttribute('hidden', '')
      } else {
        target.remove()
      }
    }
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.parts.button.addEventListener('click', this.doClose)
  }
}

export const closeButton = CloseButton.elementCreator({
  tag: 'close-button',
}) as ElementCreator<CloseButton>
