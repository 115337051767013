import { Tagged } from './tags'

export interface ComplaintFormItem {
  question: string
  key: string
  type: 'string' | 'text' | 'pick-one' | 'pick-any'
}

export const defaultSuggestions = {
  nonono:
    "Save time and effort: escalate your complaint to {{name}}'s corporate office with our help.",
  diy: 'Try to contact {{name}} by yourself, the contact details are below.',
}

export interface Contact {
  description: string
  content: string
}

export const defaultContacts: Contact[] = []

// FIXME this shouldn't be needed once everything is using /business endpoint
export const complaintProcess = `## How the {{name}} complaint process works on nonono.com

Do you have a dispute about a product, service, or customer service? 

Are you having trouble reaching the complaint line at the {{name}} help center and need to contact the corporate office? 

Here's how you can escalate your issue:

1. [File a complaint with {{name}}](/{{path}}-complaint/) for \${{price}}.
2. We will publish your complaint, and escalate it to {{name}} corporate office complaints department.
3. Your satisfaction is guaranteed. If {{name}} doesn't reply within 72 hours, you'll receive a 100% refund.`

export interface BrandProfile extends Tagged {
  _id?: string
  version?: number
  mock?: boolean
  redirectToBusinessSlug?: string
  isTestData?: boolean
  isAutomaticallyEscalated?: boolean
  isSelfService?: boolean
  path: string
  name: string
  _sort?: string
  price: number
  status: 'unverified' | 'verified' | 'draft'
  suggestions: typeof defaultSuggestions
  escalationEmails?: string[]
  contacts: Contact[]
  complaintProcess: string
  title?: string
  description?: string
  extraText?: string
  reviews?: {
    count: number
    totalScore: number
    reviewIds: string[]
    wouldDoBusiness: number
    aveScore: string
    wdbaPct: string
  }
  complaintForm?: {
    prologue?: string
    paymentText?: string
    extraItems?: ComplaintFormItem[]
  }
  cases?: {
    caseIds: string[]
    count: number
    resolved: number
    resolvedIds: string[]
  }
  schema?: {
    name: string
    description: string
    url: string
    image: string
    address: {
      '@type': 'PostalAddress' | string
      addressLocality: string
      streetAddress: string
    }
    contactPoint: {
      contentType: 'customer service' | string
      email: 'none' | string
      telephone: 'none' | string
    }
  }
}

export const profile: BrandProfile = {
  mock: true,
  path: 'business-path',
  name: 'business name',
  price: 9,
  extraText: '',
  suggestions: defaultSuggestions,
  status: 'unverified',
  contacts: [],
  complaintProcess,
}
