import {
  Component as XinComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { icons } from 'xinjs-ui'

const { a, img, span } = elements

export class ComplaintButton extends XinComponent {
  brandName: string | undefined
  path: string | undefined
  tabindex: number | string | undefined = 0
  logo = false

  content = () =>
    a(
      {
        part: 'link',
        class: 'complaint button text-nowrap',
        href: '/business/',
        style: {
          padding: vars.spacing,
          margin: `${vars.spacing} 0`,
        },
      },
      this.logo
        ? img({
            src: '/images/logo-white.svg',
            alt: 'no no no logo',
            class: 'badge-focus',
            style: {
              marginRight: vars.spacing,
              marginBottom: '-5px',
            },
          })
        : {},
      span({ class: 'text-nowrap' }, 'Escalate your Complaint'),
      span({ class: 'text-nowrap', part: 'brand' }),
      icons.chevronRight({ style: { marginLeft: vars.spacing50 } })
    )

  constructor() {
    super()
    this.initAttributes('path', 'tabindex')
  }

  render(): void {
    super.render()
    const { brand, link } = this.parts
    if (this.brandName !== undefined) {
      brand.textContent = ` with ${this.brandName}`
    }
    link.setAttribute(
      'href',
      this.path === undefined ? '/business/' : `/${this.path}-complaint/`
    )
    link.setAttribute('tabindex', String(this.tabindex))
  }
}

export const complaintButton = ComplaintButton.elementCreator({
  tag: 'complaint-button',
  styleSpec: {
    ':host': {
      display: 'block',
      margin: `${vars.spacing} 0`,
    },
    ':host a.button:not(:hover):not(:active)': {
      maxWidth: '20em',
      boxShadow: 'none',
    },
  },
}) as ElementCreator<ComplaintButton>
