import { Component as WebComponent, elements, vars } from 'xinjs'
import { Review } from '../mocks/review'
import { cardView } from './card-view'
import { icons, markdownViewer } from 'xinjs-ui'
import { service } from '../firebase'

const { h2, h3, h4, a, slRating, div, span } = elements

class ReviewCard extends WebComponent {
  caseId = ''
  reviewId = ''
  review = null as Review | null
  hideCaseLink = false
  isEmbedded = false

  content = () =>
    cardView(
      {
        style: {
          padding: vars.spacing200,
          margin: `0 auto`,
          maxWidth: '30em',
        },
      },
      div(
        { class: 'row', style: { alignItems: 'center' } },
        (this.isEmbedded ? h3 : h2)({
          class: 'no-margin',
          part: 'displayName',
          style: { fontSize: vars.fontSize110 },
        }),
        span({ class: 'elastic' }),
        a(
          {
            dataTooltip: 'See original case',
            part: 'link',
          },
          span({ part: 'date' }),
          icons.link({
            style: { marginLeft: vars.spacing50 },
          })
        )
      ),
      slRating({
        apply(elt) {
          elt.setAttribute('readonly', '')
        },
        label: 'No No No’s score out of a possible five stars',
        hidden: true,
        part: 'ourRating',
        style: {
          display: 'block',
          marginTop: vars.spacing,
          marginBottom: vars.spacing50,
        },
      }),
      (this.isEmbedded ? h4 : h3)('Review of No No No', {
        part: 'ourRatingCaption',
        hidden: true,
        class: 'no-margin',
        style: { fontSize: vars.fontSize110 },
      }),
      markdownViewer({
        part: 'ourDetail',
        style: { display: 'block' },
        hidden: true,
      }),
      slRating({
        apply(elt) {
          elt.setAttribute('readonly', '')
        },
        label: 'Company’s resolution score out of a possible five stars',
        part: 'rating',
        style: {
          display: 'block',
          marginTop: vars.spacing,
          marginBottom: vars.spacing50,
          _symbolColorActive: vars.brandColor,
        },
      }),
      (this.isEmbedded ? h4 : h3)(
        { class: 'no-margin', style: { fontSize: vars.fontSize110 } },
        'Resolution review of ',
        span({ part: 'businessName' })
      ),
      markdownViewer({ part: 'detail', style: { display: 'block' } })
    )

  constructor() {
    super()
    this.initAttributes('caseId', 'hideCaseLink', 'isEmbedded')
  }

  update = (): void => {
    if (this.review == null) {
      return
    }
    this.caseId = this.review.caseId
    const {
      businessName,
      displayName,
      detail,
      rating,
      ourRatingCaption,
      ourRating,
      ourDetail,
      date,
      link,
    } = this.parts as { [key: string]: HTMLInputElement }
    link.setAttribute(
      'href',
      `/case/${
        this.review.caseSlug ? this.review.caseSlug : this.review.caseId
      }/`
    )
    link.addEventListener('click', () => {
      window.scroll(0, 0)
    })
    link.hidden = this.hideCaseLink
    displayName.textContent = this.review.displayName as string
    businessName.textContent = this.review.businessName as string
    date.textContent = new Date(this.review._created as string).toDateString()
    detail.value = this.review.detail
    rating.value = String(this.review.rating)
    if (
      this.review.ourRating !== undefined &&
      this.review.ourDetail !== undefined
    ) {
      ourRating.hidden = false
      ourRatingCaption.hidden = false
      ourRating.value = String(this.review.ourRating)
      ourDetail.hidden = false
      ourDetail.value = this.review.ourDetail
    }
    this.hidden = false
  }

  private loaded = false
  loadReview = async (): Promise<void> => {
    if (this.loaded) {
      return
    }
    this.loaded = true
    this.review = (await service.review.get({
      reviewId: String(this.reviewId),
    })) as Review
    this.update()
  }

  loadCase = async (): Promise<void> => {
    if (this.loaded) {
      return
    }
    this.loaded = true
    this.review = (await service.review.get({
      caseId: String(this.caseId),
    })) as Review
    this.update()
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.hidden = true
    if (this.review !== null) {
      this.update()
    } else if (this.reviewId !== '') {
      void this.loadReview()
    } else if (this.caseId !== '') {
      void this.loadCase()
    }
  }
}

export const reviewCard = ReviewCard.elementCreator({ tag: 'review-card' })
