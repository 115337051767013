import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'

const { div, script } = elements

interface DisqusGlobal extends Window {
  disqus_config: (this: any) => void
}

// based on (awful) code found here https://help.disqus.com/en/articles/1717112-universal-embed-code
class DisqusThread extends WebComponent {
  host = 'https://nonono.com/'
  // TODO support query parameters if appropriate

  content = () => [
    div({ id: 'disqus_thread' }),
    script({
      src: 'https://nox3.disqus.com/embed.js',
      dataTimestamp: String(Date.now()),
    }),
  ]

  constructor() {
    super()

    const { host } = this
    ;(window as unknown as DisqusGlobal).disqus_config = function (this: any) {
      const path = window.location.pathname.replace(/(^\/|\/$)/g, '')
      this.page.url = host + path
      this.page.identifer = path
    }
  }
}

export const disqusThread = DisqusThread.elementCreator({
  tag: 'disqus-thread',
  styleSpec: {
    ':host': {
      marginTop: vars.spacing,
      padding: `${vars.spacing} 0`,
      width: '100%',
      maxWidth: vars.readableWidth,
      display: 'block',
      margin: 'auto',
    },
  },
}) as ElementCreator<DisqusThread>
