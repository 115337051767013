import { snippetEditor } from './components/snippet-editor'
import { elements } from 'xinjs'
import { loadRoute } from './routes'
import { CollectionSpec } from './collection-spec'

const { span } = elements

// TODO make this more elegant when data-table gets fixed
const timestampCell =
  (prop = '_created') =>
  () => {
    return span({ class: 'td', bindDate: `^.${prop}` })
  }

const priceCell =
  (prop = 'price') =>
  () => {
    return span({
      class: 'td',
      style: { textAlign: 'right' },
      bindPrice: `^.${prop}`,
    })
  }

const tagCell = () => () => {
  return span({
    class: 'td',
    bindTags: `^.tags`,
  })
}

export const collections: CollectionSpec[] = [
  {
    name: 'privileges',
    description: 'who is allowed to do what',
    isSuper: true,
    sortField: 'isCSR desc',
    columns: [
      {
        name: '_id',
        prop: '_id',
        align: 'left',
        visible: false,
        width: 180,
      },
      {
        name: 'description',
        prop: 'description',
        align: 'left',
        visible: true,
        width: 350,
      },
      {
        name: 'email',
        prop: 'email',
        align: 'left',
        visible: true,
        width: 200,
      },
      {
        name: 'Sigin Provider',
        prop: 'signinProvider',
        align: 'left',
        visible: true,
        width: 150,
      },
      {
        name: 'is csr',
        prop: 'isCSR',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'is admin',
        prop: 'isAdmin',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'is super',
        prop: 'isSuper',
        align: 'right',
        visible: true,
        width: 75,
      },
    ],
  },
  // {name: 'business', description: 'internal information we have on a business'},
  {
    name: 'business-profile',
    descriptionKey: 'name',
    description: 'information we have on a business',
    uniqueFields: ['path'],
    sortField: 'name',
    limit: 1000,
    columns: [
      {
        name: 'name',
        prop: 'name',
        align: 'left',
        visible: true,
        width: 300,
      },
      {
        name: 'price',
        prop: 'price',
        align: 'right',
        visible: true,
        width: 100,
        dataCell: priceCell(),
      },
      {
        name: 'auto esc.',
        prop: 'isAutomaticallyEscalated',
        align: 'center',
        visible: true,
        width: 120,
      },
      {
        name: 'self-service',
        prop: 'isSelfService',
        align: 'center',
        visible: true,
        width: 120,
      },
    ],
  },
  {
    name: 'case',
    descriptionKey: 'title',
    description:
      'a complaint including initial submission and subsequently captured data',
    where: { field: 'isPaid', operator: '==', value: true },
    limit: 1000,
    columns: [
      {
        name: 'date',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
        dataCell: timestampCell(),
      },
      {
        name: 'business',
        prop: 'businessName',
        align: 'left',
        visible: true,
        width: 200,
      },
      {
        name: 'title',
        prop: 'title',
        align: 'left',
        visible: true,
        width: 300,
      },
      {
        name: 'email',
        prop: 'email',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'assigned to',
        prop: 'assignedUserId',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'price',
        prop: 'price',
        align: 'right',
        visible: true,
        width: 90,
      },
      {
        name: 'tags',
        prop: 'tags',
        align: 'left',
        visible: true,
        width: 150,
        dataCell: tagCell(),
      },
      {
        name: 'paid',
        prop: 'isPaid',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'escalated',
        prop: 'isEscalated',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'resolved',
        prop: 'isResolved',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'reviewed',
        prop: 'isReviewed',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'closed',
        prop: 'isClosed',
        align: 'right',
        visible: true,
        width: 75,
      },
    ],
  },
  {
    name: 'config',
    description: 'global settings',
    isSuper: true,
    columns: [
      {
        name: 'id',
        prop: '_id',
        visible: true,
        align: 'left',
        width: 300,
      },
      {
        name: '_modified',
        prop: '_modified',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: '_created',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
      },
    ],
  },
  {
    name: 'customer',
    descriptionKey: 'email',
    description: 'information we have about a given customer',
    columns: [
      {
        name: '_id',
        prop: '_id',
        align: 'right',
        visible: false,
        width: 180,
      },
      {
        name: 'Date',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
        dataCell: timestampCell(),
      },
      {
        name: 'email',
        prop: 'email',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'First Name',
        prop: 'nameFirst',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'Last Name',
        prop: 'nameLast',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'has case',
        prop: 'hasCase',
        align: 'right',
        visible: true,
        width: 75,
      },
      {
        name: 'is_active',
        prop: 'is_active',
        align: 'right',
        visible: false,
        width: 75,
      },
    ],
  },
  /*
  {
    name: 'form',
    description:
      'definition of a form that allows viewing or editing of data from one or more collections',
    columns: [
      {
        name: 'title',
        prop: 'title',
        visible: true,
        align: 'left',
        width: 300,
      },
      {
        name: 'created',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'modified',
        prop: '_modified',
        align: 'left',
        visible: true,
        width: 180,
      },
    ],
  },
  */
  {
    name: 'message',
    descriptionKey: 'subject',
    description:
      'messages that have been sent, including wholly internal, outgoing, and incoming messages',
    limit: 500,
    columns: [
      {
        name: 'date sent',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
        dataCell: timestampCell(),
      },
      {
        name: 'subject',
        prop: 'subject',
        align: 'left',
        visible: true,
        width: 400,
      },
      {
        name: 'from',
        prop: 'from',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'to',
        prop: 'to',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'business id',
        prop: 'businessId',
        align: 'right',
        visible: true,
        width: 90,
      },
      {
        name: 'case id',
        prop: 'caseId',
        align: 'right',
        visible: true,
        width: 120,
      },
    ],
  },
  {
    name: 'page',
    descriptionKey: 'path',
    description: 'content for a page',
    editor: (obj: any) => {
      loadRoute(`/page-editor?id=${obj.value._id}`)
    },
    sortField: 'path',
    columns: [
      {
        name: 'path',
        prop: 'path',
        align: 'left',
        visible: true,
        width: 200,
      },
      {
        name: 'Created',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 100,
        dataCell: timestampCell(),
      },
      {
        name: 'Modified',
        prop: '_modified',
        align: 'left',
        visible: true,
        width: 100,
        dataCell: timestampCell('_modified'),
      },
      {
        name: 'markdown',
        prop: 'markdown',
        align: 'left',
        visible: true,
        width: 400,
      },
    ],
  },
  {
    name: 'review',
    description:
      'reviews left by customers after a case is resolved or abandoned',
    columns: [
      {
        name: 'Date',
        prop: '_created',
        align: 'left',
        visible: true,
        width: 180,
        dataCell: timestampCell(),
      },
      {
        name: 'businessName',
        prop: 'businessName',
        align: 'left',
        visible: true,
        width: 140,
      },
      {
        name: 'detail',
        prop: 'detail',
        align: 'left',
        visible: true,
        width: 400,
      },
      {
        name: 'businessId',
        prop: 'businessId',
        align: 'right',
        visible: true,
        width: 100,
      },
      {
        name: 'caseId',
        prop: 'caseId',
        align: 'right',
        visible: true,
        width: 100,
      },
      {
        name: 'rating',
        prop: 'rating',
        align: 'right',
        visible: true,
        width: 90,
      },
      {
        name: 'N3',
        prop: 'ourRating',
        align: 'right',
        visible: true,
        width: 90,
      },
      {
        name: 'WDBA',
        prop: 'wouldDoBusiness',
        align: 'left',
        visible: true,
        width: 120,
      },
      {
        name: 'WUN3A',
        prop: 'wouldUseNoNoNoAgain',
        align: 'left',
        visible: true,
        width: 120,
      },
    ],
  },
  {
    name: 'template',
    descriptionKey: 'name',
    description: 'templates for messages or parts of messages',
    editor: snippetEditor,
    columns: [
      {
        name: 'name',
        prop: 'name',
        align: 'left',
        visible: true,
        width: 300,
      },
      {
        name: 'subject',
        prop: 'subject',
        align: 'left',
        visible: true,
        width: 300,
      },
      {
        name: 'action',
        prop: 'action',
        align: 'left',
        visible: true,
        width: 300,
      },
      {
        name: 'context',
        prop: 'context',
        align: 'left',
        visible: true,
        width: 180,
      },
      {
        name: 'recipient',
        prop: 'recipient',
        align: 'left',
        visible: true,
        width: 180,
      },
    ],
  },
]
