import { Component as WebComponent, ElementCreator, elements } from 'xinjs'
import { icons } from 'xinjs-ui'
import { markdownViewer, MarkdownViewer } from './markdown-viewer'
import { Review } from '../mocks/review'
import { popPage } from './pop-page'
import { service } from '../firebase'
const { div, h3, span, a, slRating } = elements

interface Parts {
  avatar: HTMLElement
  name: HTMLElement
  rating: HTMLInputElement
  date: HTMLElement
  caption: HTMLElement
  excerpt: MarkdownViewer
  showAll: HTMLAnchorElement
}

class ReviewStamp extends WebComponent {
  review: Review | null = null

  showReview = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()
    document.body.append(
      popPage({
        pageId: 'case-embedded',
        context: service.case
          .get({ caseId: this.review!.caseId })
          .then((caseData) => ({
            case: caseData,
            review: this.review,
          })),
      })
    )
  }

  content = () => [
    div({ part: 'avatar' }),
    h3({ part: 'name' }),
    slRating({
      part: 'rating',
      readonly: true,
      label: 'Score out of a possible five stars',
    }),
    div({ part: 'date' }),
    div({ part: 'caption' }),
    markdownViewer({ part: 'excerpt' }),
    div({ part: 'overflow' }),
    a(
      { part: 'showAll', onClick: this.showReview },
      icons.zoomIn(),
      span('Show full review')
    ),
  ]

  constructor() {
    super()
    this.initAttributes('review')
  }

  render() {
    super.render()

    const { review } = this

    const { avatar, name, rating, date, caption, excerpt, showAll } = this
      .parts as unknown as Parts

    if (review) {
      avatar.textContent = (review.displayName as string)
        .split(' ')
        .map((name) => name[0])
        .join('')
      name.textContent = review.displayName as string
      if (review.ourRating) {
        rating.value = String(review.ourRating as number)
        excerpt.value = review.ourDetail as string
        caption.textContent = `${review.displayName}'s review of NoNoNo`
      } else {
        rating.value = String(review.rating as number)
        excerpt.value = review.detail as string
        caption.textContent = `${review.displayName}'s review of ${review.businessName}`
      }
      date.textContent = new Date(
        review._created as string
      ).toLocaleDateString()
      showAll.setAttribute('href', '/case/' + review.caseSlug)
      showAll.toggleAttribute('hidden', false)
    } else {
      showAll.toggleAttribute('hidden', true)
    }
  }
}

export const reviewStamp = ReviewStamp.elementCreator({
  tag: 'review-stamp',
  styleSpec: {
    ':host': {
      display: 'block',
      boxShadow: '0 2px 16px #0002',
      minWidth: '200px',
      minHeight: '400px',
      borderRadius: '8px',
      position: 'relative',
      background: '#fff',
    },

    ':host > *': {
      position: 'absolute',
    },

    ':host [part="avatar"]': {
      width: '60px',
      height: '60px',
      background: '#ccc',
      top: '12px',
      left: '16px',
      position: 'absolute',
      backgroundColor: 'var(--brand-color)',
      borderRadius: '30px',
      lineHeight: '60px',
      textAlign: 'center',
      color: 'var(--brand-text-color)',
      fontWeight: '500',
      fontSize: '28px',
      opacity: '0.25',
    },

    ':host svg': {
      fill: 'var(--primary-color) !important',
    },

    ':host [part="showAll"] svg': {
      transform: 'translateY(-2px)',
      marginRight: '4px',
    },

    ':host [part="name"]': {
      color: 'var(--brand-color)',
      top: '12px',
      left: '88px',
      margin: '0',
    },

    ':host [part="rating"]': {
      left: '88px',
      top: '37px',
      _symbolSize: '14px',
      _symbolSpacing: '1px',
    },

    ':host [part="date"]': {
      left: '88px',
      top: '54px',
      fontSize: '12px',
    },

    ':host [part="caption"]': {
      left: '16px',
      right: '16px',
      top: '88px',
      fontSize: '14px',
      fontWeight: '500',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    ':host [part="excerpt"]': {
      top: '112px',
      left: '16px',
      right: '16px',
      bottom: '42px',
      overflow: 'hidden',
    },

    ':host [part="excerpt"], :host [part="excerpt"] > *': {
      lineHeight: '24px',
    },

    ':host [part="overflow"]': {
      left: '16px',
      right: '16px',
      height: '48px',
      bottom: '42px',
      background:
        'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    },

    ':host [part="showAll"]': {
      color: 'var(--primary-color)',
      position: 'absolute',
      left: '16px',
      bottom: '12px',
    },
  },
}) as ElementCreator<ReviewStamp>
