import {
  Component as XinComponent,
  ElementCreator,
  elements,
  vars,
  xin,
  debounce,
} from 'xinjs'
import { hasFieldContaining } from '../filters'
import { xinFloat, xinSelect, XinSelect } from 'xinjs-ui'
import { getRecord, getRecords, WhereQuerySpec } from '../firebase'
import { loadingSpinner } from './loading-spinner'
import { collections } from '../collections'
import { loadRoute } from '../routes'
import { dbTool } from './crud-tool'
import { CollectionSpec } from '../collection-spec'

const { h2, h3, div, label, input, ul, li, a, select, span, option, button } =
  elements

const headingStyle = {
  margin: 0,
  textAlign: 'center',
  padding: `${vars.spacing50} ${vars.spacing}`,
}

const listStyle = {
  listStyle: 'none',
  alignItems: 'stretch',
  margin: 0,
  padding: `${vars.spacing50} ${vars.spacing}`,
}

const floatStyle = {
  position: 'fixed',
  top: vars.spacing200,
  width: '400px',
  maxWidth: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  boxShadow: vars.zShadow,
  borderRadius: vars.roundedRadius200,
  overflow: 'hidden',
  background: vars.background,
  maxHeight: `calc(100vh - ${vars.spacing200})`,
}

const backdropStyle = {
  content: ' ',
  display: 'block',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: '#0002',
}

const labelStyle = {
  display: 'grid',
  gridTemplateColumns: '120px 1fr',
  alignItems: 'center',
  padding: 0,
}

export class QuickSearch extends XinComponent {
  static toggle(visible?: boolean) {
    const existing = document.querySelector('quick-search')
    if (visible !== true && existing) {
      existing.remove()
    } else if (visible !== false && !existing) {
      document.body.append(quickSearch())
    }
  }

  content = xinFloat(
    {
      drag: true,
      style: floatStyle,
    },
    h2('Quick Search', {
      class: 'primary',
      style: headingStyle,
    }),
    label(
      { class: 'row no-drag', style: { alignItems: 'center' } },
      input({
        part: 'search',
        type: 'search',
        placeholder: 'filter text',
        class: 'elastic',
      })
    ),
    div(
      {
        class: 'column no-drag',
        style: {
          flex: '1 1 auto',
          overflow: 'hidden auto',
          alignItems: 'stretch',
        },
      },
      loadingSpinner({ part: 'spinner', hidden: true }),
      div(
        { part: 'caseList', hidden: true },
        h3('Cases', { style: headingStyle }),
        ul({ class: 'column', style: listStyle })
      ),
      div(
        { part: 'messageList', hidden: true },
        h3('Messages', { style: headingStyle }),
        ul({ class: 'column', style: listStyle })
      )
    )
  )

  static shortCut = (event: KeyboardEvent) => {
    if ((xin.app as any).privileges.isCSR !== true) {
      return
    }
    if ((event.altKey || event.ctrlKey) && event.code === 'Space') {
      QuickSearch.toggle(true)
    } else if (event.code === 'Escape') {
      QuickSearch.toggle(false)
    }
  }

  doFilter = async () => {
    const { search, caseList, messageList, spinner } = this.parts
    const field = (search as HTMLInputElement).value.trim().toLocaleLowerCase()
    caseList.hidden = true
    messageList.hidden = true

    if (field === '') {
      return
    }

    spinner.removeAttribute('hidden')

    let cases: any[] = []

    if (field.includes('@')) {
      cases = await getRecords('case', {
        field: 'email',
        operator: '==',
        value: field,
      })
    } else {
      const caseById = await getRecord('case', field)
      if (caseById !== undefined) {
        cases = [caseById]
      } else {
        cases = await getRecords('case', {
          field: 'slug',
          operator: '==',
          value: field,
        })
      }
    }

    const messages = (xin.inbox as any).messages
      .filter((message: any) =>
        hasFieldContaining(message, field, 'from', 'to', 'subject')
      )
      .slice(0, 5)

    if (cases.length > 0) {
      caseList.hidden = false
      const random = Math.random()
      const ul = caseList.querySelector('ul') as HTMLUListElement
      ul.innerText = ''
      ul.append(
        ...cases.map(
          (caseData: any): HTMLElement =>
            li(
              { class: 'row' },
              a(caseData.title, {
                title: caseData.email,
                href: `/inbox?c=${caseData._id}#${random}`,
                class: 'text-nowrap',
              })
            )
        )
      )
    }
    if (messages.length > 0) {
      messageList.hidden = false
      const ul = messageList.querySelector('ul') as HTMLUListElement
      ul.innerText = ''
      ul.append(
        ...messages.map(
          (message: any): HTMLElement =>
            li(
              { class: 'row' },
              a(message.subject, {
                title: `from: ${message.from}, to: ${message.to}`,
                href:
                  message.caseId === undefined
                    ? `/inbox?m=${message._id}`
                    : `/inbox?c=${message.caseId}`,
                class: 'text-nowrap',
              })
            )
        )
      )
    }

    spinner.setAttribute('hidden', '')
  }

  close = (event: Event) => {
    if (
      event.target === this ||
      (event.target as HTMLElement).closest('a') !== null
    ) {
      setTimeout(() => {
        QuickSearch.toggle(false)
      }, 100)
    }
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.parts.search.addEventListener(
      'keydown',
      debounce(this.doFilter, 500),
      {
        passive: true,
      }
    )
    this.addEventListener('mouseup', this.close, { passive: true })
    this.addEventListener('touchstart', this.close, { passive: true })
  }

  render() {
    Object.assign(this.style, backdropStyle)
    this.parts.search.focus()
  }
}

export const quickSearch = QuickSearch.elementCreator({
  tag: 'quick-search',
}) as ElementCreator<QuickSearch>

window.addEventListener('keyup', QuickSearch.shortCut, { passive: true })

export type SearchCallback = (records: any[], description: string) => void
export class DeepSearch extends XinComponent {
  static callback: null | SearchCallback = null
  static collection = 'case'
  static haystack = 'businessName'
  static operator = '=='
  static needle = ''
  static limit = 500

  static toggle(visible?: boolean, callback: null | SearchCallback = null) {
    const existing = document.querySelector('deep-search')
    DeepSearch.callback = callback
    if (visible !== true && existing) {
      existing.remove()
    } else if (visible !== false && !existing) {
      document.body.append(deepSearch())
    }
  }

  close = (event: Event) => {
    if (event.target === this) {
      DeepSearch.toggle(false)
    }
  }

  static async getRecords(
    collection: string,
    haystack: string,
    operator: string,
    needle: string,
    limit: number
  ) {
    Object.assign(DeepSearch, {
      collection,
      haystack,
      operator,
      needle,
      limit,
    })
    dbTool.records = []
    dbTool.collection = collection
    dbTool.tableColumns = collections.find(
      (c) => c.name === collection
    )!.columns
    let _needle
    if (haystack !== '_id' && !isNaN(Number(_needle))) {
      _needle = Number(needle)
    } else if (needle === 'true') {
      _needle = true
    } else if (needle === 'false') {
      _needle = false
    } else {
      _needle = needle
    }
    const query =
      haystack && needle
        ? { field: haystack, operator: operator, value: _needle }
        : undefined
    dbTool.records = await getRecords(
      collection,
      query as WhereQuerySpec | undefined,
      limit
    )
    if (DeepSearch.callback) {
      DeepSearch.callback(
        dbTool.records,
        `${collection} ⨉ ${dbTool.records.length}`
      )
    }
    DeepSearch.toggle(false)
  }

  doSearch = async () => {
    const { collection, field, operator, needle, limit } = this.parts as {
      collection: XinSelect
      field: XinSelect
      operator: HTMLSelectElement
      needle: HTMLInputElement
      limit: HTMLInputElement
    }

    loadRoute('/data')
    await DeepSearch.getRecords(
      collection.value,
      field.value,
      operator.value,
      needle.value,
      Number(limit.value)
    )
  }

  get fields(): { caption: string; value: string }[] {
    const spec = collections.find(
      (spec) => spec.name === DeepSearch.collection
    ) as CollectionSpec
    return [
      {
        caption: 'id',
        value: '_id',
      },
      ...spec.columns.map(({ name, prop }) => ({
        caption: name,
        value: prop,
      })),
    ]
  }

  pickCollection = () => {
    DeepSearch.collection = (this.parts.collection as XinSelect).value
    const { fields } = this
    ;(this.parts.field as XinSelect).options = fields
    ;(this.parts.field as XinSelect).value = fields[0].value
  }

  content = () =>
    xinFloat(
      {
        drag: true,
        style: floatStyle,
      },
      h2('Deep Search', {
        class: 'primary',
        style: headingStyle,
      }),
      div(
        {
          class: 'column no-drag',
          style: {
            flex: '1 1 auto',
            overflow: 'hidden auto',
            alignItems: 'stretch',
            padding: vars.spacing,
          },
        },
        label(
          { style: labelStyle },
          span('collection'),
          xinSelect({
            part: 'collection',
            value: DeepSearch.collection,
            options: collections.map((collection) => collection.name),
            onChange: this.pickCollection,
          })
        ),
        div(
          {
            style: {
              display: 'grid',
              gridTemplateColumns: 'auto 50px auto',
              alignItems: 'center',
            },
          },
          xinSelect({
            title: 'field',
            part: 'field',
            placeholder: 'field',
            editable: true,
            options: this.fields,
            value: DeepSearch.haystack,
          }),
          select(
            {
              title: 'comparison operator',
              part: 'operator',
              value: DeepSearch.operator,
              style: {
                background: 'none',
                boxShadow: 'none',
                padding: 0,
                textAlign: 'center',
              },
            },
            option('=='),
            option('!='),
            option('<'),
            option('<='),
            option('>='),
            option('>'),
            option('in'),
            option('not-in')
          ),
          input({
            title: 'value',
            part: 'needle',
            placeholder: 'value',
            value: DeepSearch.needle,
          })
        ),
        label(
          { style: labelStyle },
          span('Max Records'),
          input({ part: 'limit', type: 'number', value: DeepSearch.limit })
        ),
        div(
          { class: 'row', style: { marginTop: vars.spacing } },
          span({ class: 'elastic' }),
          button('Cancel', {
            onClick() {
              DeepSearch.toggle(false)
            },
          }),
          button('Search…', {
            onClick: this.doSearch,
          })
        )
      )
    )

  connectedCallback(): void {
    super.connectedCallback()
    this.addEventListener('mouseup', this.close, { passive: true })
    this.addEventListener('touchstart', this.close, { passive: true })
  }

  render() {
    Object.assign(this.style, backdropStyle)
    ;(this.parts.collection as HTMLInputElement).value = 'case'
  }
}

export const deepSearch = DeepSearch.elementCreator({
  tag: 'deep-search',
}) as ElementCreator<DeepSearch>
