import { elements } from 'xinjs'
const { img } = elements
export { appFooter } from './app-footer'
export { assetManager } from './asset-manager'
export { blogView } from './blog-view'
export { businessEditor } from './business-editor'
export { complaintButton } from './complaint-button'
export { cardView } from './card-view'
export { crudTool } from './crud-tool'
export { disclose } from './disclose'
export { disqusThread } from './disqus-thread'
export { businessProfile } from './business-profile'
export { businessSearch, findBusiness } from './business-search'
export { businessSummary } from './business-summary'
export { caseView } from './case-view'
export { complaintsView } from './complaints-view'
export { formEditor } from './form-editor'
export { formViewer } from './form-viewer'
export { gkEditor } from './gk-editor'
export { homePage } from './home-page'
export { inBox } from './inbox'
export { latestReviews } from './latest-reviews'
export { loadingSpinner } from './loading-spinner'
export { markdownViewer } from './markdown-viewer'
export { messageEditor } from './message-editor'
export const placeholder = (): HTMLImageElement =>
  img({ alt: 'No No No™', class: 'centered ph', src: 'images/logo.svg' })
export { pageEditor } from './page-editor'
export { pageNavigator } from './page-navigator'
export { popPage } from './pop-page'
export { quickSearch, deepSearch } from './search'
export { reviewCard } from './review-card'
export { reviewStamp } from './review-stamp'
export { serviceTest } from './service-test'
export { signIn } from './sign-in'
export { statusReport } from './report'
export { tabSelector } from 'xinjs-ui'
export { uploadTsv } from './upload-tsv'
export { snippetEditor } from './snippet-editor'
