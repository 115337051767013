export function hasFieldContaining(
  haystack: any,
  needle: string,
  ...fields: string[]
): boolean {
  needle = needle.toLocaleLowerCase()
  return (
    fields.find(
      (field) =>
        haystack[field] !== undefined &&
        JSON.stringify(haystack[field]).toLocaleLowerCase().includes(needle)
    ) !== undefined
  )
}
