import { Component as WebComponent, elements } from 'xinjs'
import { icons } from 'xinjs-ui'
import { Form } from './form-items'
import { formViewer, FormViewer } from './form-viewer'
import { log, error } from '../notifications'
import { service as serviceProxy, ServiceRequestType } from '../firebase'

const { div, span, select, option, pre, button } = elements
interface Service {
  id?: string
  endpoint: string
  defaults: { [key: string]: any }
  method: ServiceRequestType
  form: Form
}

const services: Service[] = [
  {
    endpoint: 'hello',
    defaults: {},
    method: 'get',
    form: {
      id: 'hello-test-form',
      title: 'hello',
      pages: [
        {
          title: 'hello test',
          nextCaption: 'test',
          items: [
            {
              type: 'info',
              key: '',
              caption: '# hello\n\n`hello` requires no parameters.',
            },
            {
              type: 'string',
              key: 'name',
              caption: 'optional name parameter',
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'record',
    defaults: { p: 'test/15u0em6ytnkes0od' },
    method: 'get',
    form: {
      id: 'record-get-test',
      title: 'get record',
      pages: [
        {
          title: 'get record test',
          nextCaption: 'test',
          items: [
            {
              type: 'string',
              key: 'p',
              caption: 'path',
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'privileges',
    defaults: {},
    method: 'get',
    form: {
      id: 'privileges-test-form',
      title: 'privileges',
      pages: [
        {
          title: 'privileges test',
          nextCaption: 'test',
          items: [
            {
              type: 'info',
              key: '',
              caption: '# privileges\n\nprivileges takes no parameters',
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'add',
    defaults: { a: -1, b: 17.5 },
    method: 'post',
    form: {
      id: 'add-test-form',
      title: 'add',
      pages: [
        {
          title: 'add test',
          nextCaption: 'Test',
          items: [
            {
              type: 'float',
              caption: 'a',
              key: 'a',
              required: true,
            },
            {
              type: 'float',
              caption: 'b',
              key: 'b',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'notify',
    defaults: {
      content: 'hello from dev',
      url: '',
      title: '',
      description: '',
    },
    method: 'post',
    form: {
      id: 'discord-notification',
      title: 'notify',
      pages: [
        {
          title: 'notify test',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'content',
              key: 'content',
              required: true,
            },
            {
              type: 'string',
              caption: 'url',
              key: 'url',
              required: false,
            },
            {
              type: 'string',
              caption: 'title',
              key: 'title',
              required: false,
            },
            {
              type: 'string',
              caption: 'description',
              key: 'description',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'message',
    defaults: {
      to: '',
      cc: '',
      bcc: '',
      from: 'jack@justsay.nonono.com',
      subject: 'Testing',
      text: 'Some text',
    },
    method: 'post',
    form: {
      id: 'test-message-post',
      title: 'send',
      pages: [
        {
          title: '/message POST',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'to',
              key: 'to',
              required: true,
            },
            {
              type: 'string',
              caption: 'cc',
              key: 'cc',
              required: false,
            },
            {
              type: 'string',
              caption: 'bcc',
              key: 'bcc',
              required: false,
            },
            {
              type: 'string',
              caption: 'from',
              key: 'from',
              required: true,
            },
            {
              type: 'string',
              caption: 'subject',
              key: 'subject',
              required: true,
            },
            {
              type: 'text',
              caption: 'text',
              key: 'text',
              required: false,
            },
            {
              type: 'text',
              caption: 'html',
              key: 'html',
              required: false,
            },
            {
              type: 'boolean',
              caption: 'send email',
              key: 'sendEmail',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'logcasepaid',
    defaults: {
      caseId: 'zqqjgt1cl4lqweqa',
    },
    method: 'get',
    form: {
      id: 'logcasepaid',
      title: 'Log Case Paid',
      pages: [
        {
          title: '/logcasepaid',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'caseId',
              key: 'caseId',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'askgpt',
    defaults: {
      prompt:
        'tell me a joke about some how many of some random kind of animal it takes to change a light bulb',
    },
    method: 'post',
    form: {
      id: 'askgpt',
      title: 'ask me',
      pages: [
        {
          title: '/askgpt',
          nextCaption: 'Test',
          items: [
            {
              type: 'text',
              caption: 'Prompt',
              key: 'prompt',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'review',
    method: 'get',
    defaults: {},
    form: {
      id: 'review',
      title: 'get /review',
      pages: [
        {
          title: 'get /review',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'reviewId',
              key: 'reviewId',
              required: false,
            },
            {
              type: 'string',
              caption: 'caseId',
              key: 'caseId',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'reviews',
    method: 'get',
    defaults: {
      count: 5,
    },
    form: {
      id: 'review',
      title: 'get /reviews',
      pages: [
        {
          title: 'get /reviews',
          nextCaption: 'Test',
          items: [
            {
              type: 'int',
              caption: 'count',
              key: 'count',
              required: true,
            },
            {
              type: 'string',
              caption: 'businessId',
              key: 'businessId',
              required: false,
            },
            {
              type: 'string',
              caption: 'business path',
              key: 'businessPath',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'list',
    method: 'get',
    defaults: {
      id: 'business-search',
    },
    form: {
      id: 'get-list (public)',
      title: 'get /list',
      pages: [
        {
          title: 'get /list',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'list name',
              key: 'id',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'list',
    method: 'post',
    defaults: {
      collection: 'business-profile',
      props: ['path', 'name', 'cases', 'reviews', 'isTestData'],
      latest: false,
      publicId: 'business-search',
      limit: 6000,
    },
    form: {
      id: 'post-list',
      title: 'post /list',
      pages: [
        {
          title: 'post /list',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'collection',
              key: 'collection',
              required: true,
            },
            {
              type: 'tagList',
              options: ['_id', '_created', '_modified'],
              caption: 'props',
              key: 'props',
              required: true,
            },
            {
              type: 'boolean',
              caption: 'bypass cache',
              key: 'latest',
              required: false,
            },
            {
              type: 'string',
              caption: 'public id',
              key: 'publicId',
              required: false,
            },
            {
              type: 'int',
              caption: 'limit',
              key: 'limit',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'case',
    defaults: {},
    method: 'get',
    form: {
      id: 'get-case',
      title: 'get /case',
      pages: [
        {
          title: 'get /case',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'case id',
              key: 'caseId',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'business',
    id: 'business',
    defaults: {
      id: '',
      path: 'test',
      rebuild: false,
    },
    method: 'get',
    form: {
      id: 'business',
      title: 'business',
      pages: [
        {
          title: 'business',
          nextCaption: 'test',
          items: [
            {
              type: 'string',
              caption: 'id',
              key: 'id',
              required: false,
            },
            {
              type: 'string',
              caption: 'path',
              key: 'path',
              required: false,
            },
            {
              type: 'boolean',
              caption: 'rebuild',
              key: 'rebuild',
              required: false,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'case',
    id: 'case?checkLink',
    defaults: { caseId: '', checkLink: 'review' },
    method: 'get',
    form: {
      id: 'case-checklink',
      title: 'case?checkLink',
      pages: [
        {
          title: 'link type',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'caseId',
              key: 'caseId',
              required: true,
            },
            {
              type: 'selectOne',
              caption: 'Link Type',
              options: ['review', 'business', 'customer'],
              key: 'checkLink',
              required: true,
            },
          ],
        },
      ],
    },
  },
  {
    endpoint: 'createStripeUser',
    defaults: {
      fname: 'kai',
      lname: 'kankaala',
      email: 'kaitsu@woimakas.fi',
      phone: '+37256936836',
    },
    method: 'post',
    form: {
      id: 'stripe-test',
      title: 'add',
      pages: [
        {
          title: 'add test',
          nextCaption: 'Test',
          items: [
            {
              type: 'string',
              caption: 'first name',
              key: 'fname',
              required: true,
            },
            {
              type: 'string',
              caption: 'last name',
              key: 'lname',
              required: true,
            },
            {
              type: 'string',
              caption: 'email',
              key: 'email',
              required: true,
            },
            {
              type: 'string',
              caption: 'phone',
              key: 'phone',
              required: true,
            },
          ],
        },
      ],
    },
  },
]

services.forEach((service) => {
  if (service.id === undefined) {
    service.id = `${service.endpoint}/${service.method}`
  }
})

class ServiceTest extends WebComponent {
  service = services[0].id
  private resultsVisible = false

  content = () =>
    div(
      {
        class: 'column',
        style: { position: 'relative', alignItems: 'stretch', gap: 0 },
      },
      div(
        { class: 'toolbar primary' },
        button(icons.chevronLeft(), {
          title: 'Admin Tools',
          onClick: () => {
            if (!this.resultsVisible) {
              window.history.back()
            } else {
              this.reset()
            }
          },
        }),
        span('Service Tests'),
        span({ class: 'elastic' }),
        select(
          { part: 'servicePicker' },
          ...services.map((service) => option(service.id as string))
        ),
        icons.chevronDown()
      ),
      formViewer({ part: 'form' }),
      pre({ part: 'results', style: { margin: 0, whiteSpace: 'pre-wrap' } }),
      button('Go Back', { part: 'goBack' })
    )

  constructor() {
    super()
    this.initAttributes('service')
  }

  showResults = (): void => {
    const { form, results, goBack } = this.parts
    this.resultsVisible = true
    results.hidden = false
    goBack.hidden = false
    form.hidden = true
  }

  reset = (): void => {
    const { results, goBack } = this.parts
    const form = this.parts.form as unknown as FormViewer
    this.resultsVisible = false
    form.hidden = false
    results.hidden = true
    goBack.hidden = true
    form.page = 0
  }

  connectedCallback(): void {
    super.connectedCallback()
    const { servicePicker, results, goBack } = this.parts as {
      [key: string]: HTMLInputElement
    }
    const form = this.parts.form as unknown as FormViewer
    results.hidden = true
    goBack.hidden = true

    servicePicker.addEventListener('change', () => {
      this.service = servicePicker.value
      this.reset()
    })

    form.addEventListener('submit', (event: Event) => {
      event.preventDefault()
      const service = services.find((service) => service.id === this.service)
      if (service == null) {
        error(`${this.service as string} not found`)
        return
      }
      const { endpoint, method } = service
      log(`**${service.method}** ${endpoint} ${JSON.stringify(form.value)}`)
      results.innerText = '... waiting ...'
      ;(async () => {
        console.time('service-test')
        if (service != null) {
          let payload = ''
          payload = JSON.stringify(form.value, null, 2) + '\n'
          const result = await serviceProxy[endpoint][service.method](
            form.value
          )
          console.timeEnd('service-test')
          results.innerText = `request: ${method} /${endpoint}\n${payload}\nresponse: ${JSON.stringify(
            result,
            null,
            2
          )}`
        }
      })().catch((_error) => {
        console.timeEnd('service-test')
        error(JSON.stringify(_error))
        results.innerText = `Error:\n\n${JSON.stringify(_error)}`
      })
      this.showResults()
    })

    goBack.addEventListener('click', this.reset)

    this.queueRender()
  }

  render(): void {
    const form = this.parts.form as unknown as FormViewer
    const service = services.find((service) => service.id === this.service)
    if (service != null) {
      form.form = service.form
      form.value = service.defaults != null ? service.defaults : {}
      form.render()
    }
  }
}

export const serviceTest = ServiceTest.elementCreator({ tag: 'service-test' })
