import { xin } from 'xinjs'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

function getValueAtPath(path: string, fieldName: string): any {
  fieldName = fieldName.trim()
  const valuePath = fieldName.startsWith('[')
    ? `${path}${fieldName}`
    : `${path}.${fieldName}`
  return xin[valuePath]
}

export function lateBinding(context: any, elt: HTMLElement) {
  let path: string
  if (context === undefined || context === null) {
    return
  } else if (typeof context === 'string') {
    path = context
  } else {
    xin.templateContext = context
    path = 'templateContext'
  }

  const lateBoundElements = [
    ...(elt.querySelectorAll('[data-late-binding]') || []),
  ] as HTMLElement[]

  for (const elt of lateBoundElements) {
    const [target, fieldName] = elt.dataset.lateBinding!.split('=') as string[]
    switch (target) {
      case 'html':
        {
          const src = getValueAtPath(path, fieldName)
          if (src) {
            elt.innerHTML = populateTemplate(src, context, true, true)
          }
        }
        break
      default:
        ;(elt as any)[target] = getValueAtPath(path, fieldName)
    }
  }
}

export function populateTemplate(
  src: string,
  context: object | string | undefined | null,
  renderAsMarkdown = false,
  trustedSource = false
): string {
  let path: string
  if (context === undefined || context === null) {
    return src
  } else if (typeof context === 'string') {
    path = context
  } else {
    xin.templateContext = context
    path = 'templateContext'
  }
  let populated = src
  while (populated.match(/\{\{([^{}]+)\}\}/)) {
    populated = populated.replace(
      /\{\{([^{}]+)\}\}/g,
      (_: string, templateCode: string): string => {
        // handle function calls
        const lateBinding = templateCode.includes('=')
        const hasFallback = templateCode.includes('?')
        let lateBindTarget = ''
        let fieldName = templateCode
        let replacement = ''

        if (lateBinding) {
          ;[lateBindTarget, fieldName] = templateCode
            .split('=', 2)
            .map((s) => s.trim())
          if (lateBindTarget === '') {
            lateBindTarget = 'value'
          }
        } else if (hasFallback) {
          ;[fieldName, replacement] = templateCode
            .split('?', 2)
            .map((s) => s.trim())
        }

        if (lateBinding) {
          if (hasFallback) {
            console.warn('late-binding does not support fallback values (yet)')
          }
          replacement = `data-late-binding="${lateBindTarget}=${fieldName}"`
        } else if (hasFallback) {
          const foundValue = getValueAtPath(path, fieldName)
          const parts = replacement.split(':')

          replacement = foundValue ? parts[0] : parts[1] || ''
        } else if (fieldName.match(/\(.*\)$/)) {
          const paths = fieldName.match(/^(.*)\((.+)*\)$/)
          if (paths !== null) {
            const func = getValueAtPath(path, paths[1])
            const argValues =
              paths.length > 2
                ? paths[2]
                    .split(',')
                    .map((argPath: string) => getValueAtPath(path, argPath))
                : []
            replacement = func(...argValues)
          }
        } else {
          const foundValue = getValueAtPath(path, fieldName)

          if (foundValue !== undefined) {
            replacement = foundValue
          }
        }

        return replacement
      }
    )
  }
  if (!renderAsMarkdown) {
    return populated
  } else {
    const html = marked(populated, { mangle: false, headerIds: false })
    return trustedSource ? html : DOMPurify.sanitize(html)
  }
}
