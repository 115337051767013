import { xin, elements, XinProxyObject, vars, getListItem, touch } from 'xinjs'
import './bindings'
import './style'
import './shoelace'
import {
  appFooter,
  assetManager,
  gkEditor,
  markdownViewer,
  findBusiness,
} from './components'
import './components/review-stamp'
import './components/latest-reviews'
import { QuickSearch, DeepSearch } from './components/search'
import { signinWithGoogle } from './components/sign-in'
import { publicRoutes } from './routes'
import { app, fb } from './app'
import { loadRoute } from './routes'
import { pageNavigator } from './components/page-navigator'
import { stripePayment } from './components/stripe-payment'
import {
  xinFloat,
  positionFloat,
  AbTest,
  bodymovinPlayer,
  icons,
  popMenu,
  MenuItem,
  xinForm,
  xinField,
  makeSorter,
} from 'xinjs-ui'

// avoid lint complaints
;[pageNavigator, stripePayment, xinForm, xinField]

function mainMenu(): MenuItem[] {
  const items: MenuItem[] = []

  if (app.ui.isInternal) {
    items.push(
      {
        icon: 'help',
        caption: 'Help',
        action: showHelp,
      },
      null
    )
  }

  items.push(
    ...publicRoutes.map(({ menuIcon, title, href }) => {
      return {
        icon: menuIcon,
        caption: title,
        action: href,
      }
    })
  )

  if (app.ui.isInternal) {
    const internalMenu: MenuItem[] = [
      {
        icon: 'folder',
        caption: 'Inbox',
        action: '/inbox/',
      },
      {
        icon: 'search',
        caption: 'Quick Search…',
        action() {
          QuickSearch.toggle(true)
        },
      },
    ]

    if (app.privileges?.isAdmin) {
      internalMenu.push(
        {
          icon: 'database',
          caption: 'Deep Search…',
          action() {
            DeepSearch.toggle(true)
          },
        },
        null,
        {
          icon: 'database',
          caption: 'Businesses',
          action: '/data?c=business-profile',
        },
        {
          icon: 'folder',
          caption: 'Cases',
          action: '/data?c=case',
        },
        {
          icon: 'database',
          caption: 'Reviews',
          action: '/data?c=review',
        },
        {
          icon: 'file',
          caption: 'Pages',
          action: '/data?c=page',
        },
        {
          icon: 'database',
          caption: 'Snippets',
          action: '/data?c=template',
        },
        null,
        {
          icon: 'image',
          caption: 'Asset Manager',
          action() {
            const existing = document.body.querySelector('asset-manager')
            existing ? existing.remove() : document.body.append(assetManager())
          },
        },
        {
          icon: 'database',
          caption: 'Gatekeeper Settings',
          action() {
            const existing = document.body.querySelector('gk-editor')
            existing ? existing.remove() : document.body.append(gkEditor())
          },
        },
        {
          caption: 'Developer',
          menuItems: [
            {
              icon: 'database',
              caption: 'Service Tests',
              action: '/service-test',
            },
            {
              icon: app.isTestDataVisible ? 'check' : '',
              caption: app.isTestDataVisible
                ? 'Hide Test Data'
                : 'Show Test Data',
              action() {
                app.isTestDataVisible = !app.isTestDataVisible
              },
            },
          ],
        },
        null,
        {
          icon: 'editDoc',
          caption: app.ui.editableContent
            ? `Edit "${app.ui.editableContent}" page`
            : 'Edit (not available)',
          enabled: () => !!app.ui.editableContent,
          action() {
            if (app.ui.editableContent.endsWith(' page')) {
              loadRoute(`/page-editor?p=${app.ui.editableContent.slice(0, -5)}`)
            } else {
              const [collection, id] = app.ui.editableContent.split(' ')
              loadRoute(`/data?c=${collection}&id=${id}`)
            }
          },
        }
      )
    }

    items.push(null, {
      caption: 'Admin',
      menuItems: internalMenu,
    })
  }

  if (app.ui.showSignin) {
    items.push(null, {
      icon: 'logIn',
      caption: 'Sign In',
      action: signinWithGoogle,
    })
  } else if (app.ui.isInternal) {
    items.push(null, {
      icon: 'logOut',
      caption: 'Sign Out',
      action: fb.userSignout,
    })
  }
  return items
}

AbTest.conditions = fb.gk

declare global {
  interface Window {
    xin: XinProxyObject
    Intercom: any
  }
}

console.log(
  'Welcome to %cNo No No%c, the Consumer Advocacy Platform',
  'color: orange',
  'color: default'
)

// debug stuff
Object.assign(window, {
  xin,
  touch,
  getListItem,
  fb,
  app,
})

const { main, header, div, span, button, img, a, slDrawer } = elements

const setMenuVisibility = () => {
  app.ui.showMenu =
    document.body.offsetWidth < 900 || app.ui.showSignin || app.ui.isInternal
}

setMenuVisibility()

const showSekrit = (event: KeyboardEvent) => {
  if (event.altKey && event.code === 'KeyN') {
    app.ui.showSignin = app.ui.isInternal ? false : true
    setMenuVisibility()
  }
}

window.addEventListener('resize', setMenuVisibility)

let helpDrawer: any | undefined

const showHelp = () => {
  if (helpDrawer === undefined) {
    const viewer = markdownViewer({
      pageId: 'help-index',
      onClick(event: Event) {
        const a = (event.target as HTMLElement).closest('a') as
          | HTMLAnchorElement
          | undefined
        const href =
          a !== undefined && a.hasAttribute('href')
            ? (a.getAttribute('href') as string)
            : ''
        if (a !== undefined && href.match(/^[\w-]+$/)) {
          event.preventDefault()
          event.stopPropagation()
          helpDrawer.label = 'Help: ' + href.replace(/-/g, ' ')
          viewer.loadPage(href)
        }
      },
    })
    helpDrawer = slDrawer(
      { label: 'Help' },
      button(
        {
          title: 'help index',
          class: 'transparent',
          style: {
            boxShadow: 'none',
          },
          slot: 'header-actions',
          onClick() {
            viewer.loadPage('help-index')
            helpDrawer.label = 'Help'
          },
        },
        icons.home()
      ),
      viewer
    )
    document.body.append(helpDrawer)
  }
  helpDrawer.show()
}

document.body.addEventListener('keydown', showSekrit, { capture: true })

document.body.append(
  div(
    { id: 'root' },
    main({
      class: 'column',
      bindFullWidth: 'route.fullwidth',
    }),
    appFooter({ bindHide: 'route.admin' }),
    header(
      {
        onClick() {
          document.querySelector('main')?.scrollIntoView({ behavior: 'smooth' })
        },
      },
      a(
        { class: 'button', title: 'No No No home page' },
        img({
          src: '/images/logo.svg',
          alt: 'No No No™ Logo',
          class: 'badge',
          style: {
            height: '32px',
            width: 'auto',
            marginRight: vars.spacing,
            marginBottom: '-2px',
          },
        }),
        span('No No No', {
          class: 'brand-font',
          style: {
            fontSize: vars.fontSize150,
            wordSpacing: '-0.2ch',
            verticalAlign: '3px',
          },
        }),
        { href: '/' }
      ),
      span({ class: 'elastic' }),
      findBusiness({ placeholder: 'Search businesses…' }),
      span({ class: 'elastic' }),
      span(
        { class: 'show-if-wide' },
        ...publicRoutes
          .sort(makeSorter((a) => [a.title]))
          .map(({ title, href }) => a({ class: 'button' }, title, { href }))
      ),
      button(
        {
          title: 'menu',
          class: 'menu-trigger',
          bindShow: 'app.ui.showMenu',
          style: { padding: vars.spacing25 },
          onClick(event: Event) {
            event.stopPropagation()
            popMenu({
              target: event.target as HTMLElement,
              menuItems: mainMenu(),
            })
          },
        },
        img({
          bindShow: 'app.ui.isInternal',
          class: 'avatar',
          alt: 'user avatar',
          bindImageSrc: 'app.user.photoURL',
          bindTitle: 'app.user.email',
        }),
        icons.moreVertical({
          class: 'show-after-hidden icon-more-vertical avatar',
        })
      )
    )
  )
)

function celebrate(name: string) {
  document.body.append(
    xinFloat(
      {
        style: {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: vars.spacing200,
          borderRadius: vars.spacing,
        },
      },
      bodymovinPlayer({ src: './images/birthday.json' }),
      div(
        {
          style: {
            fontSize: '32px',
            lineHeight: '36px',
            textAlign: 'center',
            textTransform: 'capitalize',
          },
        },
        `happy birthday ${name}!`
      ),
      button(
        {
          style: {
            position: 'absolute',
            top: vars.spacing,
            right: vars.spacing,
            background: 'transparent',
            border: 0,
          },
          onClick(event: Event) {
            const float = (event.target as HTMLElement).closest(
              'xin-float'
            ) as HTMLElement
            float.remove()
          },
        },
        icons.x()
      )
    )
  )
}

app.celebrate = celebrate

fb.authStateChangeListeners.add((user) => {
  const date = new Date()
  const name = user?.email ? user.email.split('@')[0] : ''
  if (name === 'jaakko' && date.getDate() === 28 && date.getMonth() === 10) {
    celebrate(name)
  }
})

const tooltip = xinFloat({ class: 'tooltip' })
document.body.append(tooltip)
document.body.addEventListener('mousemove', (event: Event) => {
  const element = event.target
  tooltip.hidden = true
  if (element instanceof HTMLElement) {
    const needsTooltip = element.closest('[data-tooltip]')
    if (needsTooltip instanceof HTMLElement) {
      tooltip.hidden = false
      tooltip.textContent = needsTooltip.dataset.tooltip as string
      positionFloat(tooltip, needsTooltip, 's')
    }
  }
})

window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'tfho3bpo',
})

window.addEventListener('route-did-load', () => {
  window.Intercom('update')
})
