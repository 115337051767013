import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'

import { disqusThread } from './disqus-thread'
import { loadingSpinner } from './loading-spinner'

import { prefetched } from '../firebase'

const BLOG_API_KEY = 'f887f211-7db0-474d-a844-a22bebbdc81c'

async function getPost(slug: string): Promise<any> {
  if (slug === '') {
    return undefined
  }

  const req = await fetch(
    `https://api.dropinblog.com/v1/json/post/?b=${BLOG_API_KEY}&post=${slug}`
  )
  const { data } = await req.json()
  return data
}

async function getPosts(limit: number | 'all' = 6): Promise<any> {
  const req = await fetch(
    `https://api.dropinblog.com/v1/json/?b=${BLOG_API_KEY}&limit=${limit}`
  )
  const { data } = await req.json()
  return data
}

const { h1, h2, h3, img, div, a } = elements

const mockPost = {
  title: '',
  summary: '',
  content: '',
  slug: '',
  featuredImage: '',
  publishedAt: '',
  updatedAt: '',
  categories: [{ title: 'General', slug: 'general' }],
  author: {
    name: '',
    slug: '',
    photo: '',
  },
  readtime: '',
  pinned: false,
}

class BlogCard extends WebComponent {
  post = mockPost

  content = [
    img({ part: 'image', class: 'featured' }),
    a({ part: 'link' }, h3({ part: 'title' })),
    div({ part: 'summary' }),
    div(
      { class: 'metadata', style: { marginTop: vars.spacing50 } },
      div({ part: 'published' }),
      div({ part: 'read' })
    ),
  ]

  render(): void {
    super.render()

    const { image, title, summary, link, published, read } = this.parts

    ;(image as HTMLImageElement).src = this.post.featuredImage
    ;(image as HTMLImageElement).alt = this.post.title
    title.textContent = this.post.title
    summary.innerHTML = this.post.summary
    ;(link as HTMLAnchorElement).href = `/blog/${this.post.slug}/`
    published.textContent = this.post.publishedAt
    if (this.post.updatedAt !== this.post.publishedAt) {
      published.setAttribute(
        'data-tooltip',
        `Last update: ${this.post.updatedAt}`
      )
    }
    read.textContent = this.post.readtime
  }
}

export const blogCard = BlogCard.elementCreator({
  tag: 'blog-card',
  styleSpec: {
    'blog-card': {
      width: '240px',
      position: 'relative',
    },

    '@media screen and (max-width: 800px)': {
      'blog-card': {
        minWidth: '320px',
      },
    },

    'blog-card a': {
      whiteSpace: 'wrap',
      marginBottom: vars.spacing50,
    },

    'blog-card img.featured': {
      height: '120px',
    },

    'blog-card h3': {
      color: vars.brandColor,
    },

    'blog-card a:active h3': {
      color: vars.brandTextColor,
    },
  },
}) as ElementCreator<BlogCard>

class BlogPost extends WebComponent {
  post = mockPost

  content = [
    img({ part: 'image', class: 'featured' }),
    h1({ part: 'title' }),
    div(
      {
        class: 'row',
        style: { alignItems: 'center', marginBottom: vars.spacing50 },
      },
      img({
        part: 'authorAvatar',
        class: 'avatar',
        style: { width: '42px', height: '42px', borderRadius: '21px' },
      }),
      div(
        { class: 'column', style: { gap: 0, alignItems: 'flex-start' } },
        div({
          class: 'metadata',
          part: 'author',
          style: { color: vars.brandColor },
        }),
        div(
          { class: 'metadata' },
          div({ part: 'published' }),
          div({ part: 'read' })
        )
      )
    ),
    div({ part: 'content' }),
  ]

  render(): void {
    super.render()

    const { image, title, content, authorAvatar, author, read, published } =
      this.parts
    ;(image as HTMLImageElement).src = this.post.featuredImage
    ;(image as HTMLImageElement).alt = this.post.title
    title.textContent = this.post.title
    ;(authorAvatar as HTMLImageElement).src = this.post.author.photo
    ;(authorAvatar as HTMLImageElement).alt = this.post.author.name
    author.textContent = this.post.author.name
    content.innerHTML = this.post.content
    ;[...(content.querySelectorAll('[style]') || [])].forEach((elt) =>
      elt.removeAttribute('style')
    )
    published.textContent = this.post.publishedAt
    if (this.post.updatedAt !== this.post.publishedAt) {
      published.setAttribute(
        'data-tooltip',
        `Last update: ${this.post.updatedAt}`
      )
    }
    read.textContent = this.post.readtime
  }
}

export const blogPost = BlogPost.elementCreator({
  tag: 'blog-post',
  styleSpec: {
    'blog-post': {
      maxWidth: '100%',
      position: 'relative',
      marginBottom: vars.spacing,
    },
  },
}) as ElementCreator<BlogPost>

class BlogView extends WebComponent {
  slug = ''
  post?: any
  blog?: any

  content = [
    div({ part: 'post' }),
    this.slug ? disqusThread() : '',
    div({ part: 'heading' }),
    div({
      part: 'posts',
      class: 'row',
      style: {
        justifyContent: 'center',
        padding: vars.spacing,
        gap: vars.spacing,
        flexWrap: 'wrap',
      },
    }),
  ]

  connectedCallback(): void {
    super.connectedCallback()

    const { post } = this.parts

    const blogData = prefetched.records.find((rec) => rec.collection === 'blog')

    if (blogData && !blogData.isUsed) {
      blogData.isUsed = true
      const { post, blog } = blogData
      this.post = post
      this.blog = blog
      this.queueRender()
      return
    }

    if (this.slug) {
      post.textContent = ''
      post.append(loadingSpinner())
      getPost(this.slug).then((post) => {
        this.post = post
        this.queueRender()
      })
    } else {
      getPosts('all').then((blog) => {
        this.blog = blog
        this.queueRender()
      })
    }
  }

  render(): void {
    super.render()

    const { post, posts, heading } = this.parts
    heading.textContent = ''

    if (this.post) {
      post.textContent = ''
      post.append(blogPost({ post: this.post.post }))
    }

    let visiblePosts = []
    if (this.blog) {
      posts.textContent = ''
      visiblePosts = this.blog.posts.filter(
        (post: any) => post.slug !== this.post?.post.slug
      )
    } else if (this.post) {
      visiblePosts = this.post.post.related_posts.slice(0, 3)
    }

    if (visiblePosts.length) {
      const headingProps = {
        style: { textAlign: 'center', marginTop: vars.spacing200 },
      }
      if (this.post) {
        heading.append(h2('Related Posts', headingProps))
      } else {
        heading.append(
          h1('Ultimate consumer guides on escalating a complaint', headingProps)
        )
      }
      posts.append(...visiblePosts.map((post: any) => blogCard({ post })))
    }
  }
}

export const blogView = BlogView.elementCreator({
  tag: 'blog-view',
  styleSpec: {
    'blog-view': {
      position: 'relative',
      marginTop: vars.spacing,
      marginBottom: vars.spacing,
    },

    'blog-view h1': {
      marginTop: vars.spacing,
    },

    'blog-view h3': {
      marginTop: vars.spacing50,
    },

    'blog-view img': {
      width: '100%',
      height: 'auto',
    },

    'blog-view img:not(.featured):not(.avatar)': {
      margin: `${vars.spacing} 0`,
    },

    'blog-view .metadata': {
      opacity: 0.75,
      display: 'flex',
      gap: vars.spacing,
      fontSize: '80%',
    },
  },
}) as ElementCreator<BlogView>
