import { Form } from '../components/form-items'

export const contactForm: Form = {
  id: 'contact-form',
  title: 'contact form',
  completionMessage: 'Thank you, we’ll get back to you!',
  collection: 'message',
  pages: [
    {
      title: 'Contact No No No™',
      nextCaption: 'Send',
      items: [
        {
          type: 'info',
          caption: '# Contact No No No™',
          key: 'na',
        },
        {
          type: 'selectOne',
          caption: 'Reason for Contacting Us',
          options: [
            'You have a complaint against a business not in our existing database',
            'Inquire about a previous complaint',
            'Inaccurate or misleading information on our website',
            'Respond to a complaint on our website',
          ],
          key: 'subject',
          isOtherAllowed: true,
          required: true,
        },
        {
          type: 'text',
          caption: 'Details',
          key: 'text',
          required: true,
        },
        {
          type: 'string',
          caption: 'Your First Name',
          key: 'nameFirst',
          required: true,
        },
        {
          type: 'string',
          caption: 'Your Last Name',
          key: 'nameLast',
          required: true,
        },
        {
          type: 'email',
          caption: 'Your Email',
          key: 'from',
          required: true,
        },
        {
          type: 'phone',
          caption: 'Your Mobile Phone',
          key: 'phone',
        },
      ],
    },
  ],
}
