import {
  SlCarouselItem
} from "./chunk.D6SPZSIE.js";

// src/components/carousel-item/carousel-item.ts
var carousel_item_default = SlCarouselItem;
SlCarouselItem.define("sl-carousel-item");

export {
  carousel_item_default
};
