import { Form } from '../components/form-items'

export const reviewForm: Form = {
  id: 'review-form',
  title: 'review form',
  completionMessage: `# Thank you for your feedback!

  It really means a lot to us and helps us do better.

  ## Tips are Welcome!
  
  If you feel that the service you received deserves a tip, please <a target="_blank" href="https://buy.stripe.com/cN28yI7Rze5MexqeUV">**click here to leave a tip**</a>.`,
  collection: 'review',
  pages: [
    {
      title: 'Review No No No',
      nextCaption: 'Submit your Review',
      items: [
        {
          type: 'info',
          caption: '### Please review your experience with No No No',
          key: 'null-key',
        },
        {
          type: 'rating',
          caption: 'Rate **No No No**',
          key: 'ourRating',
          required: true,
        },
        {
          type: 'text',
          caption: 'Write a review of **No No No**',
          key: 'ourDetail',
          required: true,
        },
        {
          type: 'thumbs',
          caption: 'Would you use our service again?',
          key: 'wouldUseNoNoNoAgain',
          required: true,
        },
        {
          type: 'info',
          caption: '### Review {{business.name}} based on the outcome',
          key: 'null-key',
        },
        {
          type: 'rating',
          caption: 'Rate **{{business.name}}**',
          key: 'rating',
          required: true,
        },
        {
          type: 'text',
          caption: 'Write a resolution review of **{{business.name}}**',
          key: 'detail',
          required: true,
        },
        {
          type: 'thumbs',
          caption: 'Would you do business with **{{business.name}}** again?',
          key: 'wouldDoBusiness',
          required: true,
        },
      ],
    },
  ],
}
