import { service, prefetched, logEvent } from '../firebase'
import { Component as WebComponent, elements, vars, xin } from 'xinjs'
import { cardView } from './card-view'
import { caseView } from './case-view'
import { disqusThread } from './disqus-thread'
import { reviewCard } from './review-card'
import { markdownViewer } from './markdown-viewer'
import { complaintButton, ComplaintButton } from './complaint-button'
import {
  profile as mockProfile,
  complaintProcess,
  BrandProfile,
} from '../mocks/business-profile'
import { populateTemplate } from '../populate-template'
import { loadingSpinner } from './loading-spinner'
import { app } from '../app'

const { h1, h2, h3, div, ul, li, slRating, b, a, br, strong, span } = elements

const wrapAnywhere = {
  style: {
    overflowWrap: 'anywhere',
  },
}

interface Contact {
  description: string
  content: string
}

const address = (content: string): HTMLElement => {
  if (content.match(/^[+-\d]+$/) != null) {
    return a(
      { href: `tel:${content}`, dataType: 'tel', ...wrapAnywhere },
      content
    )
  } else if (content.includes('@')) {
    return a(
      { href: `mailto:${content}`, dataType: 'email', ...wrapAnywhere },
      content
    )
  } else if (content.startsWith('http')) {
    return a(
      { href: content, target: '_blank', ...wrapAnywhere },
      content.split('//')[1]
    )
  } else {
    return div(
      ...content
        .split('\n')
        .map((s, idx) => (idx > 0 ? [br(), s] : s))
        .flat()
    )
  }
}

const contact = (contact: Contact): HTMLDivElement => {
  return div(
    { class: 'row', style: { width: '100%', alignItems: 'flex-start' } },
    b(contact.description, {
      style: {
        flex: '0 0 35%',
        textAlign: 'right',
        textTransform: 'capitalize',
      },
    }),
    address(contact.content)
  )
}

export class BusinessProfile extends WebComponent {
  path = ''
  businessId = ''
  value: BrandProfile | null = null

  content = (): HTMLElement[] => [
    loadingSpinner({ part: 'spinner' }, 'loading'),
    div(
      {
        part: 'info',
        hidden: true,
        class: 'column',
        style: {
          gap: vars.spacing,
          justifyContent: 'center',
          padding: vars.spacing,
        },
      },
      div(
        {
          class: 'row-if-wide',
          style: { gap: vars.spacing },
        },
        div(
          {
            class: 'column',
            style: {
              position: 'relative',
              alignItems: 'flex-start',
              maxWidth: '30em',
              flex: '1 1 30em',
            },
          },
          h1({
            part: 'title',
            style: { marginTop: vars.spacing50 },
          }),
          div(
            { dataBullet: 'A', style: { margin: `${vars.spacing50} 0` } },
            span(
              strong('Save time and effort:'),
              ' escalate your complaint to ',
              span({ part: 'businessName' }),
              ' corporate office with our help. Just click the orange button!'
            )
          ),
          div({
            part: 'bulletB',
            dataBullet: 'B',
            style: { margin: `${vars.spacing50} 0` },
          }),
          div({ class: 'column', style: { width: '100%' }, part: 'contacts' }),
          div({
            class: 'hr',
            dataLabel: 'OR',
            style: { margin: `${vars.spacing150} 0` },
          }),
          complaintButton({
            part: 'fac',
            style: { margin: `${vars.spacing150} 0`, alignSelf: 'center' },
          })
        ),
        div(
          {
            class: 'column',
            style: {
              alignItems: 'stretch',
              gap: vars.spacing,
              maxWidth: '30em',
              flex: '1 1 30em',
            },
          },
          cardView({
            part: 'process',
            style: { flex: '0 0 40%' },
          }),
          cardView(
            {
              part: 'caseCard',
              hidden: true,
            },
            h3('Reviews', { part: 'reviewHeading', style: { marginTop: 0 } }),
            ul({
              part: 'cases',
              style: {
                margin: `0 ${vars.spacing} 0 0`,
                paddingLeft: vars.spacing150,
              },
            })
          )
        )
      ),
      div({
        class: 'column',
        part: 'reviews',
        hidden: true,
        style: {
          gap: vars.spacing,
          margin: 'auto',
          marginBottom: vars.spacing,
          alignItems: 'stretch',
          maxWidth: '30em',
          flex: '1 1 30em',
        },
      }),
      div({
        class: 'column',
        part: 'complaints',
        hidden: true,
        style: {
          gap: vars.spacing,
          margin: 'auto',
          marginBottom: vars.spacing,
          alignItems: 'stretch',
          maxWidth: '30em',
          flex: '1 1 30em',
        },
      }),
      div({ class: 'column' }, a({ part: 'complaintsLink', hidden: true })),
      markdownViewer({
        part: 'extra',
        placeholder: null,
        style: { display: 'block', maxWidth: '30em' },
      }),
      disqusThread()
    ),
  ]

  constructor() {
    super()
    this.initAttributes('path', 'businessId')
    if (this.value === null) {
      xin[this.instanceId] = mockProfile
    } else {
      this.value = xin[this.instanceId] = Object.assign(
        {},
        mockProfile,
        { contacts: [] },
        this.value
      )
    }
  }

  spinner = (): void => {
    const { info, spinner } = this.parts

    if (this.value == null) {
      info.setAttribute('hidden', '')
      spinner.removeAttribute('hidden')
    } else {
      spinner.setAttribute('hidden', '')
      info.removeAttribute('hidden')
    }
  }

  async loadBusiness(): Promise<void> {
    const { path, businessId } = this
    let record: any
    if (businessId !== '') {
      record = await service.business.get({ id: businessId })
    } else {
      record =
        prefetched.records.find(
          (rec) => rec.path === path && rec._collection === 'business-profile'
        ) || (await service.business.get({ path }))
    }

    this.value = xin[this.instanceId] = Object.assign(
      {},
      mockProfile,
      { contacts: [] },
      record
    )

    this.spinner()

    this.display()
  }

  display(): void {
    const {
      title,
      businessName,
      bulletB,
      process,
      fac,
      contacts,
      caseCard,
      reviewHeading,
      cases,
      extra,
      reviews,
      complaints,
      complaintsLink,
    } = this.parts as { [key: string]: HTMLInputElement }

    cases.textContent = ''
    caseCard.hidden = true
    reviews.textContent = ''
    complaints.textContent = ''
    contacts.textContent = ''

    const profile = this.value !== null ? this.value : mockProfile

    if (profile.extraText !== undefined) {
      extra.value = profile.extraText
    }

    // GA Funnel
    logEvent('view_item', {
      currency: 'USD',
      items: [
        {
          item_id: `${profile.name.replace(/ /g, '-')}-complaint`,
          item_name: `${profile.name} Complaint Resolution Service`,
        },
      ],
      value: profile.price,
    })

    console.log('business profile')
    app.ui.editableContent = `business-profile ${profile._id}`

    reviewHeading.textContent = `${profile.name} Reviews`
    if (profile.reviews != null && profile.reviews.count > 0) {
      caseCard.hidden = false
      cases.append(li(`${profile.reviews.count} Resolution Reviews`))
      cases.append(
        li(
          'Resolution Rating ',
          slRating({
            label: 'Average resolution review score, out of five',
            value: Number(profile.reviews.aveScore),
            readonly: true,
            style: {
              margin: `0 ${vars.spacing50} ${vars.spacing_25}`,
              verticalAlign: 'top',
            },
          }),
          profile.reviews.aveScore
        )
      )
      cases.append(
        li(`Customers willing to do business again ${profile.reviews.wdbaPct}`)
      )

      /*
      if (profile.reviews.wouldDoBusiness !== undefined) {
        const percent = (profile.reviews.wouldDoBusiness / profile.reviews.count * 100).toFixed(1)
        cases.append(li(
          `${profile.reviews.wouldDoBusiness} (${percent}%) of reviewers would do business again`
        ))
      }
      */

      if (profile.reviews.count > 0) {
        reviews.hidden = false
        const reviewIds = profile.reviews.reviewIds.slice(0, 5)
        reviews.append(
          h2(`Latest Reviews of ${profile.name} Cases`),
          ...reviewIds.map((reviewId) =>
            reviewCard({ isEmbedded: true, reviewId })
          )
        )
      }
    }

    if (profile.cases !== undefined && profile.cases.count > 0) {
      complaintsLink.textContent = `Read all ${profile.name} complaints here`
      complaintsLink.setAttribute('href', `/complaints/${profile.path}/`)
      complaintsLink.hidden = false
    }

    if (profile.cases !== undefined && profile.cases.caseIds.length > 0) {
      complaints.hidden = false
      const ids = profile.cases.caseIds.slice(0, 3)
      complaints.append(
        h2(`${profile.name} Latest Cases`),
        ...ids.map((caseId) =>
          caseView({
            caseId,
            businessData: { name: profile.name },
            linkCase: true,
            isEmbedded: true,
          })
        )
      )
    }

    title.textContent = profile.title
      ? profile.title
      : `How to file a complaint with ${profile.name} customer service`
    businessName.textContent = profile.name
    bulletB.textContent = `Try to contact ${profile.name} by yourself, the contact details are below.`
    process.innerHTML = populateTemplate(
      profile.complaintProcess || complaintProcess,
      this.instanceId,
      true,
      true
    )
    if (fac != null) {
      ;(fac as unknown as ComplaintButton).path = profile.path
    }
    contacts.textContent = ''
    contacts.append(
      ...profile.contacts
        .filter(
          (c) =>
            c.description.toLocaleLowerCase() !== 'facebook' &&
            !c.content.includes('facebook.com')
        )
        .map(contact)
    )
  }

  connectedCallback(): void {
    super.connectedCallback()

    if (this.value === null) {
      this.spinner()

      void this.loadBusiness()
    }
    this.display()
  }
}

export const businessProfile = BusinessProfile.elementCreator({
  tag: 'business-profile',
})
