import {
  Component as WebComponent,
  ElementCreator,
  elements,
  vars,
} from 'xinjs'
import { icons } from 'xinjs-ui'
import { complaintButton, ComplaintButton } from './complaint-button'
import { caseView } from './case-view'
import { loadingSpinner } from './loading-spinner'
import { getRecord, getRecords } from '../firebase'

const { h1, div, span, a } = elements

const pageButton = (page: number, currentPage: number) => {
  const pb = a(
    {
      class: 'unadorned',
      style: { boxShadow: 'none', padding: vars.spacing50 },
      href: `?p=${page}`,
    },
    `${page + 1}`
  )
  if (page === currentPage) {
    pb.classList.add('current')
  }
  return pb
}

class PagePicker extends WebComponent {
  value = 0
  totalPages = 0
  maxVisiblePages = 10

  constructor() {
    super()
    this.initAttributes('totalPages', 'maxVisiblePages')
  }

  content = () => [
    a(
      {
        part: 'first',
        class: 'unadorned',
        style: { boxShadow: 'none', padding: vars.spacing50 },
        dataTooltip: 'Page 1',
      },
      icons.chevronLeft()
    ),
    span({ part: 'pages', class: 'row', alignItems: 'center' }),
    a(
      {
        part: 'last',
        class: 'unadorned',
        style: { boxShadow: 'none', padding: vars.spacing50 },
      },
      icons.chevronRight()
    ),
  ]

  connectedCallback(): void {
    super.connectedCallback()
  }

  render(): void {
    const { first, last } = this.parts
    first.setAttribute('href', '.')
    last.setAttribute('href', `?p=${this.totalPages - 1}`)
    last.dataset.tooltip = `Page ${this.totalPages}`

    super.render()
    if (this.totalPages < 1) {
      this.hidden = true
      return
    } else {
      this.hidden = false
    }
    const { pages } = this.parts
    pages.textContent = ''
    if (this.totalPages < this.maxVisiblePages) {
      for (let page = 0; page < this.totalPages; page++) {
        pages.append(pageButton(page, this.value))
      }
    } else {
      let firstPage = Math.floor(this.value - this.maxVisiblePages / 2)
      if (firstPage < 0) {
        firstPage = 0
      }
      if (firstPage > this.totalPages - this.maxVisiblePages) {
        firstPage = this.totalPages - this.maxVisiblePages
      }
      console.log({ firstPage })
      for (
        let page = firstPage;
        page < firstPage + this.maxVisiblePages;
        page++
      ) {
        pages.append(pageButton(page, this.value))
      }
    }
  }
}

export const pagePicker = PagePicker.elementCreator({
  tag: 'page-picker',
}) as ElementCreator<PagePicker>

class ComplaintsView extends WebComponent {
  page = 0
  path = ''
  businessId = ''
  businessData = undefined as any | undefined
  complaintsPerPage = 5
  totalPages = 0

  scrollToTop = () => {
    this.scrollIntoView({ behavior: 'smooth' })
  }

  content = [
    loadingSpinner({ part: 'spinner' }, 'loading complaints'),
    div(
      {
        part: 'content',
        class: 'column',
        style: { alignItems: 'stretch', minHeight: vars.vh },
      },
      h1(
        { part: 'subhead', style: { marginTop: 0, alignSelf: 'center' } },
        'All complaints filed against ',
        a({ part: 'profile', style: { marginBottom: 0 } }),
        ' on ',
        a('nonono.com', { href: '/' })
      ),
      div({ part: 'complaints', class: 'column' }),
      pagePicker({
        part: 'picker',
        class: 'row',
        style: { justifyContent: 'center', marginBottom: vars.spacing, gap: 0 },
        onClick: this.scrollToTop,
      }),
      complaintButton({
        part: 'fac',
        style: { alignSelf: 'center', marginBottom: vars.spacing200 },
      })
    ),
  ]

  async load() {
    if (this.path !== '') {
      const [data] = await getRecords(
        'business-profile',
        { field: 'path', operator: '==', value: this.path },
        1
      )
      if (data === undefined) {
        throw new Error(`no business with path == ${this.path} found`)
      }
      this.businessData = data
    } else if (this.businessId !== '') {
      this.businessData = await getRecord('business-profile', this.businessId)
      if (this.businessData === undefined) {
        throw new Error(`no business with path == ${this.path} found`)
      }
    }
    this.totalPages =
      this.businessData.cases !== undefined && this.businessData.cases.count > 0
        ? Math.ceil(this.businessData.cases.count / this.complaintsPerPage)
        : 0

    this.queueRender()
  }

  setPage = () => {
    const { picker } = this.parts as { [key: string]: PagePicker }
    this.page = picker.value
    this.queueRender()
  }

  connectedCallback(): void {
    super.connectedCallback()

    const { picker } = this.parts as { picker: PagePicker }

    if (this.path !== '' || this.businessId !== '') {
      this.load().catch((e) => {
        console.error(e)
      })
    } else {
      console.error('no path or businessId')
    }

    const params = new URLSearchParams(window.location.search)
    this.page = Number(params.get('p') || 0)
    picker.value = this.page
    console.log(this.page)
  }

  render(): void {
    super.render()
    const { spinner, content, complaints, picker, profile } = this.parts

    if (this.businessData === undefined) {
      spinner.removeAttribute('hidden')
      content.setAttribute('hidden', '')
      return
    }

    spinner.setAttribute('hidden', '')
    content.removeAttribute('hidden')

    profile.textContent = this.businessData.name
    profile.dataset.tooltip = `See ${this.businessData.name} Customer Service Page`
    profile.setAttribute('href', `/${this.businessData.path}-customer-service/`)

    const fac = this.parts.fac as ComplaintButton
    fac.brandName = this.businessData.name
    fac.path = this.businessData.path

    complaints.textContent = ''
    if (this.totalPages > 0) {
      complaints.append(
        ...this.businessData.cases.caseIds
          .slice(
            this.page * this.complaintsPerPage,
            this.page * this.complaintsPerPage + this.complaintsPerPage
          )
          .map((caseId: string) =>
            caseView({
              caseId,
              linkComplaint: true,
              linkBusinessProfile: true,
              showReview: true,
              isEmbedded: true,
            })
          )
      )
    }

    ;(picker as PagePicker).totalPages = this.totalPages
  }
}

export const complaintsView = ComplaintsView.elementCreator({
  tag: 'complaints-view',
}) as ElementCreator<ComplaintsView>
